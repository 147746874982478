import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './loan-origination-application-summary.template.html';
import './loan-origination-application-summary.style.less';
import './loan-origination-application-summary.print.less';
import {CustomerProfile} from '../../../../customer/profile/customer-profile.types';
import {LoanType} from '../../../../service/loan-type.types';
import {CreateLoanInput} from '../../../../service/create-loan-input.types';

class LoanOriginationApplicationSummary {
  // bindings
  profile!: CustomerProfile;
  loanType!: LoanType;
  loan!: CreateLoanInput;
  onChangeLoan!: () => void;
  onChangeCustomer!: () => void;
  // used to hide action buttons
  previewMode!: boolean;


  isPreviewMode() {
    return _.defaultTo(this.previewMode, false);
  }

}

nxModule.component('loanOriginationApplicationSummary', {
  templateUrl,
  bindings: {
    profile: '<',
    loanType: '<',
    loan: '<',
    onChangeLoan: '&',
    onChangeCustomer: '&',
    previewMode: '<'
  },
  controller: LoanOriginationApplicationSummary
});