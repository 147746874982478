import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import angular from "angular";
import _ from 'lodash';

import '../common/date-granted.component'
import systemPropertyService from '../../../../../react/system/systemPropertyService';

import templateUrl from './customer-loans-edit-information.template.html';

nxModule.component('customerLoansEditInformation', {
  templateUrl: templateUrl,
  controller: function ($route, $location, dict, customerCache, command,
                        customerService, fileService, userService, confirmation,
                        notification, http, loanProductsCache, depositAccountTypeService,
                        loanService) {
    let that = this;

    this.dict = dict;
    that.collateralFiles = [];
    that.linkedDepositAccounts = [];
    that.validateFiles = false;

    let loanId = Number($route.current.params['loanId']);
    let customerId = Number($route.current.params['customerId']);

    dict.onLoadingComplete(async () => {
      const [loans, loanTypes, profile, accounts, accountTypes] = await Promise.all([
        customerCache.loans(customerId).toPromise(),
        loanProductsCache.toPromise(),
        customerCache.profile(customerId).toPromise(),
        customerCache.depositAccounts(customerId).toPromise(),
        depositAccountTypeService.toPromise()]);

      that.profile = profile;
      that.loan = _.find(loans, el => el.id === loanId);
      that.loan.loanType = _.find(loanTypes, {id: that.loan.typeId})

      // save accounts
      that.accounts = _.cloneDeep(accounts);
      that.cbuAccounts = [];
      that.pfAccounts = [];
      that.tpAccounts = [];

      for (let a of that.accounts) {
        if (a.status === 'CLOSED') {
          continue;
        }

        switch (that.profile.customerType) {
          case 'INDIVIDUAL':
            if (a.typeId === that.loan.loanType.individualCbuAccountTypeId) {
              that.cbuAccounts.push(a);
            }
            if (a.typeId === that.loan.loanType.individualPfAccountTypeId) {
              that.pfAccounts.push(a);
            }
            if (a.typeId === that.loan.loanType.individualTpAccountTypeId) {
              that.tpAccounts.push(a);
            }
            break;
          case 'CORPORATE':
            if (a.typeId === that.loan.loanType.corporateCbuAccountTypeId) {
              that.cbuAccounts.push(a);
            }
            if (a.typeId === that.loan.loanType.corporatePfAccountTypeId) {
              that.pfAccounts.push(a);
            }
            if (a.typeId === that.loan.loanType.corporateTpAccountTypeId) {
              that.tpAccounts.push(a);
            }
            break;
          default:
            console.error('Invalid customer type.');
        }
      }

      this.linkedDepositAccounts = loanService.getAvailableLinkedAccounts(that.accounts, accountTypes);
      for (let a of that.accounts) {
        let productNumber = a.productNumber;
        let productName = _.find(accountTypes, {id: a.typeId}).productDefinition.productName;
        a.label = `${productName} (${productNumber})`;
      }

      if (loanId) {
        that.clonedLoan = angular.copy(that.loan);
        that.clonedLoan.cbuAccount = _.find(that.accounts, {id: that.loan.cbuSavingsAccountId});
        that.clonedLoan.pfAccount = _.find(that.accounts, {id: that.loan.pfSavingsAccountId});
        that.clonedLoan.tpAccount = _.find(that.accounts, {id: that.loan.tpSavingsAccountId});
        that.clonedLoan.linkedDepositAccount = _.find(that.accounts, {id: that.loan.linkedDepositAccountId});

        let cleanUnsecuredLoanSecurityId = _.find(that.dict['LOAN_SECURITY'], {code: 'CLN'});
        if (that.loan.loanInformation.loanSecurityId === cleanUnsecuredLoanSecurityId) {
          that.validateFiles = true;
        }

        that.collateralFiles = that.loan.collateralFileIds.map(fileId => ({
          id: fileId
        }));

        this.loanOfficers = await userService.getLoanOfficersAssignedToCurrentUserBranches(that.clonedLoan.officerId);
        this.collectors = await userService.getCollectorsAssignedToCurrentUserBranches(that.clonedLoan.collectorId);
        this.customFieldValues = await customFieldService.readValuesMap({productId: loanId});
      }

      that.showReleaseDate = systemPropertyService.getProperty('LOAN_FUTURE_DATE_GRANTED_ALLOWED') === 'TRUE';
    });

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`)
    };

    that.save = () => {
      let loanCopy = _.cloneDeep(that.clonedLoan);
      loanCopy.collateralFileIds = that.collateralFiles.map(file => file.id);

      confirmation('Do you want to update loan information?', () => {
        command.execute('EditLoanInformation', {
          productId: loanCopy.id,
          loanInformation: loanCopy.loanInformation,
          creationType: loanCopy.creationType,
          collateralFileIds: loanCopy.collateralFileIds,
          cbuSavingsAccountId: loanCopy.cbuSavingsAccountId,
          pfSavingsAccountId: loanCopy.pfSavingsAccountId,
          tpSavingsAccountId: loanCopy.tpSavingsAccountId,
          allowAccrual: loanCopy.allowAccrual,
          categoryIds: loanCopy.categoryIds,
          customFieldValues: that.customFieldValues,
          extraNumber: loanCopy.extraNumber,
          officerId: loanCopy.officerId,
          collectorId: loanCopy.collectorId,
          releaseAllowedDate: loanCopy.releaseAllowedDate,
          linkedDepositAccountId: loanCopy.linkedDepositAccountId
        }).success(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        });
      });
    };
  }
});
