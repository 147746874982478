import angular from 'angular';

import nxModule from 'nxModule';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/do';
import {Observable} from 'rxjs/Observable';

class GroupLoansCreateService {
  constructor(http, groupCustomerCache, loanProductsCache, command, $location) {
    this.http = http;
    this.groupCustomerCache = groupCustomerCache;
    this.command = command;
    this.$location = $location;
  }

  getLoan(loanId) {
    return Observable.create(observer => {
      this.http.get(`/products/loans/${loanId}`)
        .success(loan => {
          observer.next(loan);
          observer.complete();
        })
        .error(err => observer.error(err));
    })
  }

  getMemberLoans(customerId) {
    return this.getMembers(customerId)
      .combineLatest(this.groupCustomerCache.individualLoans(customerId).toObservable(), (members, loans) => {
        return members.map(member => ({
          ...member,
          loans: loans.filter(loan => loan.customerId === member.id)
        }))
      })
  }

  getMembers(customerId) {
    return this.groupCustomerCache.profile(customerId)
      .toObservable()
      .filter(p => p.customerType === 'GROUP' && p.customerGroup)
      .map(p => p.customerGroup.customers);
  }

  prepareLoanForSubmitting(loan) {
    const clonedLoan = angular.copy(loan);
    clonedLoan.creationType = clonedLoan.creationType || 'NEW_LOAN';
    clonedLoan.collateralFileIds = clonedLoan.collateralFiles ? clonedLoan.collateralFiles.map(file => file.id) : null;
    clonedLoan.collateralFiles = null;
    return clonedLoan;
  }

  calculateLoan(loan) {
    const clonedLoan = this.prepareLoanForSubmitting(loan);

    return Observable.create(obs => {
      this.http.post('/products/loans/simulate', clonedLoan)
        .success(response => {
          obs.next(response);
          obs.complete();
        }, true)
        .error(resp => {
          obs.error(resp);
        })
    })
  }

  submitLoans(groupId, loans) {
    const loanRequests = loans.map(loan => this.prepareLoanForSubmitting(loan.loan));

    const request = {
      loanRequests,
      customerGroupId: groupId,
    };

    return this.command.execute('CreateLoanBatch', request);
  }
}

nxModule.service('groupLoansCreateService', GroupLoansCreateService);
