import {
    NxButton,
    NxButtonVariant,
    NxCashInput,
    NxFormik,
    NxFormikSubmitButton,
    NxFormikCashInput,
    NxFormikPercentageInput,
    NxInput,
    NxLoader,
    NxPercentageInput,
    NxPopup,
    NxRow,
    NxRowPosition,
    NxStack
} from '@nextbank/ui-components';
import useAxios from 'axios-hooks';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {LoanType} from 'components/service/loan-type.types';
import {LoanDetails} from 'components/service/loan.types';
import NxForm from 'form/NxForm';
import NxHeader, {HeaderVariant} from 'form/NxHeader';
import NxPage from 'form/NxPage';
import Simulation from 'loan/simulation/Simulation';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import styles from './Reprice.scss';

interface RepriceCommandInput {
  productId: number;
  interestRate: number;
}

interface RepriceData {
  principalAmount: number;
  principalBalance: number;
  interestAmount: number;
  interestBalance: number;
  currentInterestRate: number;
  newInterestRate: number;
}

enum ButtonAction {
  SIMULATE,
  SUBMIT
}

const Reprice = (): ReactElement => {
  const {customerId, loanId} = useParams<{customerId: string, loanId: string}>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [buttonAction, setButtonAction] = useState<ButtonAction>(ButtonAction.SIMULATE);
  const history = useHistory();
  const execute = useCommand();

  const [{data: loan, loading}] = useAxios<LoanDetails>(`/products/loans/${loanId}`);
  const [{data: loanTypes}] = useAxios<LoanType[]>(`/products/loans/types`);
  const [{data: simulatedLoan}, simulateLoan] = useAxios<LoanDetails>('/products/loans/simulate/repriced', {
    manual: true
  });

  const loanType: LoanType | undefined = useMemo(() => {
    if (loan && loanTypes) {
      return loanTypes.find(t => t.id === loan.typeId);
    }
  }, [loan, loanTypes]);

  const RepriceFormSchema: SchemaOf<RepriceData> = Yup.object().shape({
    principalAmount: Yup.number().required(),
    principalBalance: Yup.number().required(),
    interestAmount: Yup.number().required(),
    interestBalance: Yup.number().required(),
    currentInterestRate: Yup.number().required(),
    newInterestRate: loanType ?
      Yup.number().required()
      .min(loanType.minInterestRate, 'Cannot be lower than loan type min interest rate.')
      .max(loanType.maxInterestRate, 'Cannot exceed loan type max interest rate.')
      : Yup.number().required()
  });

  const redirectToReport = () : void => {
    history.push(`/customer/${customerId}/loans/${loanId}/schedule-report`);
  };

  if (!loan || loading || !loanType) {
    return <NxPage><NxHeader>Reprice loan</NxHeader><NxLoader/></NxPage>;
  }

  return <NxPage>
    <NxHeader>Reprice loan</NxHeader>
    <div className={styles.infoRow}>
      <NxRow>
        <NxInput disabled label="Product name" value={loanType.productDefinition.productName}/>
        <NxPercentageInput disabled label="Min interest rate" value={loanType.minInterestRate}/>
        <NxPercentageInput disabled label="Max interest rate" value={loanType.maxInterestRate}/>
      </NxRow>
    </div>

    <NxHeader variant={HeaderVariant.H3}>Loan parameters</NxHeader>
    <NxFormik<RepriceData>
      initialValues={{
        currentInterestRate: loan.interestType === 'ADD_ON_RATE' ? loan.monthlyInterestRate : loan.interestRate,
        principalAmount: loan.principalAmount,
        principalBalance: loan.principalBalance,
        interestAmount: loan.interestAmount,
        interestBalance: loan.interestBalance,
        newInterestRate: loan.interestType === 'ADD_ON_RATE' ? loan.monthlyInterestRate : loan.interestRate
      }}
      validationSchema={RepriceFormSchema}
      onSubmit={async (input: RepriceData): Promise<void> => {
        if (buttonAction === ButtonAction.SIMULATE) {
          simulateLoan({
            method: 'POST',
            data: {
              productId: Number(loanId),
              interestRate: input.newInterestRate
            }
          });
          return;
        } else {
          const response: CommandOutputWrapper<void> = await execute<RepriceCommandInput, void>({
            name: 'RepriceLoan',
            input: {
              productId: Number(loanId),
              interestRate: input.newInterestRate
            }
          });
          if (!response.approvalRequired) {
            setShowPopup(true);
          }
        }
      }
      }>
      {(): ReactElement => (
        <NxForm>
          <NxStack>
            <NxFormikCashInput disabled label="Principal amount" name="principalAmount"/>
            <NxFormikCashInput disabled label="Principal balance" name="principalBalance"/>
            <NxFormikCashInput disabled label="Interest amount" name="interestAmount"/>
            <NxFormikCashInput disabled label="Interest balance" name="interestBalance"/>
            <NxFormikPercentageInput disabled label="Current interest rate" name="currentInterestRate"/>
            <NxRow>
              <NxFormikPercentageInput label="Repriced interest rate" name="newInterestRate"/>
              <NxFormikSubmitButton
                variant={NxButtonVariant.OUTLINED}
                onClick={(): void => setButtonAction(ButtonAction.SIMULATE)}>
                Simulate
              </NxFormikSubmitButton>
            </NxRow>
            {simulatedLoan &&
            <>
              <Simulation loan={simulatedLoan} showOutstandingPrincipal={true}/>
              <NxCashInput disabled label={'Interest amount'} value={simulatedLoan.interestAmount}/>
              <NxCashInput disabled label={'Interest balance'} value={simulatedLoan.interestBalance}/>
            </>
            }
          </NxStack>
          <NxRow position={NxRowPosition.END}>
            <NxCancelButton />
            <NxFormikSubmitButton
              variant={NxButtonVariant.ADD}
              onClick={(): void => setButtonAction(ButtonAction.SUBMIT)}>
              Reprice
            </NxFormikSubmitButton>
          </NxRow>
        </NxForm>
      )}
    </NxFormik>
    <NxPopup header={`Reprice successful`}
             open={showPopup}
             description="Loan reprice completed. Proceed to print the Amortization Schedule.">
      <NxRow position={NxRowPosition.END}>
        <NxButton variant={NxButtonVariant.ADD}
                  onClick={redirectToReport}>
          Proceed
        </NxButton>
      </NxRow>
    </NxPopup>
  </NxPage>;
};

export default Reprice;