import nxModule from 'nxModule';
import './pesonet-transfer.component.less'
import templateUrl from './pesonet-transfer.template.html';
import {Account, Product, ProductDefinition} from "../../../service/product.types";
import {ILocationService} from "angular";
import {HttpService} from "../../../../shared/utils/httpService";
import {ProductDefinitionService} from "../../../service/product-definition.service";
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

interface ExternalFinancialInstitution {
  name: string,
  code: string,
  channel: 'PESONET' | 'INSTAPAY';
  enabled: boolean
}

class PesonetTransfer {
  readonly config: unknown = {
    placeholder: 'Select Bank',
    searchField: ['name', 'code'],
    valueField: 'code',
    labelField: 'name',
    maxItems: 1
  };

  pesonetBanks: ExternalFinancialInstitution[] = [];
  depositAccount!: Account & { productName?: string };

  bankCode?: string;
  amount!: number;
  accountNumber?: string;
  accountName?: string;
  remarks?: string;

  private customerId!: number;
  private productId!: number;

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private $filter: NxIFilterService,
              private confirmation: Confirmation,
              private command: CommandService,
              private http: HttpService,
              private customerCache: CustomerCache,
              private productDefinitionService: ProductDefinitionService) {
  }

  async $onInit() : Promise<void> {
    const [depositAccounts, productDefinitions] = await Promise.all([
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.productDefinitionService.toPromise()
    ]);

    this.depositAccount = depositAccounts.find((a : Product) => a.id === this.productId)!;
    const productDefinition: ProductDefinition = productDefinitions.find((p: ProductDefinition) => p.id === this.depositAccount.definitionId)!;
    this.depositAccount.productName = productDefinition.productName;
    this.pesonetBanks = await this.http.get<ExternalFinancialInstitution[]>(`/external-transfer/institutions?channel=PESONET`).toPromise();
  }

  async transfer() : Promise<void> {
    const confirmed = await this.confirmation(`Do you want to deposit ${this.$filter('nxCurrency')(this.amount)} to ${this.accountNumber}:${this.accountName}?`);
    if (confirmed) {
      const response = await this.command.execute('PesonetOverTheCounterTransfer', {
        externalBankCode: this.bankCode,
        externalAccountNumber: this.accountNumber,
        externalCustomerName: this.accountName,
        amount: this.amount,
        productId: this.productId,
        remarks: this.remarks
      }).toPromise();

      if (!response.approvalRequired) {
        this.customerCache.depositAccounts(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }

  redirectBack() : void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.productId}`);
  }
}

nxModule.component('pesonetTransfer', {
  templateUrl,
  controller: PesonetTransfer,
  bindings: {
    customerId: '<',
    productId: '<'
  }
});