import RouteDefinition from "routes/RouteDefinition";
import {CustomerProfile} from "components/customer/profile/customer-profile.types";
import axios from "axios";

const routes: RouteDefinition[] = [
  {
    name: async ({customerId}: {customerId: string}): Promise<string> => {
      const {data: customer} = await axios.get<CustomerProfile>(`/customers/${customerId}`);
      return customer.effectiveName ?? '??';
    },
    path: '/customer/:customerId'
  }
];

export default routes;