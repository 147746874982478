import nxModule from 'nxModule';
import _ from 'lodash';
import {addressTypes, ownershipTypes} from 'constants/address';
import systemPropertyService from '../../../react/system/systemPropertyService';

import './addresses.style.less';

const templateUrl = require('./addresses.template.html');
nxModule.component('customerProfileAddresses', {
  templateUrl: templateUrl,
  bindings: {
    'addresses': '=',
    'editMode': '=',
    'module': '<'
  },
  controller: function (dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.addressTypes = addressTypes;
    that.ownershipTypes = ownershipTypes;

    that.$onInit = () => {
      that.addresses = that.addresses || [];

      if (!that.module) {
        that.module = 'customer'
      }
    };

    that.hasOnePrimaryAddress = () => {
      const primaryAddressCount = that.addresses.filter(a => a.primary).length;
      return primaryAddressCount === 1;
    };

    that.ownershipChanged = (address) => {
      if (address.ownership !== 'RENTED') {
        address.rentContract = null;
        address.rentAmount = null;
      }
    };

    const minAddr = systemPropertyService.getProperty('CIF_MIN_ADDRESS_SECTIONS');
    that.minAddressSections = minAddr ? Number(minAddr) : 0;


    that.addAddress = function () {
      that.addresses.push({
        countryId: that.readDefaultCountry(),
        primary: false
      });
    };

    that.removeAddress = function (addr) {
      let array = that.addresses;
      let index = array.indexOf(addr);
      array.splice(index, 1);
    };

    that.readDefaultCountry = () => {
      let defaultCountryCode = that.cfg.defaultValue('customer', 'address', 'country');
      if (defaultCountryCode) {
        let country = _.find(that.dict['COUNTRY'], {code: defaultCountryCode});
        return country && country.id ? country.id : null;
      }
      return null;
    };

    that.primaryChanged = (addr, idx) => {
      //make sure only one address is selected as primary
      if (addr.primary) {
        that.addresses.forEach( (a, i) => {
          if (idx !== i) {
            a.primary = false;
          }
        });
      }
    }

    that.hasValidAddresses = () => {
      if(this.addresses.length < this.minAddressSections) {
        return false;
      }

      if(this.addresses.length === 0) {
        return true;
      }

      return this.hasOnePrimaryAddress();
    };
  }
});
