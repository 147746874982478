import {IController, INgModelController, IOnChangesObject} from 'angular';
import {CustomFieldCategoryDetails, CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import CustomFieldService from 'custom-field/CustomFieldService';
import _ from 'lodash';
import nxModule from 'nxModule';
import {EntHordeNode} from '../../technical/ent-horde/ent-horde.component';
import {FilterValue} from './filter-value.types';

import templateUrl from './loan-category-filter.template.html';

type LoanCategoryEntHordeNodeType = 'categoryType' | 'category';

interface LoanCategoryEntHordeNode extends EntHordeNode {
  name: string;
  type: LoanCategoryEntHordeNodeType;
  children: LoanCategoryEntHordeNode[];
}

class LoanCategoryFilter implements IController {
  ngModel!: INgModelController;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  values: number[];
  treeData: LoanCategoryEntHordeNode[] = [];

  private readonly categoriesPromise: Promise<CustomFieldCategoryDetails[]>;

  options!: FilterValue[];

  constructor() {
    this.categoriesPromise = customFieldService.readCategoryDetails(CustomFieldGroup.LOAN);
  }

  $onInit(): void {
    this.ngModel.$render = () => {
      this.values = this.modelValue();
    };
  }

  private modelValue(): number[] {
    return this.ngModel.$modelValue || [];
  }

  updateModel(): void {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.values);
  }

  uniqueSelectedCategoryIds(): Set<number> {
    const sanitizedValues = (this.values || []).filter(value => value && value >= -1); // gets categories only - they have ID's starting from -1 and above
    return new Set<number>(sanitizedValues);
  }

  $onChanges(changes: IOnChangesObject): void {
    this.rebuildForest();
  }

  async rebuildForest(): Promise<void> {
    const availableCategories = await this.categoriesPromise;
    const typeNameGroupedCategories = _.groupBy(availableCategories, cat => cat.categoryTypeName);

    const forest = Object.keys(typeNameGroupedCategories).map(type => {
      const categoriesForGivenType = typeNameGroupedCategories[type]
        .map(cat => this.buildTree(cat));

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!categoriesForGivenType && categoriesForGivenType.length === 0) {
        return null;
      }

      return {
        /**
         * Unique ID calculation per categoryType:
         * - gets category's unique ID from the closest item
         * - negating value to distinct it from the categories (positive numbers)
         * - multiplied by 10 to ensure value lower than -1 (used to distinct from 'unassigned categories' signal)
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: categoriesForGivenType[0].id * -10,
        name: type,
        type: 'categoryType',
        children: categoriesForGivenType
      }
    })
    .filter(filter => filter);

    const customOptions = this.options.filter(option => option.id < 0)
      .map(option => ({
        id: option.id,
        name: option.code,
        type: 'category',
        children: []
      }));

    this.treeData = [{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: -Number.MAX_SAFE_INTEGER, // any value below -1 and distinct from any categoryType's ID
      name: 'All categories',
      type: 'categoryType',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      children: <LoanCategoryEntHordeNode[]>[...customOptions, ...forest]
    }];
  }

  private buildTree(category: CustomFieldCategoryDetails): LoanCategoryEntHordeNode {
    const children = (category.children || []).map(category => this.buildTree(category));

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: category.id,
      name: category.categoryName,
      type: 'category',
      children: [...children]
    };
  }
}

nxModule.component('loanCategoryFilter', {
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    options: '<',
    ngRequired: '<'
  },
  controller: LoanCategoryFilter
});
