import nxModule from 'nxModule';

import templateUrl from './loan-origination-assessment-list.template.html';
import {LosApplicationTableEntry, PersistedLosApplicationListItem} from '../../application/types/los-application.types';
import {ActionType, Columns} from '../../../common/dynamic-list/dynamic-list.component';
import {IController, ILocationService} from 'angular';
import moment from 'moment';
import {Branch} from "management/BranchTypes";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {BranchService} from "components/service/branch.service";

class LoanOriginationAssessmentList implements IController {
  applications: LosApplicationTableEntry[] = [];
  applicationsColumns: Columns = [
    { title: 'No' },
    { title: 'Branch', field: 'branchName' },
    { title: 'Existing customer', field: 'existingCIFLabel' },
    { title: 'Customer name', field: 'customerName' },
    { title: 'Loan', field: 'loanTypeName' },
    { title: 'Created', field: 'createdOn' },
    { title: 'Updated', field: 'updatedOn' },
    { title: 'Approval status', field: 'status' },
    {
      actions: [{
        label: 'View',
        field: 'view',
        access: 'LOSUpdateApplication',
        type: ActionType.BUTTON
      }]
    }
  ];

  constructor(private http: HttpService,
              private $location: ILocationService,
              private $filter: NxIFilterService,
              private branchService: BranchService) {
  }


  async $onInit() {
    const [applications, branches] = await Promise.all([
      this.http.get<PersistedLosApplicationListItem[]>('/los/applications/awaiting').toPromise(),
      this.branchService.toPromise()
    ]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.applications = applications.map(application => {
      return {
        id: application.id,
        branchName: branches.find(branch => Number(branch.id) === Number(application.branchId))!.name,
        existingCIFLabel: application.existingCIF ? 'Yes' : 'No',
        customerName: application.customerName,
        loanTypeName: application.loanTypeName,
        createdOn: moment(application.createdOn).format('ll'),
        updatedOn: application.lastUpdatedOn ? moment(application.lastUpdatedOn).format('LLL') : '-',
        status: this.$filter('prettyEnum')(application.status),
        view: () => this.$location.path(`los/assessment/${ application.id }`)
      }
    })
  }
}

nxModule.component('loanOriginationAssessmentList', {
  templateUrl,
  controller: LoanOriginationAssessmentList
});