import React, {ReactElement} from 'react';
import {TreeNodeViewProps} from 'tree/editableTree/EditableSubtreeView';

export interface CustomFieldCategoryValue {
  id: number | undefined,
  label: string;
  code: string;
  remarks: string;
}

const CustomFieldCategoryTreeView = (props: TreeNodeViewProps<CustomFieldCategoryValue>): ReactElement => {
  if (props.value.code) {
    return <div>{props.value.label} | {props.value.code}</div>;
  }

  return <div>{props.value.label}</div>;
};

export default CustomFieldCategoryTreeView;
