export enum CheckClearingGroup {
  INWARD = 'INWARD',
  OUTWARD = 'OUTWARD',
  ISSUED = 'ISSUED',
  ON_US = 'ON_US',
  CASHIER = 'CASHIER'
}

export interface OutgoingCheck {
  id: number;
  number: string;
  status: string;
  amount: number;
  registeredOn: string;
  productId: number;
  micrNumber: string;
  depositoryAccountId: number;
}

export interface Check {
  id: number;
  number: string;
  brstn: string;
  validFrom: string;
  registeredOn: string;
  status: string;
  amount: number;
}

export interface IncomingCheck extends Check {
  micrNumber: string;
  estimatedClearingDate: string;
  receivedOnTimestamp: string;
}

export type CheckPreparationStatus = 'ASSIGNED' | 'FOR_OUTGOING' | 'CANCELLED';

export interface CheckPreparation {
  id: number;
  checkPreparationId: number;
  customerNumber: string;
  customerName: string;
  productName: string;
  status: CheckPreparationStatus;
  releaseAmount: number;
  check: Check;
}