import nxModule from 'nxModule';
import ParameterizedLocalCache from "../../shared/utils/parameterized-local-cache";

nxModule.factory('geoLocationCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (maxDepth) => http.get(`/dictionaries/geo/locations?maxDepth=${maxDepth}&fetchNested=false`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.geoLocation,
    cacheName: 'geoLocation'
  })
);
