import nxModule from 'nxModule';

import templateUrl from './batch-clearing-page.template.html';
import {IController} from 'angular';

class BatchClearingPage implements IController {
  constructor() {}
}

nxModule.component('batchClearingPage', {
  templateUrl: templateUrl,
  controller: BatchClearingPage
});
