import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {NxButton, NxButtonVariant, NxPopup, NxRow, NxRowPosition, NxStack} from "@nextbank/ui-components";
import axios from "axios";
import PrintService from "print/PrintService";
import {Print, PrintLine, PrintWrapper} from "./PrintType";
import styles from './PrintModal.scss';
import {PrintModalInput} from "components/service/print/modal-print-preview.service";
import PrintCustomVariableList from "print/PrintCustomVariableList";

export interface ShowInput {
  printDescription: string | {code: string, parameters: Record<string, unknown>};
  printProviderInput?: unknown;
}

export interface BatchShowInput {
  printDescription: string
  input: PrintModalInput[];
}

export type Show = (param: ShowInput) => Promise<ShowOutput>;

export interface ShowOutput {
  printed: boolean
}

export interface PrintModalApi {
  show: Show
}

interface ShowRequest {
  resolve: (arg: ShowOutput) => void;
  reject: (err: unknown) => void;
  input: ShowInput;
  print?: Print;
}

type ExecutionState =  'IDLE' | 'GOT_PRINT_REQUEST' | 'SHOWED_CUSTOM_VARIABLES' |'SHOWED_PRINT';

interface PrintOutput {
  printWrapper: PrintWrapper;
  url: string;
}

const getCodeAndParams = (description: string | {code: string, parameters: Record<string, unknown>}): {
  printCode: string,
  printParameters: Record<string, unknown> | null
} => {
  if(typeof description === 'string') {
    return {
      printCode: description,
      printParameters: null,
    };
  }

  return {
    printCode: description.code,
    printParameters: description.parameters
  };
};

const printService = new PrintService();
const PrintModal = (props: {children: React.Factory<PrintModalApi>}): React.ReactElement => {
  const [printQueue, setPrintQueue] = useState<ShowRequest[]>([]);
  const [executionState, setExecutionState] = useState<ExecutionState>('IDLE');
  const printOutput = useRef<PrintOutput>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const printed = useRef<boolean>(false);

  const show = useMemo(() =>
    (param: ShowInput): Promise<ShowOutput> => {
      return new Promise<{printed: boolean}>((resolve, reject) => {
        console.debug('Showing print', param.printDescription, 'input:', param.printProviderInput);
        setPrintQueue(q => [...q, {
          input: param,
          resolve,
          reject
        }]);

        return;
      });
  }, []);

  const generatePrintAndDisplayModal = async (printCode: string, printParameters: Record<string, unknown> | null, input : ShowInput, customVariables: Record<string, unknown> | null): Promise<void> => {
    const {data: printWrapper} = await axios.post<PrintWrapper>(`/print/${printCode}/json`, {
      printRequests: [{
        parameters: {
          ...printParameters
        },
        inputs: input.printProviderInput,
        customVariables: {
          ...customVariables
        }
      }]
    });
      let backgroundImages: string[] = [];
      const print = printWrapper.prints[0];
      if (print.type !== "TEMPLATE" && print.backgroundFileIds?.length > 0) {
          const {data: backgrounds} = await axios.get<string[]>(`/print/${print.code}/background-image`, {
              params: {
                  ...print.parameters
              }
          });
          backgroundImages = backgrounds;
      }
      const url = await printService.generatePrint(printWrapper.prints, {backgrounds: backgroundImages});
      printOutput.current = {
      printWrapper,
      url,
    };

    setExecutionState('SHOWED_PRINT');
  };

  const processQueue = useCallback(async (): Promise<void> => {
      printed.current = false;
      printOutput.current = undefined;

      const [head, ] = printQueue;
      const {input, resolve, reject} = head;
      const {printCode, printParameters} = getCodeAndParams(input.printDescription);

      setExecutionState('GOT_PRINT_REQUEST');

      try {
        const print:Print = await printService.getPrint({
          printCode,
          printParameters: printParameters ?? {}
        });

        if(!print.active) {
          setPrintQueue(([, ...tail]) => tail);
          setExecutionState('IDLE');
          resolve({printed: false});
          return;
        }

        const newHead : ShowRequest = {...head, print: print};
        setPrintQueue(([, ...tail]) => [newHead, ...tail]);

        const customVariablePrints: PrintLine[] = print.lines.filter(l => l.type === 'CUSTOM');
        if (customVariablePrints.length === 0) {
          await generatePrintAndDisplayModal(printCode, printParameters, input, null);
          return;
        }

        setExecutionState('SHOWED_CUSTOM_VARIABLES');
      } catch (e) {
        console.error('Error processing print', e);
        setPrintQueue(([, ...tail]) => tail);
        setExecutionState('IDLE');
        reject(e);
      }
    }, [printQueue]);

  useEffect(() => {
    if(executionState === 'IDLE' && printQueue.length > 0) {
      console.debug('Processing new print item', printQueue[0].input);
      processQueue();
    }
  }, [printQueue, executionState, processQueue]);

  const close = async (): Promise<void> => {
    const [{resolve, input}, ] = printQueue;
    const displayedPrint = printed.current;
    setPrintQueue(([, ...tail]) => tail);
    setExecutionState('IDLE');

    resolve({printed: displayedPrint});

    if(!displayedPrint) {
      return;
    }

    if(!printOutput.current) {
      throw new Error('Missing print output on close');
    }

    const printWrapper = printOutput.current.printWrapper;

    const printCode = printWrapper.prints[0].code;
    await printService.handlePassbook(printCode, input.printProviderInput);
    if (printWrapper.printHistoryEntryIds && printWrapper.printHistoryEntryIds.length > 0) {
      await printService.registerPrintEvent({
        printHistoryEntryIds: printWrapper.printHistoryEntryIds
      });
    }
  };

  const displayPrint = async (): Promise<void> => {
    if (!iframeRef.current) {
      throw new Error('Missing print iframe');
    }

    printed.current = true;
    iframeRef.current.contentWindow?.print();
  };


  const generateCustomPrintAndDisplayModal = async (customVariables: Record<string, unknown>) : Promise<void> => {
    const [head, ] = printQueue;
    const {input} = head;
    const {printCode, printParameters} = getCodeAndParams(input.printDescription);

    await generatePrintAndDisplayModal(printCode, printParameters, input, customVariables);
  };

  const [head, ] = printQueue;
  const printOutputRef = printOutput.current;
  if(executionState === 'SHOWED_CUSTOM_VARIABLES' && head.print) {
    return <>
      <NxPopup open={true}
               onClose={close}
               header={`Print: ${head.print.name}`}
      >
        <PrintCustomVariableList
          variables={head.print.lines
            .filter(line => line.type === 'CUSTOM')
            .map(line => ({
              name: line.field,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              type: line.customVariableConfig.CUSTOM_VARIABLE_TYPE!,
              label: line.text,
              required: !!line.customVariableConfig.REQUIRED
            }))
          }
          onVariablesProvided={generateCustomPrintAndDisplayModal}
          onClose={close}/>
      </NxPopup>
      <props.children show={show} />
    </>;
  }
  else if(executionState === 'SHOWED_PRINT' && head.print) {
    if(!printOutputRef) {
      throw new Error('Missing printRef');
    }

    const printOutput = printOutputRef.printWrapper.prints[0];
    const sandbox = printOutput.type === 'TEMPLATE' ? {
      'sandbox': 'allow-same-origin allow-modals'
    } : {};

    return <>
      <NxPopup open={true}
               onClose={close}
               header={`Print: ${head.print.name}`}
      >
        <NxStack>
          <iframe
            className={styles.iframe}
            src={printOutputRef.url}
            frameBorder={0}
            {
              ...sandbox
            }
            ref={iframeRef}>
          </iframe>

          <NxRow position={NxRowPosition.END}>
            <NxButton onClick={displayPrint}>Print</NxButton>
            <NxButton variant={NxButtonVariant.CLOSE}
                      onClick={close}>
                Close
            </NxButton>
          </NxRow>
        </NxStack>
      </NxPopup>
      <props.children show={show} />
    </>;
  }

  return <props.children show={show}/>;
};

export default PrintModal;
