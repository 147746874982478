import BigNumber from 'bignumber.js';
import _ from 'lodash';
import nxModule from 'nxModule';

import templateUrl from './common-income-source.template.html';
import {incomeSourceTypes} from './constants';

class CommonIncomeSource {

  natureOfWorkOptions = [];
  natureTypeOptions = [];
  businessTypeOptions = [];
  businessStructureOptions = [];
  showRemarks = false;

  constructor(dict, propertyConfigService)  {
    this.dict = dict;
    this.propertyConfigService = propertyConfigService;
    this.businessStructureOptions = ['SOLE_PROPRIETORSHIP', 'PARTNERSHIP', 'CORPORATION'];
  }

  $onInit() {
    this.cfg = this.propertyConfigService;
    this.types = incomeSourceTypes.filter(type => type.code !== 'DEPED');
    let defaultCountryCode = this.cfg.defaultValue('customer', 'income', 'country');
    this.defaultCountry = _.find(this.dict['COUNTRY'], {code: defaultCountryCode});

    this.dict.onLoadingComplete(() => {
      this.natureOfWorkOptions = this.dict['NATURE_OF_WORK_BUSINESS'];
      this.refreshNatureOfWorkFields();
    });
  }

  refreshNatureOfWorkFields = () => {
    this.natureTypeOptions = this.dict.filterByAttribute('NOWB_TYPE', 'parentDictEntryId', this.sourceOfIncome.natureOfWorkId);
    if (!this.natureTypeOptions.find(v => v.id === this.sourceOfIncome.natureTypeId)) {
      this.sourceOfIncome.natureTypeId = null;
    }

    this.businessTypeOptions = this.dict.filterByAttribute('NOWB_BUSINESS_TYPE', 'parentDictEntryId', this.sourceOfIncome.natureOfWorkId, this.sourceOfIncome.natureTypeId);
    if (!this.businessTypeOptions.find(v => v.id === this.sourceOfIncome.businessTypeId)) {
      this.sourceOfIncome.businessTypeId = null;
    }

    this.showRemarks = this.supportsRemarks(this.natureOfWorkOptions, this.sourceOfIncome.natureOfWorkId)
      || this.supportsRemarks(this.natureTypeOptions, this.sourceOfIncome.natureTypeId)
      || this.supportsRemarks(this.businessTypeOptions, this.sourceOfIncome.businessTypeId);
  };

  set monthlyIncome(newValue) {
    this.sourceOfIncome.annualIncome = new BigNumber(newValue).multipliedBy(12).toNumber();
  }

  // use getter so that ng-model binding is possible and we won't need to update income source
  get monthlyIncome() {
    const annual = this.sourceOfIncome.annualIncome;

    if(annual === undefined || annual === null) {
      return annual;
    }

    return new BigNumber(annual).div(12).toNumber();
  }

  calculateMonthlyIncomeAmount = () => {
    if(this.sourceOfIncome.annualIncome){
      this.sourceOfIncome.monthlyIncome = new BigNumber(this.sourceOfIncome.annualIncome).dividedBy(12).toNumber();
    }
  }

  supportsRemarks(options, selectedValue) {
    const selectedOption = options && options.find(v => v.id === selectedValue);
    return selectedOption
      && selectedOption.attributes
      && selectedOption.attributes.hasOwnProperty('supportsRemarks')
      && Array.isArray(selectedOption.attributes.supportsRemarks)
      && selectedOption.attributes.supportsRemarks.includes(true);
  }
}

nxModule.component('commonIncomeSource', {
  templateUrl,
  controller: CommonIncomeSource,
  bindings: {
    incomeFormTitle: '<',
    companyFormTitle: '<',
    performanceFormTitle: '<',
    editMode: '=',
    removeIncomeSource: '&',
    sourceOfIncome: '<',
    module: '<'
  }
});