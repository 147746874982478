import nxModule from 'nxModule';
import angular from 'angular';

import './custom-fee-board.style.less';
import templateUrl from './custom-fee-board.template.html';
import {HttpService} from "shared/utils/httpService";
import {PaymentIntervalType} from "components/administration/loan/intervals/payment-interval.types";
import {FeeClass, FeeType} from "components/service/fees/fee.types";
import {memoize} from 'lodash/fp';

type BoardColumns = keyof CustomFeeBoard['board']['cells'][number];
interface ColumnWithLabel {
  label: string;
  column: BoardColumns;
}

const unreachable = (illegalOption: never): never => {
  throw new Error(`Illegal option ${illegalOption}`);
};

const requiredFields = [
  'minTerm',
  'minAmount',
  'collectionType',
  'minTransactionAmount',
  'minPrincipal',
  'minBalance',
  'rateTypeId'
];

const availableAmortizationColumns = [
  'minTerm',
  'maxTerm',
  'minAmount',
  'maxAmount',
  'exemptionAmount'
];

class CustomFeeBoard {
  collectByOptions: string[] = ['FULL', 'PROPORTIONAL_TO_TERM'];
  minTermHeader!: string;
  maxTermHeader!: string;
  intervalTypes!: PaymentIntervalType[];

  feeClass!: FeeClass;
  board!: {
    defaultFixedAmount?: number;
    defaultPercentageAmount?: number;
    cells: {
      exemptionAmount?: number;
      maxAmount?: number;
      minAmount?: number;
      fixedAmount?: number
      percentageAmount?: number;
      rateTypeId?: number;
      minPrincipal?: number;
      maxPrincipal?: number;
      minBalance?: number;
      minTerm?: number;
      maxTerm?: number;
      minTransactionAmount?: number;
      maxTransactionAmount?: number;
      minCustomerAge?: number;
      maxCustomerAge?: number;
      paymentIntervals?: number[];
      collectionType?: CustomFeeBoard['collectByOptions'];
    }[],
    amortizationBoard?: boolean;
    amortizationCells?: unknown[];
  };
  feeType!: FeeType;
  boardType!: string;
  applyOn: string | undefined;
  form!: angular.IFormController;
  allColumns: ColumnWithLabel[] = [];
  availableColumns: ColumnWithLabel[] = [];

  // selected optional columns to show
  optionalColumnNames: string[] = [];
  // all possible optional columns
  optionalColumnNameOptions: ColumnWithLabel[] = [];
  columnsWithValues: string[] = [];

  readonly paymentIntervalsConfig = {
    labelField: ['name'],
    valueField: 'id',
    sortField: 'name',
    searchField: ['name']
  };

  constructor(private $scope: angular.IScope, private dict: unknown, private http: HttpService) {  }

  async $onInit(): Promise<void> {
    this.allColumns = [
      {label: "Client type", column: "rateTypeId"},
      {label: "Min principal (inclusive)", column: "minPrincipal"},
      {label: "Max principal (exclusive)", column: "maxPrincipal"},
      {label: "Min balance (inclusive)", column: "minBalance"},
      {label: this.feeClass === 'DOC_STAMP' ? "Min calendar days" : "Min term", column: "minTerm"},
      {label: this.feeClass === 'DOC_STAMP' ? "Max calendar days" : "Max term", column: "maxTerm"},
      {label: "Min transaction amount (inclusive)", column: "minTransactionAmount"},
      {label: "Max transaction amount (exclusive)", column: "maxTransactionAmount"},
      {label: "Payment interval", column: "paymentIntervals"},
      {label: "Collect", column: "collectionType"},
      {label: "Min value", column: "minAmount"},
      {label: "Max value", column: "maxAmount"},
      {label: "Min customer age", column: "minCustomerAge"},
      {label: "Max customer age", column: "maxCustomerAge"},
      {label: "Exemption amount", column: "exemptionAmount"}
    ];

    this.recalculateAvailableColumns();

    this.intervalTypes = await this.http.get<PaymentIntervalType[]>(`/products/loans/intervals`).toPromise();
  }

  recalculateAvailableColumns(): void {
    this.availableColumns = this.allColumns.filter(col => this.isAvailableColumn(col.column));
    const previousColumnsWithValue = this.columnsWithValues;

    this.columnsWithValues = this.availableColumns.filter(col => {
      const cells = (this.board.amortizationBoard && availableAmortizationColumns.includes(col.column)) ? this.board.amortizationCells: this.board.cells;
      if(!cells || cells.length === 0) {
        return false;
      }

      return cells.some(cell => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = cell[col.column];
        if(value === null || value === undefined || value === '') {
          return false;
        }

        if(Array.isArray(value) && value.length === 0) {
          return false;
        }

        return true;
      });
    }).map(col => col.column);

    this.optionalColumnNameOptions = this.availableColumns.filter(col => !requiredFields.includes(col.column) && !this.columnsWithValues.includes(col.column));
    // it may happen that we just edited a field, we don't want to make it disappear but if possible want to add to optional column names
    this.optionalColumnNames = [
      ...this.optionalColumnNames,
      ...previousColumnsWithValue
    ]
      .filter(name => this.optionalColumnNameOptions.some(col => col.column === name));
  }

  getVisibleProductColumns(): ColumnWithLabel[] {
    return this.getVisibleProductColumnsMemoized(this.optionalColumnNames, this.availableColumns, this.columnsWithValues, this.board.amortizationBoard);
  }

  getVisibleProductColumnsMemoized = memoize((optionalColumnNames: string[], availableColumns: ColumnWithLabel[], columnsWithValues: string[], amortizationBoard: boolean | undefined) =>
    this.getVisibleColumns([...optionalColumnNames, ...columnsWithValues], availableColumns)
      .filter(col => !amortizationBoard
        || !availableAmortizationColumns.includes(col.column)
        // always include minTerm as this should be added/required in both LoanBoardCell and LoanBoardAmortizationCell
        || col.column === 'minTerm'
    )
  );

  getAmortizationVisibleColumns(): ColumnWithLabel[] {
    return this.getAmortizationVisibleColumnsMemoized(this.optionalColumnNames, this.availableColumns, this.columnsWithValues, this.board.amortizationBoard);
  }

  getAmortizationVisibleColumnsMemoized = memoize((optionalColumnNames: string[], availableColumns: ColumnWithLabel[], columnsWithValues: string[], amortizationBoard: boolean | undefined) => {
    return this.getVisibleColumns([...optionalColumnNames, ...columnsWithValues], availableColumns)
      .filter(col => amortizationBoard && availableAmortizationColumns.includes(col.column));
  });

  getVisibleColumns(visibleColumns: string[], availableColumns: ColumnWithLabel[]): ColumnWithLabel[] {
    return availableColumns
      .filter(col => visibleColumns.includes(col.column) || requiredFields.includes(col.column));
  }

  $onChanges(changes: angular.IOnChangesObject): void {
    if ('feeType' in changes) {
      if (this.feeType === 'PERCENTAGE') {
        this.board.defaultFixedAmount = undefined;
        this.board.cells.forEach(c => {
          c.fixedAmount = undefined;
        });
      } else {
        this.board.defaultPercentageAmount = undefined;
        this.board.cells.forEach(c => {
          c.percentageAmount = undefined;
        });
      }

      this.recalculateAvailableColumns();
    }

    if ('applyOn' in changes) {
      if (this.applyOn !== 'LOAN_PAYMENT') {
        this.board.amortizationBoard = false;
        this.amortizationBoardChange();
      }
    }
  }

  isAvailableColumn(name: BoardColumns): boolean {
    switch(name) {
      case 'rateTypeId':
      case 'minPrincipal':
      case 'maxPrincipal':
      case 'paymentIntervals':
      case "minCustomerAge":
      case "maxCustomerAge":
        return this.boardType === 'LOAN_BOARD';
      case "minBalance":
        return this.boardType === 'ACCOUNT_FEE_BOARD';
      case 'minTerm':
      case 'maxTerm':
        return !['AGENT_FEE_BOARD', 'ACCOUNT_FEE_BOARD'].includes(this.boardType);
      case 'minTransactionAmount':
      case 'maxTransactionAmount':
        return this.boardType === 'AGENT_FEE_BOARD';
      case "exemptionAmount":
      case "maxAmount":
        return ['LOAN_BOARD', 'ACCOUNT_FEE_BOARD'].includes(this.boardType) && this.feeType === 'PERCENTAGE';
      case "minAmount":
        return ['LOAN_BOARD', 'ACCOUNT_FEE_BOARD'].includes(this.boardType) && this.feeType === 'PERCENTAGE';
      case "fixedAmount":
        return this.feeType === 'FIXED';
      case "percentageAmount":
        return this.feeType === 'PERCENTAGE';
      case "collectionType":
        if(this.boardType === 'LOAN_BOARD' && this.feeClass === 'DOC_STAMP') {
          return true;
        }

        return this.boardType === 'DEPOSIT_DOC_STAMP_BOARD';
    }

    unreachable(name);
  }

  addNewBoardCell(): void {
    const newCell = {};
    this.board.cells.push(newCell);
  }

  addNewAmortizationBoardCell(): void {
    if(!this.board.amortizationCells) {
      throw new Error('Missing amortization cells');
    }

    this.board.amortizationCells.push({});
  }

  // when you have a model and remove it from view, angularjs
  // doesnt detect it as a change in a form, therefore
  // we need to trigger it manually for loanProductDetails.confirmChanges
  makeFormDirty(): void {
    if(this.form) {
      this.form.$setDirty();
    }
  }

  removeBoardCell(index: number): void {
    this.board.cells.splice(index, 1);
    this.makeFormDirty();
    this.recalculateAvailableColumns();
  }

  removeAmortizationBoardCell(index: number): void {
    if(!this.board.amortizationCells) {
      throw new Error('Missing amortization cells');
    }

    this.board.amortizationCells.splice(index, 1);
    this.makeFormDirty();
    this.recalculateAvailableColumns();
  }

  amortizationBoardChange(): void {
    // When amortization board is disabled remove the amortization cells
    if (!this.board.amortizationBoard) {
      this.board.amortizationCells = undefined;
    }

    // When amortization board is enabled remove fee values from board cells - fees are defined in amortizationCells.
    if (this.board.amortizationBoard) {
      this.board.cells.forEach(cell => {
        cell.fixedAmount = undefined;
        cell.percentageAmount = undefined;
        cell.minAmount = undefined;
        cell.maxAmount = undefined;
        cell.exemptionAmount = undefined;
      });
      this.board.amortizationCells = [];
    }

    this.recalculateAvailableColumns();
  }
}

nxModule.component('customFeeBoard', {
  templateUrl,
  bindings: {
    /**
     * board: {
     *   defaultRate: 300.00,
     *   cells: [{
     *     rateType         : [PRIME, AVERAGE, NON_PRIME],
     *     minTerm          : 123,    // days
     *     maxTerm          : 365,    // days
     *     minPrincipal     : 13.34,  // currency amount
     *     maxPrincipal     : 34.00,  // currency amount
     *     rate             : 300.00, // currency amount
     *     minAmount        : 300.00, // currency amount
     *    }, ...],
     *    // If the board uses an amortization board, the fee values are defined in amortizationCells, per amortization
     *    // that falls in the term range defined in amortizationCells
     *    amortizationBoard: true,
     *    amortizationCells: [{
     *     minTerm          : 123,    // days
     *     maxTerm          : 365,    // days
     *     rate             : 300.00, // currency amount
     *     minAmount        : 300.00, // currency amount
     *    }, ...]
     */
    board: '=',
    /**
     * PERCENTAGE - board.cells.rate represents percentage value
     * FIXED -  board.cells.rate represents cash value
     */
    feeType: '<',
    feeClass: '<',
    boardType: '<',
    applyOn: '<',
  },
  require: {
    form: '?^form',
  },
  controller: CustomFeeBoard
});
