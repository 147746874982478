import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {useHistory} from 'react-router';

const NxCancelButton = (): ReactElement => {
  const history = useHistory();
  return <NxButton variant={NxButtonVariant.CLOSE}
            onClick={(): void => history.goBack()}>
    Cancel
  </NxButton>;
};

export default NxCancelButton;
