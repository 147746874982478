import nxModule from 'nxModule';
import templateUrl from './related-customers.template.html';

class CustomerProfileRelatedCustomers {

  constructor($scope, $location, notification, dict, branchService, confirmation, propertyConfigService) {
    this.$scope = $scope;
    this.$location = $location;
    this.notification = notification;
    this.dict = dict;
    this.branchService = branchService;
    this.confirmation = confirmation;
    this.cfg = propertyConfigService;

    this.isRelativeSignatory = false;

    //wait for profile to be resolved
    this.$scope.$watch('$ctrl.profile.relatedCustomers', async () => {
      if (_.isArray(this.profile.relatedCustomers) && !_.isEmpty(this.profile.relatedCustomers)) {
        this.dict.onLoadingComplete(() => {
            this.isAnyRelativeSignatory(this.profile.relatedCustomers, this.dict.RELATIVE_TYPE);
            this.profile.relatedCustomers.forEach(r => r.natureOfWork = this.resolveNatureOfWork(r));
          }
        );

        if (!this.startingRelatives) {
          this.startingRelatives = angular.copy(this.profile.relatedCustomers);
        }

        if (!this.branches) {
          this.branches = await this.branchService.toPromise();
        }
        this.profile.relatedCustomers.forEach(r => {
          r.branchName = (_(this.branches).find({id: r.branchId})|| {}).name
        });
      }
    }, true);
  }

  async addRelative(customer) {
    if (customer.id == this.profile.id) {
      this.notification.show('Warning', 'Customer cannot be relative to himself.');
      return;
    }
    if (_.find(this.profile.relatedCustomers, {relativeCustomerId: customer.id})) {
      this.notification.show('Warning', 'Customer has already been added.');
      return;
    }
    const r = await this.customerSearchToRelative(customer);
    this.profile.relatedCustomers.push(r);
  }

  async removeRelative(rel) {
    var array = this.profile.relatedCustomers;
    var index = array.indexOf(rel);
    if (this.checkRelationship(rel.typeId, this.dict.RELATIVE_TYPE) && this.profile.customerType === 'CORPORATE') {
      if (await this.confirmation(`Deleting this relationship will remove this customer as signatory from ${this.profile.effectiveName} products.
        Do you want to proceed?`)) {
        array.splice(index, 1);
      }
    } else {
      array.splice(index, 1);
    }
  }

  showWarningOnSignatoryRemoval(relative) {
    this.isAnyRelativeSignatory(this.profile.relatedCustomers, this.dict.RELATIVE_TYPE);
    this.signatoryWarning(relative);
  }

  isAnyRelativeSignatory(relatives, relativeTypes) {
    const signatoryType = relativeTypes.find(rel => 'SIGNATORY' === rel.code);
    const relative = relatives.find(rel => signatoryType.id === rel.typeId);
    this.isRelativeSignatory = !!relative;
  }

  signatoryWarning(relative) {
    let existingRelative = this.startingRelatives.find(r => r.id === relative.id);
    if (existingRelative
      && this.profile.customerType === 'CORPORATE'
      && this.checkRelationship(existingRelative.typeId, this.dict.RELATIVE_TYPE)
      && !this.checkRelationship(relative.typeId, this.dict.RELATIVE_TYPE)) {
      this.notification.show('Warning', 'Changing type to non-Signatory. Relative will be removed from products.')
      relative.withdrawalLimit = null;
    }
  }

  checkRelationship(id, relativeTypes) {
    const relative = relativeTypes.find(value => id === value.id);
    return relative && relative.code === 'SIGNATORY';
  }

  openCustomer(customerId) {
    let redirectionUrl = `/customer/${customerId}/accounts`;
    this.$location.path(redirectionUrl);
  }

  async customerSearchToRelative(customer) {
    const mobilePhones = customer.phones.filter(phone => phone.type === 'MOBILE');
    const primaryMobilePhone = mobilePhones.find(p => p.primary) || mobilePhones[0];
    const phoneNumber = primaryMobilePhone ? primaryMobilePhone.number : null;

    const primaryIncomeSource = customer.incomeSources.find(inc => inc.primary);
    const natureOfWorkId = primaryIncomeSource?.natureOfWorkId;

    const branches = await this.branchService.toPromise();
    return {
      effectiveName: customer.effectiveName,
      extension: customer.individualData?.extension,
      birthDate : customer.individualData?.birthDate,
      birthPlace: customer.individualData?.birthPlace,
      mobileNumber: phoneNumber,
      natureOfWorkId: natureOfWorkId,
      customerNumber : customer.customerNumber,
      branchId : customer.branchId,
      branchName : branches.find(b => b.id === customer.branchId).name,
      relativeCustomerId: customer.id,
      withdrawalLimit: customer.withdrawalLimit,
      typeId : null,
      beneficiary : false,
      dependent : false
    };
  }

  resolveNatureOfWork(customer) {
    return this.dict.getDescription('NATURE_OF_WORK_BUSINESS', customer.natureOfWorkId);
  }

  getRelationshipTypeOptions() {
    const relationshipTypes = this.dict.filterByAttribute('RELATIVE_TYPE', 'customerType', this.profile.customerType, 'ANY');
    return this.profile.customerType === 'CORPORATE' ? relationshipTypes.filter(rt => !['CO_BORROWER', 'CO_MAKER'].includes(rt.code)) : relationshipTypes;
  }
}

nxModule.component('customerProfileRelatedCustomers', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '<'
  },
  controller: CustomerProfileRelatedCustomers
});
