import nxModule from 'nxModule';
import _ from 'lodash';
import {agentTransactions, initialDepositOperations} from "constants/deposit";
import {interestCycleTypes} from "constants/account";
import {autoCloseIntervals} from "constants/autoCloseInterval";
import {AgentTransaction} from "components/agent-banking/agent.types";
import './deposit-account-section.styles.less';

const templateUrl = require('./deposit-account-section.template.html');
const agentTransactionEnum = AgentTransaction;

const maxCreditBalanceTypes = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Product balance',
    value: 'PRODUCT_BALANCE',
  }
];

nxModule.component('depositAccountSection', {
  templateUrl,
  bindings: {
    sectionType: '<',
    generalForm: '=',
    interestsForm: '=',
    chargesForm: '=',
    transactionLimitsForm: '=',
    accountType: '<',
    branches: '<'
  },
  controller: function ($scope, $filter, casaTypesCache, brisReportingTypesCache,
                        confirmation, feeDefinitionsCache, authentication) {

    const that = this;

    that.isNewAccount = !that.accountType || !that.accountType.id;

    that.dormancyChargeMax = 50;
    that.daysInYear = [100, 357, 360, 364, 365];
    that.casaTypes = [];
    that.pdicCasaTypes = [];
    that.maxCreditBalanceTypes = maxCreditBalanceTypes;
    that.authentication = authentication;
    that.allowedDepositActivationOperations = initialDepositOperations;
    that.agentTransactions = agentTransactions;
    that.agentTransactionEnum = agentTransactionEnum;

    $scope.$watch('$ctrl.accountType', async () => {
      if (that.accountType) {
        //set initial value for this flag
        if (that.accountType.passbookConfig && that.accountType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED') {
          that.passbookSupported = true;
          that.passbookConfig = that.accountType.passbookConfig;
        } else {
          that.passbookSupported = false;
        }
        const feeDefinitions = await feeDefinitionsCache.toPromise();
        that.originalCreditingScheduler = angular.copy(that.accountType.creditingScheduler);
        that.initSpoPlacementChargeSettings(feeDefinitions);
        that.initWithholdingTax(feeDefinitions);
        that.daysInYearChanged();
        that.cycleTypeChanged();
      }
    });

    that.forceDefaultInterestRateOnOptions = [{
      label: 'Never',
      value: 'NEVER'
    },
    {
      label: 'After balance (adb) falls below maintaining balance',
      value: 'ADB_FALLS_BELOW_MIN_BALANCE'
    }, {
      label: 'After the maximum withdrawals base on the transaction limit fee was already exceeded',
      value: 'EXCEEDED_MAX_WITHDRAWALS'
    },
    {
      label: 'Any of both',
      value: 'ADB_FALLS_BELOW_MIN_BALANCE_OR_EXCEEDED_MAX_WITHDRAWALS'
    }];

    that.transactionLimitPeriods = [{
      label: 'Monthly',
      value: 'MONTH'
    }, {
      label: 'Quarterly',
      value: 'QUARTER'
    }, {
      label: 'Yearly',
      value: 'YEAR'
    }];

    that.restrictedAccountCommands = [
      {
        label: 'Cash Withdrawal - over the counter',
        value: 'WithdrawAccountFundsByCash'
      }, {
        label: 'Cash Deposit - over the counter',
        value: 'DepositCashToAccount'
      },
      {
        label: 'Check Deposit',
        value: 'DepositCheckToAccount'
      },
      {
        label: 'Check Encashment',
        value: 'EncashOnUsCheck'
      },
      {
        label: 'Credit On-us Check',
        value: 'CreditOnUsCheck'
      }
    ];

    that.purposes = [{
      label: 'General',
      value: 'GENERAL'
    }, {
      label: 'Contractual savings / CBU',
      value: 'CONTRACTUAL_SAVINGS_CBU'
    }, {
      label: 'Contractual savings / PF',
      value: 'CONTRACTUAL_SAVINGS_PF'
    }, {
      label: 'Contractual savings / TP',
      value: 'CONTRACTUAL_SAVINGS_TP'
    }, {
      label: 'Insurance',
      value: 'INSURANCE'
    }];

    that.subtypes = [{
      label: 'Savings',
      value: 'SAVINGS'
    }, {
      label: 'Checking',
      value: 'CHECKING'
    }, {
      label: 'Combo',
      value: 'COMBO'
    }];

    that.reportingSubtypes = [{
      label: 'Savings',
      value: 'SAVINGS'
    }, {
      label: 'Checking',
      value: 'CHECKING'
    }];

    // remove PASSBOOK_NOT_SUPPORTED since we have separate flag for this (that.passbookSupported)
    that.passbookConfigOptions = [{
      label: 'Yes',
      value: 'PASSBOOK_NOT_REQUIRED'
    }, {
      label: 'No',
      value: 'PASSBOOK_REQUIRED'
    }, {
      label: 'With override',
      value: 'WITH_OVERRIDE'
    }];

    that.spoAndDaudChargeOptions = [{
      label: 'Fixed amount',
      value: false
    }, {
      label: 'Unfunded service charge and penalty per check fee',
      value: true
    }];

    that.typeConflictStrategyOptions = [{
      label: 'Show warning',
      value: 'SHOW_WARNING'
    }, {
      label: 'No action',
      value: "NO_ACTION"
    }];

    that.transferChargeFeeTypeOptions = [{
      label: 'Fixed amount',
      value: "FIXED"
    }, {
      label: 'Percentage',
      value: "PERCENTAGE"
    }];

    that.autoCloseIntervals = autoCloseIntervals;

    casaTypesCache.toObservable()
      .first()
      .subscribe(casaTypes => {
        that.casaTypes = casaTypes;
        that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC'});
      });

    brisReportingTypesCache.withParam('ACCOUNT').toObservable()
      .first()
      .subscribe(brisTypes => {
        that.brisTypes = brisTypes;
      });

    that.passbookConfigChange = () => {
      if (!that.passbookSupported) {
        that.accountType.passbookConfig = 'PASSBOOK_NOT_SUPPORTED';
      } else {
        that.accountType.passbookConfig = that.passbookConfig;
      }
    };

    that.initSpoPlacementChargeSettings = (feeDefinitions) => {
      const spoPlacementChargeDef = _(feeDefinitions).find(feeDef => {
        return Number(feeDef.productDefinitionId) === Number(that.accountType.productDefinition.id) && feeDef.feeClass === 'SPO_PLACEMENT_CHARGE';
      });
      that.accountType.enableSpoPlacementCharge = !!spoPlacementChargeDef && spoPlacementChargeDef.enabled;
    };

    that.initWithholdingTax = (feeDefinitions) => {
      const withholdinfTaxDef = _(feeDefinitions).find(feeDef => {
        return Number(feeDef.productDefinitionId) === Number(that.accountType.productDefinition.id) && feeDef.feeClass === 'WITHHOLDING_TAX';
      });
      if(withholdinfTaxDef){
        that.accountType.withholdingTax = withholdinfTaxDef.percentageAmount;
      }
    };

    that.changeAccrued = () => {
      if (that.accountType.accrued) {
        if (!that.accountType.accrualScheduler) {
          // interest is accrued, but scheduler is not set
          that.accountType.accrualScheduler = {
            cycleType: 'MONTH_END',
            enabled: true,
            executionInterval: 1
          };
        } else {
          that.accountType.accrualScheduler.enabled = true;
          that.accountType.accrualScheduler.executionInterval = 1;
        }
      } else {
        that.accountType.accrualScheduler = null;
      }
    };

    that.changeSubtype = () => {
      that.accountType.accountReportingSubtype = null;
      if (['SAVINGS', 'CHECKING'].includes(that.accountType.accountSubtype)) {
        that.accountType.accountReportingSubtype = that.accountType.accountSubtype;
      }

      if (that.accountType.accountSubtype === 'SAVINGS') {
        that.accountType.enableSpoPlacementCharge = false;
      } else {
        that.accountType.autoCloseContractualSavings = true;
      }
    };

    that.changeDormant = async () => {
      // change from 'support dormancy' -> 'don't support dormancy'
      // and current account type is not a new one
      if (!that.accountType.dormant && that.accountType.id) {
        const actionApproved = await confirmation(
          `This action disables dormancy for accounts of this type. <strong>If any dormant
          ${that.accountType.productDefinition.productName.toUpperCase()} exist
          it must be activated manually.</strong> Click 'Yes' if you want to proceed, or 'No'
          if you want to cancel.`, true
        );
        if (!actionApproved) {
          that.accountType.dormant = true;
        }
      }
    };

    const resetChargeBasedOnType = (charge) => {
      if (charge && charge.feeType) {
        if (charge.feeType === 'FIXED') {
          charge.percentageValue = null;
        } else if (charge.feeType === 'PERCENTAGE') {
          charge.fixedValue = null;
        }
      }
    };

    that.changeSelfTransferChargeType = () => {
      if (that.accountType) {
        resetChargeBasedOnType(that.accountType.selfTransferCharge);
      }
    };

    that.changeInternalTransferChargeType = () => {
      if (that.accountType) {
        resetChargeBasedOnType(that.accountType.internalTransferCharge);
      }
    };

    that.resetTransactionLimitConfig = () => {
      if (!that.accountType.enableTransactionLimit) {
        that.accountType.transactionLimit.extraOptions = null;
      }
    };

    that.allowedLimitByCustomerAge = () => {
      return (this.accountType?.productDefinition.customerTypeConstraints ?? []).includes('INDIVIDUAL')
          && this.accountType?.productDefinition?.customerTypeConstraints?.length === 1;
    };

    that.changeLimitByCustomerAge = () => {
      if (!that.accountType.limitByCustomerAge) {
        that.accountType.minCustomerAge = null;
        that.accountType.maxCustomerAge = null;
      }
    };

    that.changeCustomerTypeConstraint = () => {
      if (!that.accountType) return;
      if (!that.allowedLimitByCustomerAge()) {
        that.accountType.limitByCustomerAge = false;
        that.changeLimitByCustomerAge();
      }
    };

    that.hasAgentReadPermission = () => {
      return that.authentication.permissions['AGENT_READ'];
    };

    that.allowsAgentTransaction = (commandName) => {
      return that.accountType?.productDefinition?.allowedAgentTransactions?.includes(commandName);
    }

    that.editFeeDefinition = fee => {
      that.feeDefinitionOldValues = _.cloneDeep(fee);
      that.selectedFeeForEdit = fee;
      that.chargesForm.wizardMode = true;
    };

    that.cancelFeeDefinitionEdition = () => {
      if (that.feeDefinitionOldValues) {
        Object.assign(that.selectedFeeForEdit, that.feeDefinitionOldValues);
      }
      that.selectedFeeForEdit = null;
      that.feeDefinitionOldValues = null;
      that.chargesForm.wizardMode = false;
    };

    that.saveFeeDefinition = () => {
      that.selectedFeeForEdit = null;
      that.chargesForm.wizardMode = false;
    };

    that.isAgentFeesConfigurationValid = () => {
      if (!that.accountType?.productDefinition?.allowedAgentTransactions) {
        return true;
      }

      const allowedAgentTransactions = that.accountType.productDefinition.allowedAgentTransactions;

      const balanceInquiryFeesValid = !allowedAgentTransactions.includes(agentTransactionEnum.AGENT_ACCOUNT_BALANCE_INQUIRY)
        || that.accountType.agentBalanceInquiryCharge.extraOptions?.AGENT_CHARGE_COLLECTION_TYPE;

      const depositFeesValid = !allowedAgentTransactions.includes(agentTransactionEnum.AGENT_ACCOUNT_CASH_DEPOSIT)
        || that.accountType.agentDepositCharge.extraOptions?.AGENT_CHARGE_COLLECTION_TYPE;

      const withdrawFeesValid = !allowedAgentTransactions.includes(agentTransactionEnum.AGENT_ACCOUNT_CASH_WITHDRAWAL)
        || that.accountType.agentWithdrawalCharge.extraOptions?.AGENT_CHARGE_COLLECTION_TYPE;

      return balanceInquiryFeesValid
        && depositFeesValid
        && withdrawFeesValid;
    }

    that.daysInYearChanged = () => {
      that.cycleTypes = interestCycleTypes.filter(c => c.value !== 'EVERY_30_DAYS')

      if (that.accountType.productDefinition.daysInYear === 360) {
        that.cycleTypes.push({
          label: 'Every 30 days',
          value: 'EVERY_30_DAYS'
        })
      }
    }

    that.cycleTypeChanged = () => {
      that.accrualCycleType = [{
        label: 'Daily',
        value: 'DAY'
      }, {
        label: 'Month End',
        value: 'MONTH_END'
      }];

      if (that.accountType.creditingScheduler.cycleType !== 'EVERY_30_DAYS') {
        that.accrualCycleType.push({
          label: 'Quarter End',
          value: 'QUARTER_END'
        })
      }
    }
  }
});
