import nxModule from 'nxModule';
import _ from 'lodash';
import StateMachine from 'javascript-state-machine';
import moment from 'moment';
import {CustomerProfile, CustomerType} from '../../../customer/profile/customer-profile.types';
import {IFormController, ILocationService} from 'angular';
import {CreateLoanInput} from '../../../service/create-loan-input.types';
import {RecursivePartial} from 'shared/utils/RecursivePartial';
import {LosApplicationCache, LosApplicationCacheObject} from '../los-application.cache';
import {LoanType} from '../../../service/loan-type.types';

import templateUrl from './loan-origination-application-create.template.html';
import {ProfileUtilityService} from '../../../customer/profile/profile-utility.service';
import {StateMachineConfig} from '../types/los-state-machine.types';
import {LosApplication} from '../types/los-application.types';
import {LOSStateMachineConfig} from './state-machine-config';
import {CustomerService} from "components/service/customer.service";
import {CustomerCache} from "components/service/customer.cache.types";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import Notification from "shared/utils/notification";
import {ProfileUpdateService} from "components/customer/profile/profile-update.service.types";
import {Breadcrumbs} from "angular-breadcrumbs";
import {SystemDateService} from "components/service/system-date.service.types";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import {Confirmation} from "shared/common/confirmation.types";

class LoanOriginationApplicationCreate {
  // binding - used to edit application saved in local storage
  private applicationId?: string;
  private persistedApplicationId?: number;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private stateMachine: StateMachine.StateMachine;
  private stateMachineConfig: StateMachineConfig | null = null;

  customerForm!: IFormController;
  loanForm!: IFormController;

  private localApplication?: LosApplicationCacheObject;
  private profile: RecursivePartial<CustomerProfile> | null = null;
  private loanType: LoanType | null = null;
  private loan: RecursivePartial<CreateLoanInput> | null = null;

  // callback used by <customer-loan-create-list-table>
  chooseLoanType: ({ productType }: {productType: LoanType}) => void = ({ productType, }) => {
    this.loanType = productType;
    this.loan = {
      customerId: this.profile?.id,
      loanTypeId: this.loanType.id,
      loanInformation: {},
      automaticTransferAgreement: {
        transferStrategy: 'NONE'
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.next();
  };

  constructor(private $location: ILocationService,
              private http: HttpService,
              private profileUtilityService: ProfileUtilityService,
              private customerService: CustomerService,
              private customerCache: CustomerCache,
              private losApplicationCache: LosApplicationCache,
              private loanProductsCache: LoanProductsCache,
              private notification: Notification,
              private authentication: Authentication,
              private confirmation: Confirmation,
              private popup: Popup,
              private command: CommandService,
              private profileUpdateService: ProfileUpdateService,
              private breadcrumbs: Breadcrumbs,
              private systemDateService: SystemDateService) {
  }

  isCustomerEditEnabled(): boolean {
    // enable edit only for new customer (id is not present or customer is PROSPECT)
    return !!this.profile && (!this.profile.id || this.profile.status === 'PROSPECT');
  }

  showWarningAboutIncompleteData(): boolean {
    // if customer already exists we allow to proceed with incomplete data
    // since it can't be edited through LOS form.
    return this.customerForm && this.customerForm.$invalid && !!this.profile?.id && this.profile.status === 'ACTIVE';
  }

  async $onInit() {
    // if applicationId was provided - load application from local storage
    if (this.applicationId) {
      const application = this.losApplicationCache.get(this.applicationId)!;
      const existingCustomerId = application.profile.id;
      this.profile = existingCustomerId ?
        await this.customerCache.profile(existingCustomerId).toPromise() :
        application.profile;
      this.loan = application.loan;
      this.localApplication = application;
      const loanTypes = await this.loanProductsCache.toPromise();
      this.loanType = _.find(loanTypes, loanType => loanType.id === application.loan.loanTypeId)!;

      // init state machine and open saved application on summary screen
      this.stateMachineConfig = LOSStateMachineConfig.getDefaultStateMachineConfig();
      this.stateMachineConfig.init = 'SUMMARY';
    } else if (this.persistedApplicationId) {
      const application = await this.http.get<LosApplication>(`/los/applications/${this.persistedApplicationId}`).toPromise();
      this.profile = this.customerService.extractPhones(application.customer);
      this.loan = application.loanInputObject;
      if (this.loan.collateralFileIds) {
        this.loan.collateralFiles = this.loan.collateralFileIds.map(fileId => ({ id: fileId }));
      }
      const loanTypes = await this.loanProductsCache.toPromise();
      this.loanType = _.find(loanTypes, loanType => loanType.id === application.loanInputObject.loanTypeId)!;

      // init state machine and open saved application on customer profile screen
      this.stateMachineConfig = LOSStateMachineConfig.getSimplifiedStateMachineConfig();
      this.stateMachineConfig.init = 'CUSTOMER_PROFILE';
    }

    if (!this.stateMachineConfig) this.stateMachineConfig = LOSStateMachineConfig.getDefaultStateMachineConfig();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine = new StateMachine(this.stateMachineConfig);
  }

  async initCustomer(type: CustomerType) {
    const profile = await this.profileUtilityService.initCustomerProfile();
    this.profile = { ...profile, customerType: type };
  }

  async chooseCustomerNewIndividual() {
    await this.initCustomer('INDIVIDUAL');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.next();
  }

  async chooseCustomerNewCorporate() {
    await this.initCustomer('CORPORATE');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.next();
  }

  async chooseCustomerExisting(customer: { id: number, customerType: CustomerType }) {
    if (customer.customerType === 'GROUP') {
      this.popup({
        text: '<strong>Group customers are not supported.</strong> Only individual and corporate customers can be chosen.',
        renderHtml: true
      });
      return;
    }

    this.profile = await this.customerCache.profile(customer.id).toPromise();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.next();
  }

  async goToCustomerProfile() {
    const warningMsg = 'You are going to exit application form. Do you want to proceed?';
    const proceed = await this.confirmation(warningMsg);
    if (proceed) this.$location.path(`/customer/${this.profile?.id}/profile`);
  }

  async changeCustomerType() {
    const warningMsg = 'Customer type change resets currently configured loan. Do you want to proceed?';
    const proceed = await this.confirmation(warningMsg);
    if (!proceed) return;

    // reset already configured profile and loan
    this.profile = null;
    this.loan = null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.changeCustomerType();
  }

  canChangeCustomerType() {
    return this.stateMachine.can('changeCustomerType');
  }

  changeCustomer() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.changeCustomer();
  }

  async changeLoanType() {
    const warningMsg = 'Loan type change resets currently configured loan. Do you want to proceed?';
    const proceed = await this.confirmation(warningMsg);
    if (!proceed) return;

    // reset already configured loan
    this.loan = null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.changeLoanType();
  }

  canChangeLoanType() {
    return this.stateMachine.can('changeLoanType');
  }

  changeLoan() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stateMachine.changeLoan();
  }

  async exitApplicationForm(): Promise<void> {
    if (await this.exitApplicationFormConfirmation()) {
      this.redirectBack();
    }
  }

  async exitApplicationFormConfirmation(afterSaving = false): Promise<boolean> {
    const warningMsg = `Are you sure you want to${afterSaving ? ' save and' : ''} exit the application form?`;
    return await this.confirmation(warningMsg);
  }

  /**
   * Save application locally in the browser
   */
  async saveApplication() {
    if(!this.loanType) {
      throw new Error('Missing loan');
    }

    const createdOn = this.localApplication ? this.localApplication.createdOn : (await this.systemDateService.getCurrentUserBranchSystemDate()).format('YYYY-MM-DD');
    const existingCIF = !!this.profile?.id;
    const customerName = this.customerService.effectiveName(this.profile);
    const loanTypeName = this.loanType.productDefinition.productName;
    const application: LosApplicationCacheObject = {
      createdOn,
      existingCIF,
      customerName,
      loanTypeName,
      profile: this.profile as CustomerProfile,
      loan: this.loan as CreateLoanInput,
      userId: this.authentication.context.id
    };

    if (await this.exitApplicationFormConfirmation(true)) {
      const applicationId = this.applicationId || moment().valueOf();
      this.losApplicationCache.put(applicationId, application);
      this.notification.show('Successfully saved application');
      this.redirectBack();
    }
  }

  print() {
    window.print();
  }

  async finalizeApplication() {
    const customerInput = this.profileUpdateService.convertToCommandInput(this.profile);
    const loanInput = this.prepareLoanRequest();

    let request;
    if (this.profile?.id) {
      request = { customerId: this.profile.id, loanInput };
    } else {
      request = { customerInput, loanInput };
    }
    await this.command.execute('LOSCreateApplication', request).toPromise();
    if (this.applicationId) {
      this.losApplicationCache.remove(this.applicationId);
    }

    this.redirectBack();
  }

  private prepareLoanRequest() {
    if(!this.loan) {
      throw new Error('Missing loan');
    }

    const loanInput = _.cloneDeep(this.loan);
    loanInput.feeOverride = loanInput.feeOverride || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loanInput.collateralFileIds = _.map(loanInput.collateralFiles, file => file.id);
    loanInput.collateralFiles = undefined;
    return loanInput;
  }

  async updatePersistedApplication() {
    let request;
    const applicationId = this.persistedApplicationId;
    const loanInput = this.prepareLoanRequest();

    if (this.profile?.status === 'PROSPECT') {
      const customerInput = this.profileUpdateService.convertToCommandInput(this.profile);
      request = { applicationId, customerInput, loanInput };
    } else {
      request = { applicationId, loanInput };
    }

    const { approvalRequired } = await this.command.execute('LOSUpdateApplication', request).toPromise();
    if (!approvalRequired) {
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path(_.nth<{ path: string }>(this.breadcrumbs.get(), (this.applicationId ? -3 : -2))!.path);
  }

}

nxModule.component('loanOriginationApplicationCreate', {
  templateUrl,
  bindings: {
    applicationId: '<',
    persistedApplicationId: '<'
  },
  controller: LoanOriginationApplicationCreate
});
