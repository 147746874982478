import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';
import {DosriType} from 'components/customer/profile/customer-profile.types';

const templateUrl = require('./corporate-data.template.html');
nxModule.component('customerProfileCorporateData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '='
  },
  controller: function ($scope, $timeout, dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.dosriTypes = Object.values(DosriType)
    that.corporationBusinessTypeDictEntry = that.dict['BUSINESS_TYPE'].find(de => de.code === 'CORPORATION');

    that.firmSize = () => {
      if (that.profile.incomeSources.filter(s => s.type === 'BUSINESS').length === 0) {
        return;
      }

      const businessAssets = sum(that.profile.incomeSources.filter(s => s.type === 'BUSINESS').map(s => s.companyTotalAssets)).toNumber()
      let firmSize;
      if (businessAssets <= 3000000) {
        firmSize = 'Micro Enterprise'
      } else if (businessAssets <= 5000000) {
        firmSize = 'Small Enterprise'
      } else if (businessAssets <= 100000000) {
        firmSize = 'Medium Enterprise'
      } else {
        firmSize = 'Large Enterprise'
      }
      return firmSize;
    }

    that.dosriChanged = () => {
      if (!that.profile.dosri) {
        that.profile.dosriType = null;
      }
    };

    that.isCorporationBusinessType = () => {
      if (!that.corporationBusinessTypeDictEntry) {
        return false;
      }

      let businessTypeId = that.profile.corporateData.businessTypeId;
      return businessTypeId === that.corporationBusinessTypeDictEntry.id;
    }

    that.businessTypeChanged = () => {
      if (!that.isCorporationBusinessType()) {
        that.profile.corporateData.conglomerateId = null;
      }
    }
  }
});
