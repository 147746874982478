import {FeeDefinition} from "components/service/fees/fee.types";

export const loanCreationTypes = Object.freeze([
  {
    label: 'New Loan',
    value: 'NEW_LOAN'
  }, {
    label: 'Reloan',
    value: 'RELOAN'
  }, {
    label: 'Restructured',
    value: 'RESTRUCTURED'
  }, {
    label: 'Renewal',
    value: 'RENEWAL'
  }, {
    label: 'Additional Loan',
    value: 'ADDITIONAL_LOAN'
  }, {
    label: 'Extension',
    value: 'EXTENSION'
  }, {
    label: 'Reconstructed',
    value: 'RECONSTRUCTED'
  }, {
    label: 'Consolidation',
    value: 'CONSOLIDATION'
  }
]);

/**
 * Enumeration of which loan creation types are considered and treated as a renewal
 */
export const renewedLoanCreationTypes = Object.freeze([
  'RENEWAL', 'EXTENSION'
]);

export const loanInformationTypes = Object.freeze({
  LOAN_CLASS: 'LOAN_CLASS',
  LOAN_ECONOMIC_ACTIVITY: 'LOAN_ECONOMIC_ACTIVITY',
  LOAN_PURPOSE: 'LOAN_PURPOSE',
  LOAN_SECURITY: 'LOAN_SECURITY',
  LOAN_RATE_TYPE: 'LOAN_RATE_TYPE',
  LOAN_BORROWER_TYPE: 'LOAN_BORROWER_TYPE',
  LOAN_TRANSACTION_TYPE: 'LOAN_TRANSACTION_TYPE',
  LOAN_MICROFINANCE_CLASSIFICATION: 'LOAN_MICROFINANCE_CLASSIFICATION'
});

export const loanCreateCalculationMethods = Object.freeze([
  'PERCENTAGE_BASED_ON_YEAR_TERM',
  'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_INSURANCE_FEE',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE',
]);

export const loanMaturityCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_INSURANCE_FEE',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE',
]);

export const loanPastDueCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_APPLIED_INSTALLMENT_PENALTIES'
]);

export const chargeCollectionTypes = Object.freeze([
  'PROPORTIONAL_TO_DAYS_DELAYED',
  'ALWAYS_IN_FULL'
]);

export const loanCustomPreterminationFeesCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES'
]);

export const equallyCyclicAmortizedFeeCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR',
]);

export const cicContractTypes = Object.freeze([
    {label: 'Agricultural Loan', value: 'AGRICULTURAL_LOAN'},
    {label: 'Apex Loan', value: 'APEX_LOAN'},
    {label: 'Benefit Loan', value: 'BENEFIT_LOAN'},
    {label: 'Business Loan', value: 'BUSINESS_LOAN'},
    {label: 'Equipment Leasing', value: 'EQUIPMENT_LEASING'},
    {label: 'Home Equity Loan', value: 'HOME_EQUITY_LOAN'},
    {label: 'Loan Line', value: 'LOAN_LINE'},
    {label: 'Mortgage Real Estate', value: 'MORTGAGE_REAL_ESTATE'},
    {label: 'Personal Loan', value: 'PERSONAL_LOAN'},
    {label: 'Provident Loan', value: 'PROVIDENT_LOAN'},
    {label: 'Real Estate Leasing', value: 'REAL_ESTATE_LEASING'},
    {label: 'Salary Loan', value: 'SALARY_LOAN'},
    {label: 'Short Term Loan', value: 'SHORT_TERM_LOAN'},
    {label: 'Student Loan', value: 'STUDENT_LOAN'},
    {label: 'Syndicated Loan', value: 'SYNDICATED_LOAN'},
    {label: 'Term Loan', value: 'TERM_LOAN'},
    {label: 'Time Loan', value: 'TIME_LOAN'},
    {label: 'Trust Loan', value: 'TRUST_LOAN'},
    {label: 'Unsecured Loan', value: 'UNSECURED_LOAN'},
    {label: 'Vehicle Leasing', value: 'VEHICLE_LEASING'},
    {label: 'Vehicle Loan', value: 'VEHICLE_LOAN'},
  ]
);

export const automaticTransferStrategies = Object.freeze([
  {label: 'Always pay full amortizations', value: 'PAY_FULLY'},
  {label: 'Pay as much as possible', value: 'PAY_PARTIALLY'},
  {label: 'None', value: 'NONE'},
]);

export const allPercentageChargeTypes = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
]);

export const pastDueInterestChargeTypes = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
]);


export const loanArbitraryFees = Object.freeze([
  'DOC_STAMP',
  'NOTARIAL_FEE',
  'APPLICATION_FEE',
  'CREDIT_INVESTIGATION_FEE',
  'EXTRA_BANK_FEE',
  'SERVICE_CHARGE',
  'INSURANCE_FEE',
  'INSURANCE_SERVICE_FEE',
  'INSURANCE_PROCESSING_FEE',
  'MEMBERSHIP_FEE',
  'ID_FEE',
  'PRETERMINATION_FEE'
]);

export const defaultArbitraryFeeValues : Partial<FeeDefinition> = {
  feeType: 'FIXED',
  calculationMethod: 'FIXED_AMOUNT',
  fixedAmount: 0,
  calculateOnStatuses: ['INACTIVE', 'ACTIVE', 'MATURE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING', 'PAST_DUE_LITIGATION', 'PAST_DUE_WRITE_OFF'],
  applyOnStatuses: ['INACTIVE', 'ACTIVE', 'MATURE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING', 'PAST_DUE_LITIGATION', 'PAST_DUE_WRITE_OFF'],
  calculateOn: 'CREATE',
  applyOn: 'LOAN_RELEASE',
  overdraftStrategy: 'OVERDRAFT',
  operationGroup: 'APPLY_FEE',
  enabled: true,
  includedInEirComputation: false,
  forAccretion: false,
  displayOnProductCreation: true
};

export const feeAmortizationTypes = Object.freeze([
  'EQUALLY',
  'PERCENTAGE',
  'CYCLIC',
  'EQUALLY_CYCLIC'
]);

export const amortizationHooks = Object.freeze([
  'ALL_AMORTIZATIONS',
  'LAST_AMORTIZATION'
]);

export const advanceInterestApplicationTypes = Object.freeze([{
  label: 'Top',
  value: 'TOP',
}, {
  label: 'Bottom',
  value: 'BOTTOM'
}]);

export const contractualSavingFees = Object.freeze([
  'CBU',
  'TP',
  'PF'
]);

export const penaltyFees = Object.freeze([
  'PENALTY_MATURITY',
  'PENALTY'
]);

export const advancePaymentStrategies = Object.freeze([
  {
    label: 'Allocate excess based on payment hierarchy',
    value: 'PAYMENT_HIERARCHY'
  },
  {
    label: 'Transfer excess to CBU',
    value: 'TRANSFER_TO_CBU'
  },
  {
    label: 'Transfer excess to linked deposit account',
    value: 'TRANSFER_TO_LINKED_ACCOUNT'
  },
  {
    label: 'Let user decide',
    value: 'USER_SELECT'
  }
]);

export const loanDocumentTypes = Object.freeze([
  {
    label: 'Notarized',
    value: 'NOTARIZED'
  },
  {
    label: 'Signed',
    value: 'SIGNED'
  },
  {
    label: 'Other',
    value: 'OTHER'
  },
]);

export const loanDocumentStatuses = Object.freeze([
  {
    label: 'For Review',
    value: 'FOR_REVIEW'
  },
  {
    label: 'Incomplete',
    value: 'INCOMPLETE'
  },
  {
    label: 'Verified',
    value: 'VERIFIED'
  },
  {
    label: 'Complete and Received',
    value: 'COMPLETE_AND_RECEIVED'
  },
]);
