import {ClickAwayListener} from '@material-ui/core';
import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import 'inspire-tree-dom/dist/inspire-tree-light.css';
import React, {ReactElement, useRef} from 'react';
import {TreeNodeEditComponentFactory, TreeNodeViewComponentFactory} from 'tree/editableTree/EditableSubtreeView';
import {NxTreeNode} from 'tree/NxTreeNode';
import TreeLabel from 'tree/TreeLabel';
import TreeWrapper, {ChildrenProps, TreeWrapperProps} from 'tree/TreeWrapper';
import {v4 as uuid} from 'uuid';
import EditableSubtreeList from './EditableSubtreeList';
import styles from './EditableTree.scss';

export interface TreeProps<VALUE> extends Omit<TreeWrapperProps<VALUE>, 'onChange' | 'children' | 'nodes' | 'variant' | 'checkedValues'> {
  TreeNodeEdit: TreeNodeEditComponentFactory<VALUE>;
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
  onChange: (nodes: NxTreeNode<VALUE>[]) => unknown;
  onBlur?: () => unknown;
  value: NxTreeNode<VALUE>[];
  label: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
}

const mapNodes = function<VALUE>(nodes: TreeNode[], mapping: Map<string, VALUE>): NxTreeNode<VALUE>[] {
  return nodes
    .map(node => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const id = node.id;
      const savedValue = mapping.get(id);
      if(savedValue) {
        const children = node.getChildren();
        return {
          value: savedValue,
          children: children.length ? mapNodes(children, mapping) : []
        };
      }

      return undefined;
    })
    .filter((node: NxTreeNode<VALUE> | undefined): node is NxTreeNode<VALUE> => !!node);
};

// fully uncontrolled component
const EditableTree = function <VALUE>(props: TreeProps<VALUE>): ReactElement {
  const hasFocus = useRef(false);

  return <div>
    <ClickAwayListener onClickAway={(): void => {
      if(props.onBlur && hasFocus.current) {
        props.onBlur();
      }

      hasFocus.current = false;
    }}>
      <div className={clsx(styles.container,
          props.disabled ? styles.container_disabled : '',
          props.error ? styles.container_error : ''
        )}>
        <TreeLabel label={props.label} required={props.required} error={!!props.error} />
        <TreeWrapper {...props}
                     nodes={props.value}
                     variant="editable"
                     onChange={(nodes: TreeNode[], mapping: Map<string, VALUE>): void => {
                       hasFocus.current = true;
                       props.onChange(mapNodes(nodes, mapping));
                     }}
        >
          {({treeNodes, mapping, tree}: ChildrenProps<VALUE>): ReactElement =>
            <EditableSubtreeList<VALUE> treeNodes={treeNodes}
                                        mapping={mapping}
                                        onRootNodeAdded={(): void => {
                                          const node = tree.addNode({
                                            id: uuid(),
                                            text: ''
                                          });

                                          node.toggleEditing();
                                        }}
                                        disabled={props.disabled ?? false}
                                        TreeNodeEdit={props.TreeNodeEdit}
                                        TreeNodeView={props.TreeNodeView}
            />
          }
        </TreeWrapper>
      </div>
    </ClickAwayListener>
  {
    !!props.error && <div className={styles.error}>{props.error}</div>
  }
  </div>;
};

export default React.memo(EditableTree) as typeof EditableTree;
