import nxModule from 'nxModule';

import {IController} from 'angular';

import templateUrl from './loan-origination-configuration.template.html';
import {ApprovalHierarchy} from '../../common/approval-hierarchy/approval-hierarchy.types';
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

class LoanOriginationConfiguration implements IController {
  approvalHierarchy!: ApprovalHierarchy[];

  constructor(private $route: NxRouteService,
              private http: HttpService,
              private command: CommandService,
              private confirmation: Confirmation) {
  }

  save = async (approvalHierarchy: ApprovalHierarchy[]): Promise<void> => {
    if (await this.confirmation('Are you sure you want to update the configuration? This will affect existing applications.')) {
      await this.command.execute('LOSUpdateConfiguration', { configuration: approvalHierarchy }).toPromise();
      this.$route.reload();
    }
  };
}

nxModule.component('loanOriginationConfiguration', {
  templateUrl,
  controller: LoanOriginationConfiguration,
  bindings: {
    approvalHierarchy: '<'
  }
});