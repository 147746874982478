import nxModule from 'nxModule';
import {ActionStyling, ActionType, Columns} from '../../common/dynamic-list/dynamic-list.component';
import {LosApplicationCache, LosApplicationCacheObject} from './los-application.cache';
import moment from 'moment';
import _ from 'lodash';

import templateUrl from './loan-origination-application.template.html';
import {
  LocalLosApplicationTableEntry,
  LosApplicationTableEntry,
  PersistedLosApplicationListItem
} from './types/los-application.types';
import {ILocationService} from "angular";
import {NxRouteService} from "routes/NxRouteService";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

class LoanOriginationApplication {
  readonly localColumns: Columns = [
    { title: 'No' },
    { title: 'Existing customer', field: 'existingCIFLabel' },
    { title: 'Customer name', field: 'customerName' },
    { title: 'Loan', field: 'loanTypeName' },
    { title: 'Created', field: 'createdOn' },
    {
      actions: [{
        label: 'Edit',
        field: 'edit',
        type: ActionType.BUTTON
      }, {
        label: 'Delete',
        field: 'delete',
        type: ActionType.BUTTON,
        style: ActionStyling.DANGER
      }]
    }
  ];
  localApplications: LocalLosApplicationTableEntry[] = [];
  persistedApplications: LosApplicationTableEntry[] = [];

  constructor(private $location: ILocationService,
              private $route: NxRouteService,
              private losApplicationCache: LosApplicationCache,
              private authentication: Authentication,
              private confirmation: Confirmation,
              private http: HttpService,
              private command: CommandService) {
  }

  async $onInit() {
    this.fetchLocalApplications();
    await this.fetchPersistedApplications();
  }

  private confirmRemoval(): Promise<boolean> {
    return this.confirmation('Are you sure you want to delete this application?');
  };

  private fetchLocalApplications() {
    const userId = this.authentication.context.id;
    this.localApplications = this.losApplicationCache.keys()
    // cache keys are stored as string
      .map((applicationId: string): LocalLosApplicationTableEntry => {
        const application: LosApplicationCacheObject = this.losApplicationCache.get(applicationId)!;
        const id = Number(applicationId);
        return {
          id,
          ...application,
          existingCIFLabel: application.existingCIF ? 'Yes' : 'No',
          createdOn: moment(id).format('ll'),
          edit: () => this.$location.path(`los/application/create/${ applicationId }`),
          delete: async () => {
            const proceed = await this.confirmRemoval();
            if (proceed) {
              _.remove(this.localApplications, application => Number(application.id) === Number(applicationId));
              this.losApplicationCache.remove(applicationId);
            }
          }
        };
      })
      // get only applications of current user
      .filter((application: LocalLosApplicationTableEntry) => userId === application.userId);
  }

  private async fetchPersistedApplications() {
    const applications = await this.http.get<PersistedLosApplicationListItem[]>('/los/applications/of-user').toPromise();

    this.persistedApplications = applications.map(application => {
      return {
        id: application.id,
        existingCIFLabel: application.existingCIF ? 'Yes' : 'No',
        customerName: application.customerName,
        loanTypeName: application.loanTypeName,
        createdOn: moment(application.createdOn).format('ll'),
        updatedOn: application.lastUpdatedOn ? moment(application.lastUpdatedOn).format('LLL') : '-',
        status: application.status,
        latestRemarks: application.latestRemarks,
        edit: () => this.$location.path(`los/application/${ application.id }`),
        delete: async () => {
          const proceed = await this.confirmRemoval();
          if (proceed) {
            const response = await this.command.execute('LOSDeleteApplication', { id: application.id }).toPromise();
            if (response && !response.approvalRequired) {
              _.remove(this.persistedApplications, app => Number(app.id) === Number(application.id));
            }
          }
        }
      }
    })
  }

  createApplication() {
    this.$location.path('los/application/create');
  }
}

nxModule.component('loanOriginationApplication', {
  templateUrl,
  controller: LoanOriginationApplication
});