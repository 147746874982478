import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import React, {ReactElement, ReactNode, useCallback} from 'react';
import TreeExpandingToggle from 'tree/TreeExpandingToggle';
import styles from './TreeNodeItem.scss';

export interface TreeNodeItemTitleProps {
  disabled?: boolean;
  treeNode: TreeNode;
  children: ReactNode;
  className?: string;
  checkbox: boolean;
}

const TreeNodeItem = ({treeNode, children, checkbox, disabled, className}: TreeNodeItemTitleProps): ReactElement => {
  const indeterminate = treeNode.indeterminate();
  const setRef = useCallback((element: HTMLInputElement): void => {
    if(!element) {
      return;
    }

    element.indeterminate = indeterminate;
  }, [indeterminate]);

  return <div className={clsx('title-wrap')}>
    <TreeExpandingToggle node={treeNode} />
    <a className={clsx('title', 'icon',
      !checkbox ? (treeNode.hasChildren() ? 'icon-folder' : 'icon-file-empty') : '',
      (treeNode.selected() ? styles.selected : ''),
      styles.node,
      className
    )}>
      {checkbox && <input type="checkbox"
                          ref={setRef}
                          disabled={disabled}
                          checked={treeNode.checked()}
                          onChange={(): void => {
                            treeNode.toggleCheck();
                          }}/>}
      {children}
    </a>
  </div>;
};


export default TreeNodeItem;