import nxModule from 'nxModule';
import templateUrl from './cash.template.html';
import systemPropertyService from '../../../../react/system/systemPropertyService';

class CashController {
  constructor($element, $attrs) {
    this.$element = $element;
    this.$attrs = $attrs;
    // 9000000000000 - Backend max cash variable. Due to JS floating point precision max cash is divided by 10.
    this.maxCashAmount = 900000000000;
  }

  // Be careful making this method async as a result of awaiting for Promise.
  // It caused problems when new components where dynamically rendered within table rows (new row was added) right after document's initial render.
  // Even though value was provided for component it wasn't refreshed until any element (row) was removed from an array which drove that table.
  // Other simpler cases when an component was shown again right after a condition was finally met also happened to be affected by that.
  $onInit() {
    this.min = this.min || 0;
    // Since 0 is a falsy value we need to check if it's set explicitly to allow 0 as max value
    this.max = this.max === 0 ? 0 : (this.max || this.maxCashAmount);
    this.fraction = this.fraction || 2;
    // Due to JS floating point precision safeDigitsNumber is reduced by 1.
    this.safeDigitsNumber = Number.MAX_SAFE_INTEGER.toString().length - this.fraction - 1;

    const onKeyPress = e => {
      const evt = e.originalEvent;
      const inputElement = evt.target;
      const value = inputElement.value;

      const floatDigitsWithDotLength = this.cutOutDigitsWithDot(value);

      const isValidLength = this.validLengthCheck(value, floatDigitsWithDotLength);
      const isSelected = this.selectionCheck(inputElement);
      const cursorAtFloat = this.allowFloatChange(inputElement, value, floatDigitsWithDotLength);
      const isKeyADot = evt.key === '.';

      if (!isValidLength && !isSelected && !cursorAtFloat && !isKeyADot) {
        e.preventDefault();
        return false;
      }
    };

    if (this.ngModel) {
      this.ngModel.$validators.min = (modelValue) => {
        // Check only existing value.
        if (!modelValue && typeof modelValue !== 'number') return true;
        if (this.ignoreAmountValidation) return true;
        return this.min <= modelValue;
      };

      this.ngModel.$validators.max = (modelValue) => {
        if (!modelValue && typeof modelValue !== 'number') return true;
        if (this.ignoreAmountValidation) return true;
        return this.max >= modelValue;
      };

      this.ngModel.$render = () => {
        this.cashValue = this.ngModel.$viewValue;
      }
    }

    this.$element.on('keypress', onKeyPress);
  }

  $postLink() {
    // Set up currency symbol
    if (!this.currency && !this.hideCurrency) {
      this.currency = systemPropertyService.getProperty('DEFAULT_CURRENCY');
    }
  }

  cutOutDigitsWithDot(value) {
    const indexOfDot = value.indexOf('.');
    let floatDigits = 0;
    let floatDigitsWithDot = 0;

    // is Number is float
    if (indexOfDot >= 0) {
      floatDigits = value.length - (indexOfDot + 1);
      floatDigitsWithDot = floatDigits + 1;
    }

    return floatDigitsWithDot;
  }

  validLengthCheck(value, floatDigitsWithDot) {
    return value.length - floatDigitsWithDot < this.safeDigitsNumber;
  }

  selectionCheck(input) {
    return (input.selectionEnd - input.selectionStart) >= 1;
  }

  allowFloatChange(input, value, floatDigitsWithDot) {
    return (value.length - floatDigitsWithDot) < input.selectionEnd;
  }

  valueChanged() {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.cashValue);
    }
  }

  $onChanges(changes) {
    // invoke validation in case of input changes
    if(this.ngModel) {
      this.ngModel.$validate();
    }

    if (!this.ngModel && changes.value) {
      this.cashValue = changes.value.currentValue;
    }
  }
}

nxModule.component('cash', {
  controller: CashController,
  templateUrl,
  require: {
    ngModel: '?^ngModel'
  },
  bindings: {
    fraction: '@',
    hideCurrency: '<',
    max: '<',
    min: '<',
    ignoreAmountValidation: '<',
    prepend: '<',
    // avoid this binding. It doesnt properly refreshes component when value changes
    value: '<'
  }
});
