// initializes routes

import {adminRoutes} from './admin.routes';
import {agentBankingRoutes} from './agent-banking.routes';
import {batchRoutes} from "./batch.routes";
import {creditLinesRoutes} from "./credit-lines.routes";
import {customerRoutes} from './customer.routes';
import {dashboardRoutes} from "./dashboard.routes";
import {generalLedgerRoutes} from "./general-ledger.routes";
import {inventoryRoutes} from "./inventory.routes";
import {loansRoutes} from './loans.routes';
import {losRoutes} from "./los.routes";
import {checkPreparationRoutes} from "./check-preparation.routes";
import {reportRoutes} from './report.routes';
import {typeResolver} from "./resolvers";
import {termDepositsRoutes} from './term-deposits.routes';
import {centerRoutes} from "./center.routes";
import angular from 'angular-route';

export default ($routeProvider: angular.route.IRouteProvider): void => {
  const routes: [string, angular.route.IRoute & {label?: string}][] = [
    ...adminRoutes,
    ...agentBankingRoutes,
    ...batchRoutes,
    ...creditLinesRoutes,
    ...customerRoutes,
    ...centerRoutes,
    ...dashboardRoutes,
    ...generalLedgerRoutes,
    ...inventoryRoutes,
    ...loansRoutes,
    ...losRoutes,
    ...checkPreparationRoutes,
    ...reportRoutes,
    ...termDepositsRoutes
  ];

  routes.forEach(route => $routeProvider.when(...route));

  $routeProvider.when('/error-page/:type?', {
      template: '<error-page type="{{$resolve.type}}"/>',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: 'Error',
      resolve: { ...typeResolver }
    })
    .otherwise({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorPage: true
    });
};

