import {TreeNode} from 'inspire-tree';
import React, {ReactElement} from 'react';
import MultiselectSubtreeView from 'tree/multiselectTree/MultiselectSubtreeView';
import {TreeNodeViewComponentFactory} from 'tree/multiselectTree/MultiselectTree';
import TreeNodeListWrapper from 'tree/TreeNodeListWrapper';

const MultiselectSubtreeList = function <NT>(props: {
  treeNodes: TreeNode[],
  mapping: Map<string, NT>,
  reverseMapping: Map<NT, string[]>,
  disabled: boolean,
  TreeNodeView: TreeNodeViewComponentFactory<NT>,
}): ReactElement {
  const nodes = props.treeNodes.map(treeNode => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <MultiselectSubtreeView<NT> key={treeNode.id}
                                       treeNode={treeNode}
                                       disabled={props.disabled}
                                       mapping={props.mapping}
                                       reverseMapping={props.reverseMapping}
                                       TreeNodeView={props.TreeNodeView}
    />;
  });


  return <TreeNodeListWrapper>
    {nodes}
  </TreeNodeListWrapper>;
};

export default MultiselectSubtreeList;
