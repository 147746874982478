import {IFormController, ILocationService} from 'angular';
import {CustomerLoanCreateService} from 'components/customer/loans/new-loan/page/customer-loan-create.service';
import {OfficialReceipt} from 'components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types';
import {CreateLoanInput, LoanCreationType} from 'components/service/create-loan-input.types';
import {CustomerCache} from 'components/service/customer.cache.types';
import {Loan} from 'components/service/loan.types';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import Authentication from 'shared/utils/authentication';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {RecursivePartial} from 'shared/utils/RecursivePartial';
import systemPropertyService from 'system/systemPropertyService';

import './customer-loan-restructure-page.style.less';
import templateUrl from './customer-loan-restructure-page.template.html';

class CustomerLoanRestructurePage {
  public loanForm!: IFormController;
  private loan!: Loan;
  private remakeType: LoanCreationType = "RESTRUCTURED";
  private loanTypeId!: number;
  private loanInput: RecursivePartial<CreateLoanInput> = {};
  private officialReceipt!: OfficialReceipt | void;
  private autoOfficialReceiptEnabled = false;
  private displayEmptyReceiptMessage = false;

  constructor(private $location: ILocationService,
              private command: CommandService,
              private customerCache: CustomerCache,
              private confirmation: Confirmation,
              private authentication: Authentication,
              private http: HttpService,
              private customerLoanCreateService: CustomerLoanCreateService) {
  }

  async $onInit(): Promise<void> {
    this.loanInput = this.customerLoanCreateService.createLoanInputFromExistingLoan(this.loan, this.remakeType);
    this.loanInput.remadeFromLoanIds = [this.loan.id];
    this.loanInput.loanTypeId = this.loanTypeId;
    this.loanInput.customFieldValues = await customFieldService.readValuesMap({productId: this.loan.id});

    this.autoOfficialReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    if (this.autoOfficialReceiptEnabled) {
      this.officialReceipt = await this.http.get<OfficialReceipt>(`/official-receipt/next-available?userId=${this.authentication.context.id}`)
        .toPromise()
        .catch(response => {
          if (response?.errorCode === 'NO_AVAILABLE_OFFICIAL_RECEIPT') {
            this.displayEmptyReceiptMessage = true;
          } else {
            throw response;
          }
        });
    }
  }

  isReconstruction(): boolean {
    return this.remakeType === 'RECONSTRUCTED'
  }

  redirectBack(): void {
    if (this.isReconstruction()) {
      this.$location.path(`/customer/${this.loan.customerId}/loans/reconstruct/${this.loan.id}`);
    }
    this.$location.path(`/customer/${this.loan.customerId}/loans/restructure/${this.loan.id}`);
  }

  async postLoan(clonedLoan: RecursivePartial<CreateLoanInput>, commandName: string): Promise<void> {
    const customerId = this.loan.customerId;

    const response = await this.command.execute<unknown, {id: number}>(commandName, clonedLoan).toPromise();

    if (response && !response.approvalRequired) {
      const loanId = response.output.id;
      this.customerCache.loans(customerId).refetch();
      this.customerCache.customerProductFees(customerId).refetch();
      this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(customerId).refetch() : Promise.resolve();
      this.$location.path(`/customer/${customerId}/loans/${loanId}`);
    }
  }

  async restructure(): Promise<void> {
    const proceed = await this.confirmation('Do you want to restructure the loan?');
    if (!proceed) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const input = this.customerLoanCreateService.createLoanCommandInput(this.loanInput);
    this.postLoan(input, 'CreateRestructuredLoan');
  }

  async reconstruct(): Promise<void> {
    const proceed = await this.confirmation('Do you want to reconstruct the loan?<br><br>This action is irreversible. Proceed?', true);
    if (!proceed) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const input = this.customerLoanCreateService.createLoanCommandInput(this.loanInput);
    this.postLoan(input, 'CreateReconstructedLoan');
  }
}

nxModule.component('customerLoanRestructurePage', {
  templateUrl,
  bindings: {
    loanTypeId: '<',
    remakeType: '<',
    loan: '<'
  },
  controller: CustomerLoanRestructurePage
});
