import nxModule from 'nxModule';
import {
  allPercentageChargeTypes,
  defaultArbitraryFeeValues,
  loanArbitraryFees
} from 'constants/loan';

const templateUrl = require('./loan-product-charges.template.html');

const chargeTypePropertyConfig = {
  CBU: {
    chargeType: 'cbuCharge',
    extraOptions: 'cbuExtraOptions'
  },
  PF: {
    chargeType: 'pfCharge',
    extraOptions: 'pfExtraOptions'
  },
  TP: {
    chargeType: 'tpCharge',
    extraOptions: 'tpExtraOptions'
  },
  INSURANCE_FEE: {
    chargeType: 'insuranceFee'
  },
  INSURANCE_SERVICE_FEE: {
    chargeType: 'insuranceServiceFee'
  },
  INSURANCE_PROCESSING_FEE: {
    chargeType: 'insuranceProcessingFee'
  },
  NOTARIAL_FEE: {
    chargeType: 'notarialFee'
  },
  APPLICATION_FEE: {
    chargeType: 'applicationFee'
  },
  CREDIT_INVESTIGATION_FEE: {
    chargeType: 'creditInvestigationFee'
  },
  SERVICE_CHARGE: {
    chargeType: 'serviceCharge'
  },
  ID_FEE: {
    chargeType: 'idFee'
  },
  EXTRA_BANK_FEE: {
    chargeType: 'extraBankFee'
  },
  MEMBERSHIP_FEE: {
    chargeType: 'membershipFee'
  },
  PENALTY: {
    chargeType: 'penalty'
  },
  PENALTY_MATURITY: {
    chargeType: 'penaltyMaturity'
  }
};

class LoanProductChargesForm {
  constructor($scope, feeDefinitionsCache) {
    this.$scope = $scope;
    this.feeDefinitionsCache = feeDefinitionsCache;

    // not all charge types are available for contractual savings, as they are calculated before some figures are
    // available (like total interest rate)
    this.contractualSavingsChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
    ];

    // charge types that available on loan opening fees
    this.openingChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
      'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
      'ROUNDED_PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR'
    ];

    this.insuranceChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
      'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_INSURANCE_FEE',
    ];

    // all charge types are available only for charges that are calculated after the loan is created (e.g. penalty)
    this.allChargeTypes = allPercentageChargeTypes;

    this.insuranceFeeDeductionStrategies = [
      'DEDUCT_FROM_RELEASE_AMOUNT',
      'TRANSFER_TO_ACCOUNT',
    ];

  }

  extractFeeValuesFromPropertyBaseFee(loanProduct, feeClass) {
    let charge = loanProduct[chargeTypePropertyConfig[feeClass].chargeType];
    const extraOptionsProperty = chargeTypePropertyConfig[feeClass]?.extraOptions;
    let extraOptions = extraOptionsProperty ? loanProduct[extraOptionsProperty] : {};

    if(feeClass === 'PENALTY' && !extraOptions['PENALTY_DAYS_CALCULATION_TYPE']) {
      extraOptions['PENALTY_DAYS_CALCULATION_TYPE'] = 'DAYS_LATE';
    }

    if (charge.type === 'FIXED_AMOUNT') {
      return {
        ...defaultArbitraryFeeValues,
        fixedAmount: charge.rate,
        feeType: 'FIXED',
        calculationMethod: 'FIXED_AMOUNT',
        extraOptions: extraOptions
      };
    } else {
      return {
        ...defaultArbitraryFeeValues,
        percentageAmount: charge.rate,
        feeType: 'PERCENTAGE',
        calculationMethod: charge.type,
        extraOptions: extraOptions
      };
    }
  }

  $onInit() {
    this.$scope.$watch('$ctrl.loanProduct', async () => {
      const feeDefs = await this.feeDefinitionsCache.toPromise();

      this.propertyBasedFees = [{
        ...defaultArbitraryFeeValues,
        feeName: "Documentary stamp",
        feeClass: 'DOC_STAMP',
        fixedAmount: 500,
        validCalculationMethods : this.openingChargeTypes,
        calculationMethod: 'FIXED_AMOUNT',
        includedInEirComputation: true
      }, {
        feeName: 'CBU',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'CBU'),
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'CBU'
      }, {
        feeName: 'PF',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PF'),
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'PF',
      }, {
        feeName: 'TP',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'TP'),
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'TP'
      }, {
        feeName: "Insurance fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: 'INSURANCE_FEE',
        insuranceFeeOptions: {
          insuranceFeeDeductionStrategies: this.insuranceFeeDeductionStrategies,
          insuranceFeeDeductionStrategy: this.loanProduct.insuranceFeeDeductionStrategy,
          insuranceSavingsAccountId: this.loanProduct.insuranceSavingsAccountId,
        },
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_FEE')
      }, {
        feeName: "Insurance service fee",
        validCalculationMethods: this.insuranceChargeTypes,
        feeClass: "INSURANCE_SERVICE_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_SERVICE_FEE')
      }, {
        feeName: "Insurance processing fee",
        validCalculationMethods: this.insuranceChargeTypes,
        feeClass: "INSURANCE_PROCESSING_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_PROCESSING_FEE')
      }, {
        feeName: "Notarial Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "NOTARIAL_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'NOTARIAL_FEE'),
        includedInEirComputation: true
      }, {
        feeName: "Application Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "APPLICATION_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'APPLICATION_FEE'),
        includedInEirComputation: true
      }, {
        feeName: "Credit investigation Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "CREDIT_INVESTIGATION_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'CREDIT_INVESTIGATION_FEE')
      }, {
        feeName: "Service Charge",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "SERVICE_CHARGE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'SERVICE_CHARGE'),
        includedInEirComputation: true
      }, {
        feeName: "ID Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "ID_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'ID_FEE')
      }, {
        feeName: "Extra Bank Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "EXTRA_BANK_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'EXTRA_BANK_FEE')
      }, {
        feeName: "Membership Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "MEMBERSHIP_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'MEMBERSHIP_FEE')
      }, {
        feeName: "Penalty",
        validCalculationMethods: this.allChargeTypes,
        feeClass: "PENALTY",
        applyOnMaturity: this.loanProduct.penaltyApplyOnMaturity ?? true,
        collectionType: this.loanProduct.penaltyCollectionType,
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PENALTY')
      }, {
        feeName: "Penalty Maturity",
        validCalculationMethods: this.allChargeTypes,
        feeClass: "PENALTY_MATURITY",
        collectionType: this.loanProduct.penaltyMaturityCollectionType,
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PENALTY_MATURITY'),
        applyOn: this.loanProduct.penaltyMaturityApplyOn ?? 'ALL_AMORTIZATIONS'
      }];

      if(this.loanProduct.id) {
        this.propertyBasedFees.forEach(fd => {
          const savedFeeDef = feeDefs.find(f => f.productDefinitionId === this.loanProduct.productDefinition.id && f.feeClass === fd.feeClass);
          if(savedFeeDef) Object.assign(fd, savedFeeDef);
          if(fd.feeClass === 'PENALTY_MATURITY') fd.applyOn = this.loanProduct.penaltyMaturityApplyOn;
        });
      }

      const loanTypeFeeDefs = feeDefs
        .filter(fd => fd.enabled)
        .filter(fd => fd.productDefinitionId === this.loanProduct.productDefinition.id);
      const customFees = loanTypeFeeDefs.filter(fd => fd.feeClass === 'CUSTOM');

      this.mapLoanPropertyBasedFeesForAmortizations(this.propertyBasedFees, loanTypeFeeDefs);
      // if edit mode -> map settings from loan properties
      if (!!this.loanProduct.id) {
        this.mapVisibilitySettings(this.propertyBasedFees, loanTypeFeeDefs);
      }

      this.feeDefinitions = [
        ...this.propertyBasedFees,
        ...customFees
      ];
      this.loanProduct.feeDefinitions = this.feeDefinitions.filter(fd => loanArbitraryFees.includes(fd.feeClass) || ['CUSTOM', 'PENALTY'].includes(fd.feeClass));

      // if create mode -> make sure all default fee settings are mapped to loan
      if (!this.loanProduct.id) {
        this.mapFeesToLoanProperties();
      }
    });
  }

  mapLoanPropertyBasedFeesForAmortizations(loanPropertyBasedFees, feeDefinitions) {
    const feeDefsForAmortizations = feeDefinitions
      .filter(fd => fd.applyOn === 'LOAN_AMORTIZATION')
      .filter(fd => loanArbitraryFees.includes(fd.feeClass));


    if (feeDefsForAmortizations.length < 1) {
      return;
    }

    loanPropertyBasedFees.forEach(feeDef => {
      const feeDefForAmortization = feeDefsForAmortizations.find(fda => fda.feeClass === feeDef.feeClass);
      if (!feeDefForAmortization) {
        return;
      }

      feeDef.applyOn = feeDefForAmortization.applyOn;
      feeDef.feeAmortizationType = feeDefForAmortization.feeAmortizationType;
      feeDef.amortizedAmountCalculationParameter =  feeDefForAmortization.amortizedAmountCalculationParameter;
      feeDef.roundingScale = feeDefForAmortization.roundingScale;
    });
  }

  mapVisibilitySettings(loanPropertyBasedFees, feeDefinitions) {
    const arbitraryFeeDefinitions = feeDefinitions.filter(fd => loanArbitraryFees.includes(fd.feeClass));
    loanPropertyBasedFees.forEach(loanFee => {
      const feeDef = arbitraryFeeDefinitions.find(fda => fda.feeClass === loanFee.feeClass);
      loanFee.displayOnProductCreation = this.getVisibilitySetting(feeDef, loanFee.feeClass);
    });
  }

  getVisibilitySetting(feeDef, feeClass) {
    if (feeClass === 'PENALTY') {
      return this.loanProduct.penaltyDisplayOnProductCreation;
    } else if (feeClass === 'PENALTY_MATURITY') {
      return this.loanProduct.penaltyDisplayOnProductCreation;
    } else {
      return feeDef ? feeDef.displayOnProductCreation : true;
    }
  }

  onSave() {
    this.mapFeesToLoanProperties();
  }

  mapFeesToLoanProperties() {
    this.loanProduct.feeDefinitions = this.feeDefinitions.filter(fd => ['CUSTOM', 'PENALTY'].includes(fd.feeClass) || loanArbitraryFees.includes(fd.feeClass));
    //for property based fees updates values
    this.feeDefinitions.filter(fd => !['CUSTOM', 'DOC_STAMP'].includes(fd.feeClass)).forEach((fd) => {
      let loanProductCharge = this.loanProduct[chargeTypePropertyConfig[fd.feeClass].chargeType];

      if (fd.calculationMethod === 'FIXED_AMOUNT') {
        loanProductCharge.rate = fd.fixedAmount;
        loanProductCharge.type = 'FIXED_AMOUNT';
      } else {
        loanProductCharge.rate = fd.percentageAmount;
        loanProductCharge.type = fd.calculationMethod;
      }

      if (fd.amortizationType) {
        loanProductCharge.amortizationType = fd.amortizationType;
        loanProductCharge.amortizedAmountCalculationParameter = fd.amortizedAmountCalculationParameter;
      }

      this.loanProduct[chargeTypePropertyConfig[fd.feeClass].extraOptions] = fd.extraOptions;

      if (fd.feeClass === 'INSURANCE_FEE') {
        this.loanProduct.insuranceFeeDeductionStrategy = fd.insuranceFeeOptions.insuranceFeeDeductionStrategy;
        this.loanProduct.insuranceSavingsAccountId = fd.insuranceFeeOptions.insuranceSavingsAccount?.id;
      }

      if (fd.feeClass === 'PENALTY') {
        this.loanProduct.penaltyApplyOnMaturity = fd.applyOnMaturity;
        this.loanProduct.penaltyCollectionType = fd.collectionType;
        this.loanProduct.penaltyDisplayOnProductCreation = fd.displayOnProductCreation;
      }

      if (fd.feeClass === 'PENALTY_MATURITY') {
        this.loanProduct.penaltyMaturityApplyOn = fd.applyOn;
        this.loanProduct.penaltyMaturityCollectionType = fd.collectionType;
        this.loanProduct.penaltyMaturityDisplayOnProductCreation = fd.displayOnProductCreation;
      }

      if(loanArbitraryFees.includes(fd.feeClass) && fd.applyOn === 'LOAN_RELEASE') {
        fd.feeType = 'ARBITRARY';
      }
    });
  }
}

nxModule.component('loanProductChargesForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: LoanProductChargesForm
});
