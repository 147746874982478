import nxModule from 'nxModule';

import templateUrl from './customer-credit-line.template.html';
import BigNumber from "bignumber.js";
import './customer-credit-line.style.less'

class CustomerCreditLine {

  constructor($route, $location, $scope, customerCache, notification, systemDateService, singleUserCache,
              command, branchService, confirmation, authentication, $filter, customerService, popup,
              $routeParams, queryParamsRemover, creditLineLoansCache, creditLineCustomersCache, loanProductsCache) {
    this.$route = $route;
    this.$location = $location;
    this.$scope = $scope;
    this.customerCache = customerCache;
    this.notification = notification;
    this.systemDateService = systemDateService;
    this.singleUserCache = singleUserCache;
    this.command = command;
    this.branchService = branchService;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.$filter = $filter;
    this.customerService = customerService;
    this.popup = popup;
    this.$routeParams = $routeParams;
    this.queryParamsRemover = queryParamsRemover;
    this.creditLineLoansCache = creditLineLoansCache;
    this.creditLineCustomersCache = creditLineCustomersCache;
    this.loanProductsCache = loanProductsCache;

    this.creditLines = [];
    this.visibleCreditLines = [];
    this.selectedCreditLine = null;
    this.showClosed = this.$routeParams.showClosed === "true";

    this.queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);
  }

  async $onInit() {
    this.creditLineId = this.$route.current.params['creditLineId'];
    this.customerId = this.$route.current.params['customerId'];

    this.profile = await this.customerCache.profile(this.customerId, true, this.authentication.permissions['CST_CLOSED_CIF_READ']).toPromise();
    this.creditLineEnabled = this.profile.creditLineAllowed;
    this.customerService.redirectWhenProfileIsInvalid(this.profile);
    if (this.profile.status !== 'CLOSED') {
      await this.fetchCreditLines();
    }
  }

  async fetchCreditLines() {
    this.creditLineSub = this.customerCache.creditLines(this.customerId, false).toObservable()
      .subscribe(creditLines => {
        this.creditLines = creditLines;
        this.setupCreditLineVisibility();
      });
  }

  setupCreditLineVisibility() {
    if (!this.showClosed) {
      this.visibleCreditLines = this.creditLines.filter(cl => cl.status !== 'CLOSED');
    } else {
      this.visibleCreditLines = this.creditLines;
    }

    this.setupCreditLineOtherDetails();
  }

  toggleShowClosed() {
    // Select first entry if selectedCreditLine is null
    if (this.showClosed && !this.selectedCreditLine) {
        const closedCreditLines = this.creditLines.filter(cl => cl.status === 'CLOSED');
        this.selectedCreditLine = closedCreditLines[0];
    }
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.selectedCreditLine.id}`)
        .search('showClosed', this.showClosed.toString());
  }

  async setupCreditLineOtherDetails() {
    if (!this.visibleCreditLines || this.visibleCreditLines.length < 1) return;

    const creditLineIds = this.visibleCreditLines.map(cl => cl.id);
    const [loans, branches, loanTypes] = await Promise.all([
      this.fetchLoans(creditLineIds),
      this.branchService.toPromise(),
      this.loanProductsCache.toPromise()
    ]);

    this.assignOtherLoanData(loans, creditLineIds, loanTypes);

    const ONE_HUNDRED = new BigNumber(100);
    this.visibleCreditLines.forEach(cl => {
      const availedRate = new BigNumber(cl.availedCreditLine)
        .div(new BigNumber(cl.approvedCreditLine))
        .multipliedBy(ONE_HUNDRED);
      const unusedRate = ONE_HUNDRED.minus(availedRate);

      cl.availedRate = availedRate.toNumber();
      cl.unusedRate = unusedRate.toNumber();

      const branch = branches.find(b => b.id === cl.branchId);
      cl.branchName = branch.name;

      // assign loans
      cl.loans = loans
        .filter(loan => loan.creditLineId === cl.id)
        .filter(loan => loan.status !== 'CLOSED');
    });

    this.setupSelectedCreditLine();
  }

  async assignOtherLoanData(loans, creditLineIds, loanTypes) {
    let customers = [this.profile];
    if (this.profile.customerType === 'CORPORATE') {
      customers = await this.creditLineCustomersCache.withParam(creditLineIds).toPromise();
    }

    loans.forEach(loan => {
      loan.customer = customers.find(customer => customer.id === loan.customerId);
      loan.loanType = loanTypes.find(loanType => loanType.id === loan.typeId);
    });
  }

  fetchLoans(creditLineIds) {
    if (this.profile.customerType === 'CORPORATE') {
      return this.creditLineLoansCache.withParam(creditLineIds).toPromise();
    } else {
      return this.customerCache.loans(this.customerId, false).toPromise();
    }
  }


  async setupSelectedCreditLine() {
    if (!this.creditLineId && this.visibleCreditLines.length > 0) {
      this.$location.path(`/customer/${this.customerId}/credit-lines/${this.visibleCreditLines[0].id}`);
    }

    if (this.creditLineId) {
      const creditLine = this.visibleCreditLines.find(cl => cl.id === Number(this.creditLineId));
      if (creditLine) {
        this.selectedCreditLine = creditLine;
        this.selectedCreditLine.user = await this.singleUserCache.user(this.selectedCreditLine.createdBy).toPromise();
      }
    }
  }

  selectCreditLine(creditLine) {
    this.selectedCreditLine = creditLine;
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.selectedCreditLine.id}`);
  }

  async deleteCreditLine() {
    const request = {
      id: this.selectedCreditLine.id
    };
    const proceed = await this.confirmation(`Are you sure you want to delete this credit line?`);
    if (proceed) {
      await this.command.execute('DeleteCreditLine', request, {nxLoaderText: 'Deleting credit line...'}).toPromise();
      this.customerCache.creditLines(this.customerId).refetch();
      this.customerCache.loans(this.customerId).refetch();
      this.$route.reload();
    }
  }

  async closeCreditLine() {
    const request = {
      id: this.selectedCreditLine.id
    };

    const proceed = await this.confirmation(`Are you sure you want to close this credit line?`);
    if (proceed) {
      await this.command.execute('CloseCreditLine', request, {nxLoaderText: 'Closing credit line...'}).toPromise();
      this.customerCache.creditLines(this.customerId).refetch();
      this.$route.reload();
    }
  }

  editCreditLine() {
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.selectedCreditLine.id}/edit`);
  }

  async toggleCreditLineAllowed() {
    const openCreditLines = this.creditLines.filter(c => c.status!== 'CLOSED');

    if (!this.creditLineEnabled && openCreditLines.length > 0) {
      this.popup({
        text: `Customer already has credit lines. Could not disable credit line for customer ${this.profile.effectiveName}`,
        callback: () => this.$route.reload()
      });
    } else {
      const request = {
        customerId: this.profile.id,
        creditLineAllowed: this.creditLineEnabled
      };
      const enabled = this.creditLineEnabled ? "Enable" : "Disable";
      const doProceed = await this.confirmation(`${enabled} customer ${this.profile.effectiveName} to have credit line?`);
      if (doProceed) {
        await this.command.execute('SwitchCustomerCreditLine', request, {nxLoaderText: 'Updating Customer...'}).toPromise();
        this.customerCache.refetch();
        this.$route.reload();
      }
    }
  }

  manageBorrowers() {
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.creditLineId}/manage-borrowers`);
  }

  createNew() {
    this.$location.path(`/customer/${this.customerId}/credit-lines/create`)
  }

  $onDestroy() {
    if (this.creditLineSub) {
      this.creditLineSub.unsubscribe();
    }
  }
}

nxModule.component('customerCreditLine', {
  templateUrl,
  controller: CustomerCreditLine
});
