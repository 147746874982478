import {IFile} from 'components/customer/profile/customer-profile.types';
import _ from 'lodash';
import nxModule from 'nxModule';
import {RecursivePartial} from 'shared/utils/RecursivePartial';
import {CreateLoanInput, LoanCreationType} from 'components/service/create-loan-input.types';
import {Loan} from 'components/service/loan.types';

export class CustomerLoanCreateService {

  createLoanInputFromExistingLoan(existingLoan: Loan,
                                        creationType: LoanCreationType): RecursivePartial<CreateLoanInput> {
    return {
      customerId: existingLoan.customerId,
      loanTypeId: existingLoan.typeId,
      loanInformation: existingLoan.loanInformation,
      automaticTransferAgreement: existingLoan.automaticTransferAgreement || {transferStrategy: 'NONE'},
      creationType: creationType,
      existingAmortizationSchedule: existingLoan.amortizationSchedule,
      waiveRequest: {
        total: {
          totalWaive: 0,
          totalOutstandingBalance: 0,
          totalFinalBalanceToPay: 0
        }
      },
      paymentIntervalDefinitionId: existingLoan.paymentIntervalDefinitionId,
      paymentIntervalDefinition: existingLoan.paymentIntervalDefinition,
      totalAmortizationNumber: existingLoan.totalAmortizationNumber,
      interestRate: existingLoan.interestRate,
      overrideAmortizationAmount: existingLoan.overrideAmortizationAmount,
      officerId: existingLoan.officerId,
      uidApplication: existingLoan.uidApplication,
      uidAmortizationNumber: existingLoan.uidAmortizationNumber,
      coMakers: existingLoan.coMakers,
      interestCalculationParameter: existingLoan.interestCalculationParameter,
      categoryIds: existingLoan.categoryIds,
      creditLineId: existingLoan.creditLineId,
      sourceLoan: existingLoan
    };
  }

  createLoanCommandInput(loanInput: RecursivePartial<CreateLoanInput> & {collateralFiles?: IFile[]}): RecursivePartial<CreateLoanInput> {
    const clonedLoan = _.cloneDeep(loanInput);
    clonedLoan.collateralFileIds = _.map((clonedLoan.collateralFiles ?? []), file => file.id);
    clonedLoan.collateralFiles = undefined;
    clonedLoan.feeOverride = clonedLoan.feeOverride || {};

    return clonedLoan;
  }

}

nxModule.service('customerLoanCreateService', CustomerLoanCreateService);
