import nxModule from 'nxModule';
import {CustomerProfile} from '../../customer/profile/customer-profile.types';
import {CreateLoanInput} from '../../service/create-loan-input.types';
import {ICacheFactoryService} from "angular-cache";

export interface LosApplicationCacheObject {
  createdOn: string;
  existingCIF: boolean;
  customerName: string;
  loanTypeName?: string;
  profile: CustomerProfile;
  loan: CreateLoanInput;
  userId: number;
}

export interface LosApplicationCache {
  keys(): string[];
  get(id: string): LosApplicationCacheObject | undefined;
  remove(id: string): void;

  put(applicationId: string | number, application: LosApplicationCacheObject): void;
}

nxModule.factory('losApplicationCache', (CacheFactory: ICacheFactoryService) =>
  CacheFactory.createCache('losApplications', {
    storageMode: 'localStorage',
    storagePrefix: 'nx__nonvolatile__'
  })
);
