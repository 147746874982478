import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';
import {advanceInterestApplicationTypes, loanCreationTypes, renewedLoanCreationTypes} from 'constants/loan';
import BigNumber from "bignumber.js";
import './customer-loan-create-parameters.style.less';
import systemPropertyService from '../../../../../../../../react/system/systemPropertyService';

const templateUrl = require('./customer-loan-create-parameters.template.html');
nxModule.component('customerLoanCreateParameters', {
  templateUrl: templateUrl,
  bindings: {
    formParam: '=',
    loan: '=',
    loanProduct: '<',
    groupLoan: '<',
    hideSimulation: '<',
    createMode: '<',
    customerId: '<',
    editMode: '<',
    overrideDates: '<',
    onWarningUpdated: '<'
  },
  controller: function ($scope, $route, $timeout, $filter, http, dict, userCache, customerCache, popup,
                        borrowerCreditLinesCache, rolesCache, feeDefinitionsCache, authentication, userService,
                        customerLoanRenewalService, loanService, depositAccountTypeService, creditScoringLoanApplicationDetailsService) {
    const that = this;
    that.dict = dict;
    that.minDate = null;
    that.maxDate = null;
    that.minDateGranted = null;
    that.availableMisGroups = [];
    that.depEdPolicyNumberLength = 14;
    that.minRestructuredAmortizations = 6;
    const customerId = that.customerId;
    that.shouldOverrideDates = () => _.defaultTo(that.overrideDates, false);
    that.authentication = authentication;

    // Because loan.interestRate is being altered when is out of scope defined by 'min' and 'max' attributes it's stored before it happens.
    const originalInterestRate = that.loan.interestRate;

    const getAvailableLinkedAccounts = async () => {
      const [accounts, accountTypes] = await Promise.all([
        customerCache.depositAccounts(customerId).toPromise(),
        depositAccountTypeService.toPromise()
      ]);

      return loanService.getAvailableLinkedAccounts(accounts, accountTypes);
    };

    const readIncomeSources = async () => {
      const customer = await customerCache.profile(customerId, true, true).toPromise();
      const depEdIncomeSources = customer.incomeSources.filter(source => source.type === 'DEPED');
      that.depEdIncomeSources = depEdIncomeSources.map(source => {
        const parts = [
          `Region: ${source.depEdRegionCode}`,
          `Division: ${source.depEdDivisionCode}`,
          `Station: ${source.depEdStationCode}`,
          `Employee number: ${source.depEdEmployeeNumber}`
        ];

        return {
          label: parts.join(" "),
          value: source.id
        };
      });
    };

    // don't fetch income sources for default application in group/batch loans
    if (customerId && customerId !== -1) {
      readIncomeSources();
    }

    that.setDefaultCreationType = () => {
      if (that.loanProduct.defaultCreationType && !that.loan.creationType) {
        that.loan.creationType = that.loanProduct.defaultCreationType;
      }
    };

    that.$onInit = async () => {
      that.fetchLoanOfficers();
      that.fetchLoanCollectors();

      that.availCreditLine = false;
      if (that.loan.creationType === 'RESTRUCTURED') {
        that.creationTypes = [...loanCreationTypes].filter(type => type.value === 'RESTRUCTURED');
      } else if (that.loan.creationType === 'RECONSTRUCTED') {
        that.creationTypes = [...loanCreationTypes].filter(type => type.value === 'RECONSTRUCTED');
      } else if (that.loan.renewedLoanId) {
        that.creationTypes = [...loanCreationTypes].filter(type => renewedLoanCreationTypes.includes(type.value));
      } else if(that.loan.creationType === 'CONSOLIDATION') {
        that.creationTypes = [...loanCreationTypes].filter(type => type.value === 'CONSOLIDATION');
      } else {
        that.creationTypes = [...loanCreationTypes].filter(type => !['RESTRUCTURED', 'EXTENSION', 'RECONSTRUCTED', 'CONSOLIDATION'].includes(type.value));
      }

      if (that.loan.creationType === 'RESTRUCTURED') {
        $scope.$watch('$ctrl.loan.waiveRequest.total.totalFinalBalanceToPay', that.displayWarning);
        $scope.$watch('$ctrl.loan.feeOverride', that.displayWarning, true);
        $scope.$watch('$ctrl.loan.customFeesOverride', that.displayWarning, true);
        that.loan.totalAmortizationNumber = Math.max(
          that.minRestructuredAmortizations,
          that.loan.totalAmortizationNumber
        );
      }

      this.showReleaseDate = systemPropertyService.getProperty('LOAN_FUTURE_DATE_GRANTED_ALLOWED') === 'TRUE';

      if (that.loanProduct.withLinkedDepositAccount && !that.loan.linkedDepositAccountId) {
        const [loans, validAccountOptions] = await Promise.all([
          customerCache.loans(customerId).toPromise(),
          getAvailableLinkedAccounts()
        ]);

        const validAccountIds = new Set(validAccountOptions.map(opt => opt.id));

        const loansByNewest = _.reverse(_.sortBy(loans, 'id'));
        that.loan.linkedDepositAccountId = loansByNewest
          .map(ln => ln.linkedDepositAccountId)
          .find(id => validAccountIds.has(id));
      }

      that.creditScoringAvailable = false;
      if (systemPropertyService.getProperty('CREDIT_SCORING_ENABLED') === 'TRUE') {
        that.creditScoringAvailable = await http.get('/customers/creditscoring/health').toPromise();
      }
    };

    that.displayWarning = () => {
      that.warningMessage = `Values impacting Loan Principal changed. Use 'Suggest principal' button if necessary.`;
      that.onWarningUpdated(true);
    };

    that.closeWarning = () => {
      that.warningMessage = null;
      that.onWarningUpdated(false);
    };

    $scope.$watch('$ctrl.loan.grantDate ', () => {
      const postponement = that.loanProduct.maxFirstPaymentPostponement;
      that.minDate = that.loan.grantDate;

      if (that.minDate && postponement) {
        that.maxDate = moment(that.minDate).add(postponement, 'days').format('YYYY-MM-DD');
      }
      that.calculateFirstPaymentDate();
    });

    $scope.$watch('$ctrl.loan.paymentIntervalDefinitionId', () => {
      that.onPaymentIntervalChange(that.loan.paymentIntervalDefinitionId);
    });

    that.fetchCycles = async () => {
      if (!that.loanProduct.cycleCounting) return;

      const cycleMap = await http.get(`/products/loans/cycles?loanTypeId=${that.loan.loanTypeId}&customerIds=${that.loan.customerId}`).toPromise()
      that.cycle = cycleMap[that.loan.customerId];
      if (!that.cycle) that.cycle = 0;
    };

    const setFieldWithUserIdByPermission = async (field, permission) => {
      const roles = await rolesCache.toPromise();
      const roleIdsWithPermission = roles.filter(role => _.some(role.permissions, {name: permission})).map(role => role.id);

      if (!that.loan.id) {
        const userRoleIds = authentication.context.roleIds;
        const userHasRole = _.intersection(userRoleIds, roleIdsWithPermission).length > 0;
        that.loan[field] = userHasRole ? authentication.context.realUserId : null;
      }
    };

    that.fetchLoanOfficers = async () => {
      await setFieldWithUserIdByPermission('officerId', 'LOAN_OFFICER');
      this.officers = await userService.getLoanOfficersAssignedToCurrentUserBranches(that.loan.officerId);
    };

    that.fetchLoanCollectors = async () => {
      await setFieldWithUserIdByPermission('collectorId', 'COLLECTOR');
      this.collectors = await userService.getCollectorsAssignedToCurrentUserBranches(that.loan.collectorId);
    };

    that.isCreditLineEnabled = () => {
      return !!(that.loan && that.loan.creditLineId);
    };

    that.isLoanRenewal = () => {
      return that.loan && that.loan.renewedLoanId && renewedLoanCreationTypes.includes(that.loan.creationType);
    };

    that.isLoanRestructure = () => {
      return that.loan && that.loan.creationType === 'RESTRUCTURED';
    };

    that.isLoanReconstruction = () => {
      return that.loan && that.loan.creationType === 'RECONSTRUCTED';
    };

    that.isLoanConsolidation = () => {
      return that.loan?.creationType === 'CONSOLIDATION';
    }

    const fetchAvailableCreditLines = async () => {
      const [creditLines, corporateCreditLines] = await Promise.all([
        customerCache.creditLines(customerId).toPromise(),
        borrowerCreditLinesCache.withParam(Number(customerId)).toPromise()
      ]);

      const allCreditLines = creditLines.concat(corporateCreditLines);
      that.availableCreditLines = allCreditLines
        .filter(cl => cl.loanTypeIds.includes(that.loanProduct.id))
        .filter(cl => cl.status === 'ACTIVE')
        .map(cl => {
          const clonedCl = {...cl};
          if (cl.id === that.loan.creditLineId) {
            const unusedCL = new BigNumber(cl.unusedCreditLine);
            const principal = new BigNumber(that.loan.principalAmount);
            clonedCl.unusedCreditLine = unusedCL.add(principal).toNumber();
          }
          return clonedCl;
        })
        .filter(cl => {
          const unusedCL = new BigNumber(cl.unusedCreditLine);
          const minAmount = new BigNumber(that.loanProduct.minAmount);
          return unusedCL.isGreaterThanOrEqualTo(minAmount);
        });
      that.availableCreditLines.forEach(cl => {
        cl.selectDisplay = cl.description.concat(" | ").concat($filter('nxCurrency')(cl.unusedCreditLine));
      });
      that.availCreditLine = that.isCreditLineEnabled();
    };

    that.$onChanges = async (changes) => {
      if (changes.hasOwnProperty('loanProduct') && that.loanProduct) {
        const postponement = that.loanProduct.maxFirstPaymentPostponement;
        that.setDefaultCreationType();

        that.minAmount = that.loanProduct.minAmount;

        await that.setLoanRenewalValues();
        const allPaymentIntervals = await http.get(`/products/loans/intervals`).toPromise();

        if (that.loanProduct.paymentIntervalOptions) {
          // show only payment intervals supported by loan product
          that.paymentIntervals = _.filter(allPaymentIntervals, (i) => that.loanProduct.paymentIntervalOptions.indexOf(i.id) !== -1);

          if (!that.loan.id) {
            that.loan.paymentIntervalDefinition = that.loan.paymentIntervalDefinition || that.paymentIntervals[0];
            that.loan.paymentIntervalDefinitionId = that.loan.paymentIntervalDefinitionId || that.paymentIntervals[0].id;
          }
          that.minDate = that.loan.grantDate;

          if (that.minDate && postponement) {
            that.maxDate = moment(that.minDate).add(postponement, 'days').format('YYYY-MM-DD');
          }
          that.calculateFirstPaymentDate();
        }

        if (that.loan.paymentIntervalDefinition.paymentIntervalType === 'SINGLE_PAYMENT') {
          that.loan.totalAmortizationNumber = 1;
        } else {
          that.loan.totalAmortizationNumber = that.loan.totalAmortizationNumber || that.loanProduct.defaultAmortizationNumber;
        }

        const interestRate = that.loanProduct.useInterestRateBoard ? originalInterestRate : originalInterestRate || that.loanProduct.defaultInterestRate;
        if (that.loanProduct.minInterestRate <= interestRate && interestRate <= that.loanProduct.maxInterestRate) {
          that.loan.interestRate = interestRate;
        } else {
          that.loan.interestRate = null;
        }

        if (['BALLOON_CYCLIC', 'BALLOON_CYCLIC_EXACT_DAYS', 'BALLOON_SPREAD'].includes(that.loanProduct.amortizationType)) {
          that.loan.interestCalculationParameter = that.loan.interestCalculationParameter || that.loanProduct.defaultInterestCalculationParameter;
        }

        that.loan.pastDueInterestCharge = that.loan.pastDueInterestCharge || _.clone(that.loanProduct.pastDueInterestCharge);
        that.loan.pastDueMaturityInterestCharge = that.loan.pastDueMaturityInterestCharge || _.clone(that.loanProduct.pastDueMaturityInterestCharge);

        that.loan.penalty = that.loan.penalty || _.clone(that.loanProduct.penalty);
        that.loan.penaltyMaturity = that.loan.penaltyMaturity || _.clone(that.loanProduct.penaltyMaturity);

        if (that.loanProduct.collectAdvanceInterest) {
          that.loan.advanceInterestNo = that.loan.advanceInterestNo ?? that.loanProduct?.advanceInterestDefaultNo;
        }

        that.loan.advanceInterestApplication = that.loan.advanceInterestApplication ?? that.loanProduct?.advanceInterestDefaultApplication;
        that.showRateTypeId = await that.shouldShowRateTypeId();
        that.setDefaultCreationType();

        // this component can be used in non-existing customer context - then cl & cycles are not available
        if (customerId) {
          that.linkedDepositAccounts = await getAvailableLinkedAccounts();
          await that.fetchCycles();
          if (authentication.permissions['CST_CREDIT_LINE_READ']) {
            await fetchAvailableCreditLines();
          }
        }
        that.updateLoanMaxAmount();
        that.updatePastDueInterestRates();
      }
    };

    that.shouldShowRateTypeId = async () => {
      const feeDefinitions = await feeDefinitionsCache.toPromise();
      return that.loanProduct.cbuExtraOptions.LOAN_BOARD ||
        that.loanProduct.pfExtraOptions.LOAN_BOARD ||
        that.loanProduct.tpExtraOptions.LOAN_BOARD ||
        feeDefinitions
          .filter(
            fd => fd.productDefinitionId === that.loanProduct?.productDefinition?.id
              && fd.enabled
              && fd.extraOptions
              && fd.extraOptions.LOAN_BOARD)
          .some(fd => ['DOC_STAMP', 'CUSTOM', 'CBU', 'PF', 'TP'].includes(fd.feeClass));
    };

    that.setLoanRenewalValues = async () => {
      if (!that.loanProduct || !that.loan.renewedLoanId || !that.loanProduct.allowRenewal) {
        return;
      }

      that.renewedLoanDetails = await customerLoanRenewalService.getRenewedLoanDetails(that.loan.renewedLoanId);

      /**
       * If the paid principal of the loan failed to meet the required principal paid based on
       * LoanType#renewalMinPaidPrincipalPercentage, the value of this property should be set to TRUE in order to
       * trigger AccessRule#predicates and send renewal to approval (if configured as such)
       */
      that.loan.forcedRenewal = new BigNumber(that.renewedLoanDetails.paidPrincipal)
        .isLessThan(new BigNumber(that.renewedLoanDetails.requiredPaidPrincipal));

      that.forcedRenewalMessage = 'Paid principal is less than the required paid principal. Loan will be force renewed.';

      that.minAmount = Math.max(that.loanProduct.minAmount, that.renewedLoanDetails.remainingBalanceWithFees);
    };

    that.updateLoanMaxAmount = () => {
      const loanTypeMaxAmount = that.loanProduct.maxAmount;
      let creditLineMaxAmount = that.loanProduct.maxAmount;
      let cycleMaxAmount = that.loanProduct.maxAmount;

      if (that.availCreditLine) {
        if (that.loan && that.loan.creditLineId) {
          const creditLine = that.availableCreditLines.find(cl => cl.id === that.loan.creditLineId);
          creditLineMaxAmount = creditLine.unusedCreditLine;
        }
      }

      if (that.cycle !== null && that.cycle !== undefined) {
        const maxLength = that.loanProduct.cycles.length;
        cycleMaxAmount = that.loanProduct.cycles[Math.min(that.cycle, maxLength - 1)];
      }

      that.maxAmount = Math.min(loanTypeMaxAmount, creditLineMaxAmount, cycleMaxAmount);
    };

    that.clearCreditLineIdIfDisabledAndUpdateMaxAmount = () => {
      if (!that.availCreditLine) {
        that.loan.creditLineId = null;
      }
      that.updateLoanMaxAmount();
    };

    that.suggestPrincipal = () => {
      const clonedLoan = angular.copy(that.loan);
      clonedLoan.collateralFiles = null;
      clonedLoan.customerId = customerId;
      clonedLoan.automaticTransfer = false;
      http.post('/products/loans/simulate/suggest-principal', clonedLoan).success(loan => {
        that.loan.principalAmount = loan.principalAmount;
        that.closeWarning();
      });
    };

    that.previewAmortization = async () => {
      const clonedLoan = angular.copy(that.loan);
      clonedLoan.collateralFiles = undefined;
      clonedLoan.customerId = customerId;
      clonedLoan.creationType = clonedLoan.creationType || 'NEW_LOAN';
      clonedLoan.automaticTransfer = false;

      try {
        const response = await that.simulateLoan(clonedLoan);
        that.loan.loanPreview = response;
        that.loan.term = response.term;
        that.loan.interestRate = response.interestType === 'ADD_ON_RATE' ? response.monthlyInterestRate : response.interestRate;
        that.warningMessage = undefined;
        that.updatePastDueInterestRates(response.grossEirAnnual);
      } catch (error) {
        popup({header: 'Error', text: error.errorMessage, renderHtml: true});
        that.loan.loanPreview = undefined;
        that.loan.term = undefined;
      }
    };

    that.simulateLoan = async (loan) => {
      let simulatedLoan;

      if (loan.renewedLoanId) {
        simulatedLoan = http.post('/products/loans/simulate/renewed', loan)
      } else {
        simulatedLoan = http.post('/products/loans/simulate', loan)
      }
      return simulatedLoan.toPromise()
    };

    const calculateNextPaymentDay = (paymentDays) => {
      let minDate = moment(that.loan.grantDate).add(1, 'day');
      let nextPaymentDay = _.find(paymentDays, day => day >= minDate.date());
      if (!nextPaymentDay) {
        nextPaymentDay = paymentDays[0];
        minDate = minDate.add(1, 'month');
      }
      return minDate.set('date', Math.min(nextPaymentDay, minDate.daysInMonth()));
    };

    that.onPaymentIntervalChange = (paymentIntervalDefinitionId) => {
      if (!that.loan.paymentIntervalDefinition || that.loan.paymentIntervalDefinition.id !== paymentIntervalDefinitionId)
        that.loan.paymentIntervalDefinition = that.paymentIntervals?.find(i => i.id === paymentIntervalDefinitionId);

      if (that.loan.paymentIntervalDefinition?.paymentIntervalType === 'SINGLE_PAYMENT')
        that.loan.totalAmortizationNumber = 1;

      that.calculateFirstPaymentDate();
    };

    that.calculateFirstPaymentDate = () => {
      if (that.shouldOverrideDates() && that.loan.firstAmortizationDueDate) {
        return;
      }

      const postponement = that.loanProduct.maxFirstPaymentPostponement;
      if (!that.loan.paymentIntervalDefinition || !that.minDate) {
        return null;
      }

      let firstPaymentDate;
      const minDate = that.loan.grantDate;
      const interval = that.loan.paymentIntervalDefinition;
      const singlePaymentTerm = that.loan.singlePaymentTerm;

      switch (interval.paymentIntervalType) {
        case 'INTERVAL':
          firstPaymentDate = moment(minDate).add(Number(interval.intervalCount), interval.basePaymentInterval);
          break;
        case 'SET_DAYS_OF_THE_MONTH':
          firstPaymentDate = calculateNextPaymentDay(interval.paymentDays);
          break;
        case 'SEMIMONTHLY_GRANT_DATE':
          firstPaymentDate = moment(minDate).add(15, 'days');
          break;
        case 'SINGLE_PAYMENT':
          firstPaymentDate = moment(minDate).add(singlePaymentTerm, 'days');
          break;
        default:
          firstPaymentDate = moment(minDate).add(1, 'days');
      }

      if (that.loanProduct.validateMinFirstPaymentDate) {
        that.minDate = firstPaymentDate.format('YYYY-MM-DD');
      } else {
        // allow for first payment date at least 1 day after grant date
        that.minDate = moment(minDate).add(1, 'days').format('YYYY-MM-DD');
      }

      that.maxDate = interval.paymentIntervalType !== 'SINGLE_PAYMENT' ? firstPaymentDate.clone().add(postponement, 'days').format('YYYY-MM-DD') : null;
      if (that.loanProduct.calculateDefaultFirstPaymentDate || interval.paymentIntervalType === 'SINGLE_PAYMENT') {
        that.loan.firstAmortizationDueDate = firstPaymentDate.format('YYYY-MM-DD');
      }
    };

    that.suggestPrincipalDisabled = () => {
      return that.anyRequiredParametersMissing()
        || (that.loan.creationType === 'CONSOLIDATION' && (that.loan?.remadeFromLoanIds?.length ?? 0) < 1);
    };

    that.previewAmortizationDisabled = () => {
      return !that.loan.principalAmount || that.anyRequiredParametersMissing() || that.formParam.firstAmortizationDueDate.$invalid;
    };

    that.anyRequiredParametersMissing = () => {
      return !that.loan.totalAmortizationNumber
        || ((that.loan.interestRate === null || that.loan.interestRate === undefined) && !that.loanProduct.useInterestRateBoard)
        || !that.loan.firstAmortizationDueDate || !that.loan.creationType || !that.isDiminishingAmortizationNumberValid()
        || (that.showRateTypeId && !that.loan.loanInformation.loanRateTypeId);
    }

    that.isDiminishingAmortizationNumberValid = () => {
      return !['BALLOON_DIMINISHING_EQUAL_AMORTIZATION', 'BALLOON_DIMINISHING_EQUAL_AMORTIZATION_EXACT_DAYS'].includes(that.loanProduct.amortizationType)
        || (that.loan.diminishingAmortizationNumber > 0
          && that.loan.diminishingAmortizationNumber < that.loan.totalAmortizationNumber)
    }

    that.uidApplications = [{
      label: 'From top',
      value: 'TOP'
    }, {
      label: 'From bottom',
      value: 'BOTTOM'
    }];

    that.isUIDAmortization = () => {
      return that.loanProduct.withUIDLedger === true;
    };

    that.isEditModeEnabled = () => {
      return _.defaultTo(that.editMode, true);
    }

    that.canInputInterestRate = () => {
      if(!that.isEditModeEnabled()) {
        return false;
      }
      //always allow editing of interest rate if it's an edit 
      return that.loan.id || !that.loanProduct.useInterestRateBoard || that.loanProduct?.interestRateBoard?.allowOverride;
    }

    that.advanceInterestApplicationTypes = advanceInterestApplicationTypes;

    that.getAdvanceInterestMaxNo = () => {
      return Math.min(that.loanProduct.advanceInterestMaxNo, that.loan.totalAmortizationNumber);
    };

    that.updatePastDueInterestRates = (addOnDefaultRate) => {
      const interestType =  that.loan.interestType || that.loanProduct.interestType
      const rate = interestType === 'ADD_ON_RATE' ? addOnDefaultRate : that.loan.interestRate

      if (that.loanProduct.defaultPastDueToInterestRate) {
        that.loan.pastDueInterestCharge.rate = rate;
      }

      if (that.loanProduct.defaultPastDueMaturityToInterestRate) {
        that.loan.pastDueMaturityInterestCharge.rate = rate;
      }
    }

    that.onCreditScoringModalReady = ({api}) => {
      that.creditScoringModalApi = api;
    }

    that.showCreditScoring = async () => {
      const [loan, customer] = await Promise.all([
        that.simulateLoan(that.loan),
        customerCache.profile(customerId, false, false).toPromise()
      ]);

      const details = creditScoringLoanApplicationDetailsService.createDetails({
        dict: that.dict,
        loan: loan,
        loanProduct: that.loanProduct,
        officers: that.officers,
        paymentIntervals: that.paymentIntervals,
        customer: customer
      });

      await that.creditScoringModalApi.show(details);
    };

    that.interestCalculationParamsChanged = () => {
      if(that.loanProduct.useInterestRateBoard && !that.loanProduct.interestRateBoard.allowOverride) {
        that.warningMessage = `Values impacting interest board calculation have changed. Please run Loan Simulation again`;
        that.loan.interestRate = undefined;
      }
    };
  }
});
