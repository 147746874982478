import {ClickAwayListener} from '@material-ui/core';
import {NxGenericTextField} from '@nextbank/ui-components';
import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import 'inspire-tree-dom/dist/inspire-tree-light.css';
import React, {Factory, ReactElement, useCallback, useState} from 'react';
import MultiselectSubtreeList from 'tree/multiselectTree/MultiselectSubtreeList';
import {NxTreeNode} from 'tree/NxTreeNode';
import TreeWrapper, {ChildrenProps, TreeWrapperProps} from 'tree/TreeWrapper';
import ChevronIcon from './icon-chevron.svg';
import styles from './MultiselectTree.scss';

export interface TreeNodeViewProps<Value> {
  value: Value;
}

export type TreeNodeViewComponentFactory<Value> = Factory<TreeNodeViewProps<Value>>;

export interface TreeProps<VALUE> extends Omit<TreeWrapperProps<VALUE>, 'onChange' | 'children' | 'value' | 'variant' | 'nodes'> {
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
  onChange: (values: VALUE[]) => unknown;
  value: VALUE[];
  label: string;
  error?: string;
  inputLabel: string;
  name: string;
  onBlur?: () => unknown;
  required?: boolean;
  disabled?: boolean;
  nodes: NxTreeNode<VALUE>;
}

const gatherCheckedValues = function<VALUE>(nodes: TreeNode[], mapping: Map<string, VALUE>): VALUE[] {
  return nodes
    .flatMap(node => {
      const values = [];
      if(node.checked()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        values.push(mapping.get(node.id)!);
      }

      values.push(...gatherCheckedValues(node.getChildren(), mapping));

      return values;
    });
};

// fully uncontrolled component
const MultiselectTree = function <VALUE>(props: TreeProps<VALUE>): ReactElement {
  const {disabled = false, TreeNodeView} = props;
  const [open, setOpen] = useState(false);
  const ListComponent = useCallback(({treeNodes, mapping, reverseMapping}: ChildrenProps<VALUE>): ReactElement =>
    <MultiselectSubtreeList<VALUE> treeNodes={treeNodes}
                                   mapping={mapping}
                                   reverseMapping={reverseMapping}
                                   disabled={disabled}
                                   TreeNodeView={TreeNodeView}
    />, [disabled, TreeNodeView]);

  const endAdornment = <div className={clsx(styles.icon, open ? styles.open : '')}>
    <ChevronIcon/>
  </div>;

  return <ClickAwayListener onClickAway={(): void => {
    setOpen(false);
  }}>
    <div className={
      clsx(styles.wrapper,
        props.disabled ? styles.disabled : '',
        props.error ? styles.error : ''
      )}>
      <div onClick={(e: React.MouseEvent): void => {
        const target = e.target;
        if(!(target instanceof Element)) {
          return;
        }

        if(target.closest('.MuiFormControl-root')) {
          setOpen(state => !state);
        }
      }}
         className={clsx(styles.inputWrapper, styles.fullWidth)}
         tabIndex={-1}
      >
        <NxGenericTextField disabled={disabled}
                            label={props.label}
                            positionAbsoluteError={false}
                            name={props.name}
                            onBlur={(): void => {
                               if(!props.onBlur) {
                                 return;
                               }

                               props.onBlur();
                             }}
                            value={props.inputLabel}
                            error={props.error}
                            endAdornment={endAdornment}
                            required={props.required} />
      </div>
      {open && <div tabIndex={-1}>
        <TreeWrapper {...props}
          className={styles.treeWrapper}
          nodes={[props.nodes]}
          checkedValues={props.value}
          variant="multiselect"
          onChange={(nodes: TreeNode[], mapping: Map<string, VALUE>): void => {
            const values = gatherCheckedValues(nodes, mapping);
            const uniqueValues = new Set(values);
            props.onChange([...uniqueValues.values()]);
          }}>
        {ListComponent}
        </TreeWrapper>
      </div>
      }
    </div>
  </ClickAwayListener>;
};

export default React.memo(MultiselectTree) as typeof MultiselectTree;
