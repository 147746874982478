import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';

import {addAccountLabels} from 'components/general-ledger/common/gl.utils';
import systemPropertyService from "../../../../../react/system/systemPropertyService";
import {postingDaysThreshold} from "management/WorkingDayType";

const templateUrl = require('./branch-details.template.html');

nxModule.component('branchDetails', {
  templateUrl,
  controller: function ($route, $location, http, $timeout, dict, authentication, organizationCache, glMappingsService, popup,
                        branchService, confirmation, notification, depositoryAccountCache, geoLocationCache,
                        command) {

    const that = this;

    const defaultWorkingDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

    that.workingDays = [{
      label: 'Monday',
      value: 'MONDAY'
    }, {
      label: 'Tuesday',
      value: 'TUESDAY'
    }, {
      label: 'Wednesday',
      value: 'WEDNESDAY'
    }, {
      label: 'Thursday',
      value: 'THURSDAY'
    }, {
      label: 'Friday',
      value: 'FRIDAY'
    }, {
      label: 'Saturday',
      value: 'SATURDAY'
    }, {
      label: 'Sunday',
      value: 'SUNDAY'
    }];

    that.ledgerAccountSelectConfig = {
      placeholder: 'Select ledger account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.checkClearingModes = [
      {
        label: 'On Start Of Day',
        value: 'ON_START_OF_DAY'
      },
      {
        label: 'At Given Time',
        value: 'AT_GIVEN_TIME'
      }
    ];

    that.dict = dict;
    that.branchId = $route.current.params.branchId;
    that.rootOrganization = null;
    that.organizations = [];
    that.selectableOrganizations = [];
    that.branches = [];
    that.otherBranches = [];
    that.branch = {};
    that.depositoryAccounts = [];
    that.allDepositoryAccounts = [];
    that.automatedOfficialReceiptsEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    that.isAMLAInstitutionCodeRequired = systemPropertyService.getProperty('REQUIRE_AMLA_INSTITUTION_CODE') === 'TRUE';
    that.validateIpAddress = input => {
      const regex = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.|$)){4}$', 'i');
      return input.match(regex);
    };

    that.ipAddressesSelectizeConfig = {
      placeholder: 'Add IP addresses',
      create: true,
      createFilter: that.validateIpAddress
    };

    // On init set form as submitted to highlight invalid fields
    $timeout(() => {
      that.branchForm.$setSubmitted();
    });

    that.getDefaultOrganization = () => {
      return that.organizations && that.organizations.length === 1 ? _.head(that.organizations) : null;
    };

    that.createTime = (hour) => {
      if (hour >= 0 && hour <= 24) {
        let cutoff = new Date();
        cutoff.setHours(hour);
        cutoff.setMinutes(0);
        cutoff.setSeconds(0);
        cutoff.setMilliseconds(0);
        return cutoff;
      } else {
        return null;
      }
    };

    that.minPostingDate = () => {
      if (that.branch?.systemDate) {
        return moment(that.branch.systemDate).subtract(postingDaysThreshold, 'days').format('YYYY-MM-DD');
      }
    }

    that.maxPostingDate = () => {
      if (that.branch?.systemDate) {
        return moment(that.branch.systemDate).add(postingDaysThreshold, 'days').format('YYYY-MM-DD');
      }
    }

    that.isWithinWorkingDay = (date) => {
      if (!date || !that.branch?.workingDays) {
        return false;
      }

      const dayOfTheWeek = moment(date).format('dddd').toUpperCase();
      return that.branch.workingDays.includes(dayOfTheWeek);
    }

    that.updateDepositoryAccounts = () => {
      that.depositoryAccounts = that.allDepositoryAccounts.filter( account => { return account.accountType === 'POS' && account.branchId === that.branch.posLicenseBranchId; } );
    };

    that.onPosSupportChange = (posSupport) => {
      if (!posSupport) {
        that.branch.posLicenseBranchId = null;
      } else {
        that.branch.posLicenseBranchId = that.branch.id || authentication.context.branchId;
        that.updateDepositoryAccounts();
      }
      that.branch.posDepositoryAccountId = null;
    };

    const geoLocationsDepth = 1;

    const branchSub = branchService.toObservable()
      .combineLatest(depositoryAccountCache.toObservable(), (branches, accounts) => {
        that.allDepositoryAccounts = accounts;
        return branches;
      })
      .combineLatest(geoLocationCache.withParam(geoLocationsDepth).toObservable(), (branches, g) => {
        this.regions = _.filter(g, reg => reg.level === 1 || reg.name === 'FOREIGN OFFICES');
        return branches;
      })
      .subscribe(branches => {
        that.branches = branches;
        // For existing branch -> fetch it's details
        if (that.branchId) {
          // Find data of branch identified by [branchId]
          that.branch = _.find(branches, (branch) => branch.id == that.branchId);
          // Parse check cutoff time
          const parseTime = (time) => time ? moment(time, 'HH:mm:ss').toDate() : null;
          that.branch.checkCutoffTime = parseTime(that.branch.checkCutoffTime);
          that.branch.workingHoursFrom = parseTime(that.branch.workingHoursFrom);
          that.branch.workingHoursTo = parseTime(that.branch.workingHoursTo);
          that.branch.holidayWorkingHoursFrom = parseTime(that.branch.holidayWorkingHoursFrom);
          that.branch.holidayWorkingHoursTo = parseTime(that.branch.holidayWorkingHoursTo);
          that.branch.automaticEndOfDayTime = parseTime(that.branch.automaticEndOfDayTime);
          that.branch.checkClearingTime = parseTime(that.branch.checkClearingTime);
          that.branch.posLicenseBranchId = that.allDepositoryAccounts.find(d => d.id === that.branch.posDepositoryAccountId)?.branchId;

          // selectize initial options
          if (that.branch.ipAddresses) {
            that.ipOptions = that.branch.ipAddresses.map(ip => ({text: ip, value: ip}));
          }
        }
        that.updateDepositoryAccounts();
        // List of possible parent branches are branches with no parents
        that.parentOptions = _.filter(that.branches, (b) => b.parentBranchId == null && b.id != that.branchId);
        that.parentOptions.unshift({id: null, name: "None"});
      });

    const organizationSub = organizationCache.toObservable().subscribe(organizations => {
      that.organizations = organizations;
      // Select root organization
      that.rootOrganization = _.find(organizations, {root: true});
      // Filter selectable organizations (only non-root) can be picked by user
      that.selectableOrganizations = _.filter(organizations, {root: false});
      // There is only 1 organization -> set it as selectable
      if (organizations.length === 1) {
        that.selectableOrganizations = [];
        that.selectableOrganizations.push(organizations[0]);
      }
      // If branch organization is not set and there is only one organization -> set it
      if (that.branch && !that.branch.organizationId) {
        let defaultOrganization = that.getDefaultOrganization();
        Object.assign(that.branch, {organizationId: defaultOrganization ? defaultOrganization.id : null});
      }
    });

    const ledgerAccountsSub = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.ledgerAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup);
        })
      );
    });

    (function initializeNewBranch() {
      if (!that.branchId) {
        const defaultOrganization = that.getDefaultOrganization();
        const defaultCountry = _.find(that.dict['COUNTRY'], {code: 'PH'});
        that.branch = {
          organizationId: defaultOrganization ? defaultOrganization.id : null,
          workingHoursFrom: that.createTime(9),
          workingHoursTo: that.createTime(17),
          holidayWorkingHoursFrom: that.createTime(9),
          holidayWorkingHoursTo: that.createTime(17),
          workingDays: defaultWorkingDays,
          startedOn: new Date(),
          ipAddressRestricted: true,
          ipAddresses: [],
          systemDate: null,
          postingDate: null,
          checkCutoffTime: that.createTime(12),
          checkCounterThreshold: 3,
          posSupport: false,
          posLicensedBranchId: null,
          posDepositoryAccountId: null,
          parentBranchId: null,
          checkClearingMode: that.checkClearingModes[0].value,
          checkClearingTime: null,
          address: {
            primary: true,
            orderNo: 0,
            countryId: defaultCountry ? defaultCountry.id : null
          }
        };
      }
    })();

    const findUniqueCode = (base) => {
      let unique = false;
      let counter = 1;
      let codeCandidate = base.substring(0, 5).toUpperCase();
      // Map list of branches to branch codes (include only branches wih NN code)
      const branchCodes = _.map(_.filter(that.branches, b => b.code !== null), (b) => b.code.toUpperCase());
      // While code candidate is not unique -> increment
      while (!unique) {
        codeCandidate = codeCandidate + (counter > 1 ? counter : '');
        unique = !_.includes(branchCodes, codeCandidate);
        counter++;
      }
      return codeCandidate;
    };

    /**
     * DEPRECATED
     *
     * Due to Villarica request code is generated on backend (numeric only).
     * This method should can be used in future.
     *
     * @param name branch name
     * @return branch code candidate
     */
    const generateCode = (name) => {
      // If branch name is null or empty -> return [null]
      if (!name) return null;
      // Remove all non-ascii characters from name
      let code = name.toUpperCase().replace(/[^0-9A-Z]/g, '');
      // If name after removal of non-ascii characters is empty -> return [null]
      if (!code) return null;
      // Check if code is unique (compare with other branches
      return findUniqueCode(code);
    };

    that.onNameChange = () => {
      that.branch.code = generateCode(that.branch.name);
    };

    that.onCheckClearingModeChange = () => {
      if (that.branch.checkClearingMode === 'AT_GIVEN_TIME' && !that.branch.checkClearingTime) {
        that.branch.checkClearingTime = that.createTime(that.branch.workingHoursFrom.getHours());
      }
    };

    that.formatCommand = () => {
      const formatTime = (time) => time ? moment(time).format('HH:mm:ss') : null;
      that.branch.address.primary = true;
      that.branch.address.type = 'PERMANENT';
      return {
        id: that.branch.id,
        organizationId: that.branch.organizationId,
        name: that.branch.name,
        code: that.branch.code,
        amlaInstitutionCode: that.branch.amlaInstitutionCode !== '' ? that.branch.amlaInstitutionCode: null,
        initialCash: that.branch.initialCash,
        address: that.branch.address,
        workingHoursFrom: formatTime(that.branch.workingHoursFrom),
        workingHoursTo: formatTime(that.branch.workingHoursTo),
        holidayWorkingHoursFrom: formatTime(that.branch.holidayWorkingHoursFrom),
        holidayWorkingHoursTo: formatTime(that.branch.holidayWorkingHoursTo),
        workingDays: that.branch.workingDays,
        tinNumber: that.branch.tinNumber,
        startedOn: that.branch.startedOn,
        phoneNumber: that.branch.phoneNumber,
        faxNumber: that.branch.faxNumber,
        ipAddressRestricted: that.branch.ipAddressRestricted,
        ipAddresses: that.branch.ipAddresses,
        systemDate: that.branch.systemDate,
        postingDate: that.branch.postingDate,
        brstn: that.branch.brstn,
        sssCode: that.branch.sssCode || null,
        depEdCode: that.branch.depEdCode || null,
        checkCutoffTime: formatTime(that.branch.checkCutoffTime),
        checkCounterThreshold: that.branch.checkCounterThreshold,
        automaticEndOfDayTime: formatTime(that.branch.automaticEndOfDayTime),
        posSupport: that.branch.posSupport,
        posLicenseBranchId: that.branch.posLicenseBranchId,
        posDepositoryAccountId: that.branch.posDepositoryAccountId,
        dueFromAccountCode: that.branch.dueFromAccountCode,
        dueToAccountCode: that.branch.dueToAccountCode,
        forexTransacting: that.branch.forexTransacting || false,
        geoLocationRegionId: that.branch.geoLocationRegionId,
        bspBranchName: that.branch.bspBranchName,
        bspBranchAddress: that.branch.bspBranchAddress,
        bspBranchCode: that.branch.bspBranchCode,
        parentBranchId: that.branch.parentBranchId,
        civLimit: that.branch.civLimit,
        checkClearingMode: that.branch.checkClearingMode,
        checkClearingTime: formatTime(that.branch.checkClearingTime) || null,
        automaticCloseCounter: that.branch.automaticCloseCounter,
        automaticStartDay: that.branch.automaticStartDay,
        officialReceiptNumberPrefix: that.branch.officialReceiptNumberPrefix,
        officialReceiptNumberSuffix: that.branch.officialReceiptNumberSuffix
      };
    };

    that.save = () => {
      if (that.branch.id) {
        that.update();
      } else {
        that.create();
      }
    };

    that.create = async () => {
      const response = await command.execute('CreateBranch', that.formatCommand(), {nxLoaderText: 'Creating new branch'}).toPromise();
      if (response && !response.approvalRequired) {
        const updateBranchListAndRedirectToBranches = () => {
          branchService.refetch();
          $location.path("/admin/organization/branches");
        };
        authentication.refresh(updateBranchListAndRedirectToBranches, updateBranchListAndRedirectToBranches);
      }
    };

    that.update = async () => {
      const response = await command.execute('UpdateBranch', that.formatCommand(), {nxLoaderText: 'Updating branch'}).toPromise();
      if (response && !response.approvalRequired) {
        branchService.refetch();
        $location.path("/admin/organization/branches");
      }
    };

    that.cancel = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path("/admin/organization/branches"));
    };

    that.$onDestroy = () => {
      branchSub.unsubscribe();
      organizationSub.unsubscribe();
      ledgerAccountsSub.unsubscribe();
    };
  }
});
