import './sub-components/group-data.component'
import './sub-components/add-group-members.component'
import './sub-components/group-schedule.component'
import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import {Subscription} from 'rxjs/Subscription';
import {CustomerType} from 'components/customer/profile/customer-profile.types';

import angular from "angular";
import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';

import templateUrl from './group-profile.template.html';

nxModule.component('groupProfile', {
  templateUrl: templateUrl,
  controller: function ($route, $location, confirmation, command, groupCustomerCache, branchService, singleUserCache, customerService, propertyConfigService) {
    const that = this;
    that.editMode = false;
    that.propertyConfigService = propertyConfigService;

    that.profile = null;
    that.officers = [];

    that.customerId = $route.current.params['customerId'];

    const subscription = new Subscription();


    const formatTime = (time) => time ? moment(time).format('HH:mm:ss') : null;
    const parseTime = (time) => time ? moment(time, 'HH:mm:ss').toDate() : null;
    const formatDate = (date) => date ? moment(date).format('YYYY-MM-DD') : null;

    that.$onInit = async () => {
      if (that.customerId) {
        that.editMode = true;
        const [branches, profileResp, customFieldValues] = await Promise.all([
          branchService.toPromise(),
          groupCustomerCache.profile(that.customerId).toPromise(),
          customFieldService.readValuesMap({customerId: that.customerId})])

        let groupCustomer = angular.copy(profileResp);
        for (const customer of groupCustomer.customerGroup.customers) {
          let branch = branches.find((el) => el.id == customer.branchId);
          customer.branch = branch;
          customer.birthDate = customer.individualData?.birthDate;
          customer.customerId = customer.id;
          customer.name = customer.effectiveName;
        }

        groupCustomer.customerGroup.collectionTimeFrom = parseTime(groupCustomer.customerGroup.collectionTimeFrom);
        groupCustomer.customerGroup.collectionTimeTo = parseTime(groupCustomer.customerGroup.collectionTimeTo);

        that.profile = groupCustomer.customerGroup;
        that.profile.addresses = groupCustomer.addresses;
        that.profile.customerId = that.customerId;
        that.profile.customerNumber = groupCustomer.customerNumber;
        that.profile.branchId = groupCustomer.branchId;
        that.profile.collectionPaymentInterval = groupCustomer.customerGroup.collectionPaymentInterval;
        that.profile.categoryIds = groupCustomer.categoryIds;
        that.profile.customFieldValues = customFieldValues;

        if (typeof(groupCustomer.customerGroup.collectionDay) == 'string') {
          const weekdays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
          that.profile.collectionDay = weekdays.findIndex(el => el.toUpperCase() === groupCustomer.customerGroup.collectionDay.toUpperCase());
        }

        groupCustomer = customerService.extractPhones(groupCustomer);
        that.profile.phones = [
          groupCustomer.extractedPhones.home || {
            primary: false,
            type: 'HOME',
          }, groupCustomer.extractedPhones.mobile || {
            primary: false,
            type: 'MOBILE',
          }
        ];

      } else {
        that.profile = {
          customerIds: [],
          customers: [],
          addresses: [],
          phones: [
            {
              primary: false,
              type: 'HOME'
            }, {
              primary: false,
              type: 'MOBILE'
            }
          ],
          collectionPaymentInterval: 'WEEKLY'
        }
      }

      const definitions = await customFieldService.readDefinitions({group: CustomFieldGroup.CUSTOMER, customerType: 'GROUP', enabled: true});
      this.availableCustomFields = definitions.map(d => d.id);
    }

    that.update = () => {
      confirmation('Do you want to update group profile?', () => {
        const clone = angular.copy(that.profile);
        clone.collectionTimeTo = formatTime(clone.collectionTimeTo);
        clone.collectionTimeFrom = formatTime(clone.collectionTimeFrom);
        clone.customers = [];
        clone.phones = clone.phones.filter(phone => phone.number);
        // mark one as primary
        const primaryPhone = _.find(clone.phones, {primary: true});
        if (!primaryPhone && clone.phones.length > 0) {
          clone.phones[0].primary = true;
        }
        clone.collectionStartDate = formatDate(clone.collectionStartDate);
        command.execute('UpdateGroupCustomer', clone).success(() => {
          groupCustomerCache.refetch();
          $location.path(`/customer/${that.profile.customerId}/group-loans`);
        });
      });
    };

    that.save = () => {
      confirmation('Do you want to save group profile?', () => {
        const clone = angular.copy(that.profile);
        clone.collectionTimeTo = formatTime(clone.collectionTimeTo);
        clone.collectionTimeFrom = formatTime(clone.collectionTimeFrom);
        clone.customers = [];
        clone.phones = clone.phones.filter(phone => phone.number);
        // mark one phone as primary
        const primaryPhone = _.find(clone.phones, {primary: true});
        if (!primaryPhone && clone.phones.length > 0) {
          clone.phones[0].primary = true;
        }
        clone.collectionStartDate = formatDate(clone.collectionStartDate);
        command.execute('CreateGroupCustomer', clone).success((response) => {
          groupCustomerCache.refetch();
          $location.path(`/customer/${response.output.id}/group-loans`);
        });
      })
    };

    that.cancel = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        if (that.editMode) {
          $location.path(`/customer/${that.customerId}/group-loans`);
        } else {
          $location.path('/dashboard/lookup-group');
        }
      })
    };

    that.isGroupMembersInvalid = () => {
      return (that.profile.customers.length === 0 && propertyConfigService.required('customer', 'group', 'customers'));
    };

    that.isGroupAddressesInvalid = () => {
      return (that.profile.addresses.length === 0 && propertyConfigService.required('customer', 'group', 'addresses'));
    };
  }
});
