import {IScope} from 'angular';
import {CustomFieldDefinition, CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';

import templateUrl from './custom-field-definition-multiselect.template.html';

class CustomFieldMultiselect {
  protected definitions!: CustomFieldDefinition[];
  protected definitionIds!: number[];
  protected selectedDefinitions: number[] = [];
  protected group!: CustomFieldGroup;

  constructor(private $scope: IScope) {
  }

  async $onInit(): Promise<void> {
    this.definitions = await customFieldService.readDefinitions({group: this.group, enabled: true});
    this.unselectDisabledDefinitions();

    this.$scope.$watch('$ctrl.definitionIds', () => {
      if(!this.definitionIds) {
        this.selectedDefinitions = [];
      }
      this.unselectDisabledDefinitions();
    });
  }

  unselectDisabledDefinitions(): void {
    if (this.definitions && this.definitionIds) {
      this.selectedDefinitions = this.definitions.filter(c => c.enabled)
        .map(c => c.id)
        .filter(id => this.definitionIds.indexOf(id) !== -1);
    }
  }

  onChangeUpdate(): void {
    this.definitionIds = this.selectedDefinitions;
  }
}

nxModule.component('customFieldDefinitionMultiselect', {
  templateUrl,
  bindings: {
    group: '<',
    definitionIds: '='
  },
  controller: CustomFieldMultiselect
});
