export const incomeSourceTypes = Object.freeze([{
  code: 'SALARY',
  description: 'Salary'
}, {
  code: 'BUSINESS',
  description: 'Business'
}, {
  code: 'ALLOWANCE',
  description: 'Allowance'
}, {
  code: 'REMITTANCE',
  description: 'Remittance'
}, {
  code: 'PENSION',
  description: 'Pension'
}, {
  code: 'OTHER',
  description: 'Other'
}, {
  code: 'OTHER_BANK_ACCOUNTS',
  description: 'Other bank accounts'
}, {
  code: 'DEPED',
  description: 'DepEd'
}, {
  code: 'SSS',
  description: 'SSS pension'
}]);