import axios from 'axios';
import useAxios, {UseAxiosResult} from 'axios-hooks';
import {
  CustomFieldCategory,
  CustomFieldCategoryDetails,
  CustomFieldDefinition,
  CustomFieldDefinitionCriteria,
  CustomFieldGroup,
  CustomFieldValueCriteria
} from 'custom-field/CustomFieldDefinitionTypes';
import qs from 'qs';

export const useDefinitions = (criteria: CustomFieldDefinitionCriteria): UseAxiosResult<CustomFieldDefinition[]> => {
  return useAxios<CustomFieldDefinition[]>({
    url: '/custom-field/definitions/search',
    method: 'POST',
    data: criteria
  });
};

export class CustomFieldService {

  public async readDefinitions(criteria: CustomFieldDefinitionCriteria): Promise<CustomFieldDefinition[]> {
    const {data} = await axios.post<CustomFieldDefinition[]>('/custom-field/definitions/search', criteria);
    return data;
  }

  public async readDefinition(id: number): Promise<CustomFieldDefinition> {
    const {data} = await axios.get<CustomFieldDefinition>(`/custom-field/definitions/${id}`);
    return data;
  }

  public async readCategories(definitionIds: number[]): Promise<CustomFieldCategory[]> {
    if (!definitionIds || definitionIds.length === 0) {
      return [];
    }

    const {data} = await axios.get<CustomFieldCategory[]>(`/custom-field/categories/values`, {
      params: {
        definitionIds: definitionIds
      },
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });

    return data;
  }

  public async readCategoryDetails(group: CustomFieldGroup, asForest?: boolean): Promise<CustomFieldCategoryDetails[]> {
    const {data} = await axios.get<CustomFieldCategoryDetails[]>(`/custom-field/categories/details`, {
      params: {
        group: group,
        asForest: !!asForest
      }
    });
    return data;
  }

  public async readValuesMap(criteria: CustomFieldValueCriteria): Promise<Record<number, string>> {
    const {data} = await axios.get<Record<number, string>>(`/custom-field/values`, {
      params: {
        ...criteria
      }
    });
    return data;
  }
}

export default new CustomFieldService();
