import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './customer-loans-edit-params-and-charges.template.html';

class CustomerLoanEditParamsAndCharges {

  constructor($location, command, notification, confirmation, customerCache, authentication) {
    this.$location = $location;
    this.command = command;
    this.notification = notification;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.authentication = authentication;
  }

  $onInit() {
    this.loan.loanTypeId = this.loan.typeId;
    this.loan.automaticTransferAgreement = this.loan.automaticTransferAgreement ? this.loan.automaticTransferAgreement : { transferStrategy: 'NONE'};

    const interestType =  this.loan.interestType || this.loan.loanType.interestType
    if (interestType === 'ADD_ON_RATE') {
      this.loan.interestRate = this.loan.monthlyInterestRate
    }

    this.onFeesAutoUpdated = message => {
      this.feesAutoUpdated = message;
    };
  }

  async updateParamsAndCharges() {
    const proceed = await this.confirmation("Are you sure you want to update the loan parameters and charges?");
    if (!proceed) {
      this.notification("No changes were made to the loan parameters and charges.");
      return;
    }

    const request = _.cloneDeep(this.loan);
    const response = await this.command.execute("EditLoanParametersAndCharges", request).toPromise();
    if (!response.approvalRequired) {
      this.customerCache.loans(this.customerId).refetch();
      this.customerCache.customerProductFees(this.customerId).refetch();
      this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.customerId).refetch() : Promise.resolve();
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

}

nxModule.component('customerLoanEditParamsAndCharges', {
  templateUrl,
  controller: CustomerLoanEditParamsAndCharges,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
