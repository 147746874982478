import {applicationIdResolver, defaultResolver} from "./resolvers";
import {HttpService} from "shared/utils/httpService";
import angular from "angular-route";

export const losRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/los/application', {
    template: '<loan-origination-application/>',
    label: 'LOS application'
  }], ['/los/application/create', {
    template: '<loan-origination-application-create/>',
    label: 'Create Application'
  }], ['/los/application/create/:cacheItemId', {
    template: '<loan-origination-application-create application-id="$resolve.cacheItemId"/>',
    label: 'Create Application',
    resolve: {
      ...defaultResolver('cacheItemId', true)
    }
  }], ['/los/application/:applicationId', {
    template: '<loan-origination-application-create persisted-application-id="$resolve.applicationId"/>',
    label: 'Edit Application',
    resolve: {...applicationIdResolver}
  }], ['/los/assessment', {
    template: '<loan-origination-assessment-list/>',
    label: 'LOS assessment'
  }], ['/los/assessment/:applicationId', {
    template: '<loan-origination-assessment application-id="$resolve.applicationId"/>',
    label: 'View application',
    resolve: {...applicationIdResolver}
  }], ['/los/assessment/:applicationId/edit', {
    template: '<loan-origination-application-create persisted-application-id="$resolve.applicationId"/>',
    label: 'Edit application',
    resolve: {...applicationIdResolver}
  }], ['/los/configuration', {
    template: '<loan-origination-configuration approval-hierarchy="$resolve.approvalHierarchy"/>',
    label: 'LOS configuration',
    resolve: {
      approvalHierarchy: /* @ngInject */ (http: HttpService): Promise<unknown> => {
        return http.get('/los/applications/config').toPromise();
      }
    }
  }]
];