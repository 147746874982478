import {TreeNode} from 'inspire-tree';
import React, {Factory, ReactElement} from 'react';
import MultiselectSubtreeList from 'tree/multiselectTree/MultiselectSubtreeList';
import TreeNodeItem from 'tree/TreeNodeItem';
import TreeNodeItemWrapper from 'tree/TreeNodeItemWrapper';

export type TreeNodeViewComponentFactory<VALUE> = Factory<TreeNodeViewProps<VALUE>>;

export interface TreeNodeViewProps<VALUE> {
  value: VALUE;
}

export interface MultiselectSubtreeViewProps<VALUE> {
  mapping: Map<string, VALUE>;
  reverseMapping: Map<VALUE, string[]>;
  treeNode: TreeNode;
  disabled: boolean;
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
}

const Component = function<VALUE>({mapping, reverseMapping, treeNode, disabled, TreeNodeView}: MultiselectSubtreeViewProps<VALUE> & {stateIdentity: string}): ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const id = treeNode.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const value = mapping.get(id)!;

  if (treeNode.available()) {
    return <TreeNodeItemWrapper treeNode={treeNode} selectable={!disabled} editable={false}>
      <TreeNodeItem treeNode={treeNode} checkbox={true} disabled={disabled}>
        <a onClick={(): void => {
          if(disabled) {
            return;
          }

          treeNode.toggleCheck();
        }}>
          <TreeNodeView value={value} />
        </a>
      </TreeNodeItem>
      {
        (treeNode.expanded() && treeNode.hasChildren())  ?
        <MultiselectSubtreeList treeNodes={treeNode.getChildren()}
                                mapping={mapping}
                                reverseMapping={reverseMapping}
                                disabled={disabled}
                                TreeNodeView={TreeNodeView}/> : null
      }
    </TreeNodeItemWrapper>;
  }

  return <></>;
};

const MultiselectSubtreeView = function<VALUE>(props: MultiselectSubtreeViewProps<VALUE>): ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const itreeNode = props.treeNode.itree;
  if(itreeNode.dirtyCount === undefined) {
    itreeNode.dirtyCount = 0;
  }

  if(itreeNode.dirty) {
    itreeNode.dirtyCount += 1;
    itreeNode.dirty = false;
  }

  const key = {
    ...itreeNode.state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    id: props.treeNode.id,
    dirtyCount: itreeNode.dirtyCount,
    parent: itreeNode.parent?.id
  };

  // form fields highlight when we expand select box
  // in order to ensure this behaviour, I cannot remove elements
  // when state changes, but need to update them in place
  // in order to force rendering whenever state changes, I'm keeping
  // a dummy, immutable prop that can be used for state comparison
  // and reuse the same dom element
  return <Component {...props} stateIdentity={JSON.stringify(key)}/>;
};

export default MultiselectSubtreeView;
