import nxModule from 'nxModule';
import templateUrl from './fee-overdraft-strategy.template.html';

class FeeOverdraftStrategy {
  constructor() {
    this.options = [
      {
        label: 'Do not proceed',
        value: 'ERROR'
      }, {
        label: 'Do not apply fee',
        value: 'SKIP'
      }, {
        label: 'Drain product balance to 0',
        value: 'DRAIN'
      }, {
        label: 'Perform overdraft (allow negative balance)',
        value: 'OVERDRAFT'
      }];
  }

  $onInit() {
    this.ngModelCtrl.$render = () => {
      this.innerModel = this.ngModelCtrl.$viewValue;
    }
  }

  innerModelChange() {
    this.ngModelCtrl.$setViewValue(this.innerModel);
  }
}

nxModule.component('feeOverdraftStrategy', {
  templateUrl,
  require: {
    ngModelCtrl: '^ngModel'
  },
  bindings: {},
  controller: FeeOverdraftStrategy
});
