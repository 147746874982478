import nxModule from 'nxModule';
import templateUrl from './customer-loans-edit-rates.template.html';
import {Loan} from "../../../service/loan.types";
import {ILocationService} from "angular";
import {allPercentageChargeTypes, pastDueInterestChargeTypes} from 'constants/loan';
import {Charge} from "../../../service/loan-type.types";
import _ from "lodash";
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class EditLoanRates {
  private customerId!: number;
  private loan!: Loan;

  private newPastDueInterestCharge?: Charge;
  private newPastDueMaturityInterestCharge?: Charge;
  private newPenalty?: Charge;
  private newPenaltyMaturity?: Charge;
  private pastDueInterestChargeTypes?: unknown;
  private penaltyChargeTypes?: unknown;

  constructor(
    private readonly $location: ILocationService,
    private readonly $filter: NxIFilterService,
    private readonly confirmation: Confirmation,
    private readonly command: CommandService,
    private readonly customerCache: CustomerCache
  ) {
  }

  $onInit(): void {
    this.pastDueInterestChargeTypes = pastDueInterestChargeTypes.map(value => ({
      label: this.$filter('prettyEnum')(value),
      value,
    }));
    this.penaltyChargeTypes = [...allPercentageChargeTypes, 'FIXED_AMOUNT'].map(type => ({
      label: this.$filter('prettyEnum')(type),
      value: type
    }));
    this.newPastDueInterestCharge = _.cloneDeep(this.loan.pastDueInterestCharge);
    this.newPastDueMaturityInterestCharge = _.cloneDeep(this.loan.pastDueMaturityInterestCharge);
    this.newPenalty = _.cloneDeep(this.loan.penalty);
    this.newPenaltyMaturity = _.cloneDeep(this.loan.penaltyMaturity);
  }

  goBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  async save(): Promise<void> {
    const confirmed = await this.confirmation('Apply loan rate adjustment?');
    if (confirmed) {
      await this.command.execute("EditLoanRates", {
        productId: this.loan.id,
        pastDueInterestCharge: this.newPastDueInterestCharge,
        pastDueMaturityInterestCharge: this.newPastDueMaturityInterestCharge,
        penalty: this.newPenalty,
        penaltyMaturity: this.newPenaltyMaturity,
      }).toPromise();

      this.customerCache.loans(this.customerId).refetch();
      this.goBack();
    }
  }
}

nxModule.component('customerLoansEditRates', {
  templateUrl,
  controller: EditLoanRates,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
