import nxModule from 'nxModule';
import _ from 'lodash';
import 'rxjs/add/operator/combineLatest';
import BigNumber from 'bignumber.js';

const templateUrl = require('./interests-cash-withdrawal.template.html');
nxModule.component('customerTermDepositInterestsCashWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, productDefinitionService, confirmationTemplate,
                        notification, command) {
    const that = this;

    that.overrideCheckbox = false;
    that.deposit = {};

    that.taxRecalculated = false;

    that.override = {
      interest : 0,
      netInterest : 0,
      withholdingTax: 0
    };

    const customerId = $route.current.params['customerId'];
    const depositId = ~~$route.current.params['depositId'];

    that.$onInit = async () => {
      that.interestForecast = {};
      const [forecast, deposits, productDefinitions] = await Promise.all([
        http.post(`/products/deposits/${depositId}/interest`, {mode: 'STANDARD'}).toPromise(),
        customerCache.termDeposits(customerId).toPromise(),
        productDefinitionService.toPromise()
      ]);
      that.termDeposit = deposits.find(d => d.id === depositId);
      const productDefinition = productDefinitions.find(pd => pd.id === that.termDeposit.definitionId);

      Object.assign(that.termDeposit, {
        productName: productDefinition ? productDefinition.productName : '<Unknown product>'
      });

      that.interestForecast = forecast;
      that.override.interest = that.interestForecast.interest;
      that.override.netInterest = that.interestForecast.netInterest;
      that.override.withholdingTax = that.interestForecast.withholdingTax;
      that.withdrawalAmount = that.calculateWithdrawalAmount();
      that.override.amount = that.withdrawalAmount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.updateOverrides = () => {
      that.taxRecalculated = false;
    };

    that.recalculateTax = async () => {
      const newTax = await  http.get(`/products/deposits/${depositId}/interest/calculate-withholding-tax?interest=${that.override.interest}`).toPromise();
      that.override.netInterest = new BigNumber(that.override.interest).minus(newTax).toNumber();
      that.override.withholdingTax = newTax;
      that.override.amount = that.calculateWithdrawalAmount();
      that.taxRecalculated = true;
    };

    that.calculateWithdrawalAmount = () => {
      if(that.termDeposit.applyAdvanceInterestOnPlacement) {
        return BigNumber.max(0, new BigNumber(that.termDeposit.balance).minus(that.termDeposit.activationAmount))
          .decimalPlaces(2)
          .toNumber();
      }

      return new BigNumber(0)
        .plus(that.override.netInterest)
        .decimalPlaces(2).toNumber();
    };

    that.withdrawalEnabled = () => {
      return !that.transactionForm.invalid && that.withdrawalAmount > 0;
    };

    that.withdraw = async () => {
      const confirmed = await confirmationTemplate({
        question: `Do you want to withdraw ${$filter('nxCurrency')(that.overrideCheckbox ? that.override.amount : that.withdrawalAmount)}?`,
      });

      if(confirmed) {
        const response = await command.execute('WithdrawDepositInterestByCash', {
          productId: depositId,
          customerId: customerId,
          entryType: "DEBIT",
          amount: that.overrideCheckbox ? that.override.amount : that.withdrawalAmount,
          override: that.overrideCheckbox ? that.override.interest : null
        }).toPromise();

        if (response && !response.approvalRequired) {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        }
      }
    };
  }
});
