import CustomFieldDefinitionList from 'custom-field/CustomFieldDefinitionList';
import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import CustomFieldDefinitionView from 'custom-field/CustomFieldDefinitionView';
import CustomFieldService from 'custom-field/CustomFieldService';
import React, {ReactElement} from 'react';
import RouteDefinition from 'routes/RouteDefinition';
import EnumFormatter from 'tools/EnumFormatter';

export const listRoute = (prefix: string, group: CustomFieldGroup, isTopLevelPath: boolean): RouteDefinition => {
  const CustomGroupFieldDefinitionList = (): ReactElement => <CustomFieldDefinitionList group={group}
                                                                                        pathPrefix={prefix}/>;
  const formatter = new EnumFormatter();
  const humanFriendlyGroup = formatter.format(group);
  return {
    name: isTopLevelPath ? `${humanFriendlyGroup} custom fields` : 'Custom fields',
    path: `${prefix}/custom-fields/groups/${group}`,
    Component: CustomGroupFieldDefinitionList
  };
};

export const allRoutes = (prefix: string, group: CustomFieldGroup, isTopLevelPath: boolean): RouteDefinition[] => {
  const CustomGroupFieldDefinitionView = (): ReactElement => <CustomFieldDefinitionView group={group}/>;
  return [
    listRoute(prefix, group, isTopLevelPath),
    {
      name: async (params: {id: string}): Promise<string> => {
        if (params.id === 'new') {
          return 'new';
        }

        const definition = await CustomFieldService.readDefinition(Number(params.id));
        return definition.name;
      },
      path: `${prefix}/custom-fields/groups/${group}/fields/:id`,
      Component: CustomGroupFieldDefinitionView,
      commandAccess: 'EditCustomFieldDefinition'
    }
  ];
};