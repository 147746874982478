import _ from "lodash";
import Authentication from "shared/utils/authentication";
import moment from "moment/moment";

const isEmployee = (predicateValue: boolean | undefined, predicateContext: Record<any, any>) => {
  return predicateContext.customer.customerType !== 'INDIVIDUAL'
    || !predicateContext.customer.individualData
    || predicateContext.customer.individualData.employee === predicateValue;
}

const isDosri = (predicateValue: boolean | undefined, predicateContext: Record<any, any>) => {
  return predicateValue == null
    || predicateContext.customer.dosri == null
    || predicateContext.customer.dosri === predicateValue;
}

export default (predicateValue: Record<string, any>, predicateContext: Record<any, any>, authentication: Authentication) => {

  const keys = Object.keys(predicateValue);
  if (_.isEmpty(keys)) {
    return true;
  }

  if (!keys.some(k => ['EMPLOYEE', 'DOSRI', 'ON_RELEASE_DATE'].includes(k))) {
    return true;
  }

  if (!predicateContext.customer) {
    console.warn('Missing predicate context for ', keys.join(", "));
    return true;
  }

  for (let i = 0; i < keys.length; i++) {
    switch (keys[i]) {
      case 'EMPLOYEE':
        if (!isEmployee(predicateValue['EMPLOYEE'], predicateContext)) {
          return false;
        }
        break;
      case 'DOSRI':
        if (!isDosri(predicateValue['DOSRI'], predicateContext)) {
          return false;
        }
        break;
      case 'ON_RELEASE_DATE':
        if (!predicateValue['ON_RELEASE_DATE']) {
          // "Only on release date" predicate is set to "false", so it's allowed any time.
          return true;
        }
        return !predicateContext
          || !predicateContext.branchSystemDate
          || !predicateContext.releaseDate
          || predicateContext.branchSystemDate?.isSame(moment(predicateContext.releaseDate));
      default:
        return true;
    }
  }

  return true;
}