import {NxInput} from '@nextbank/ui-components';
import {CustomFieldCategoryValue} from 'custom-field/CustomFieldCategoryTreeView';
import React, {ChangeEvent, ReactElement, useState} from 'react';
import {TreeNodeConfirmation, TreeNodeEditProps} from 'tree/editableTree/EditableSubtreeView';
import styles from './CustomFieldCategoryTreeEdit.scss';

const CustomFieldCategoryTreeEdit = (props: TreeNodeEditProps<CustomFieldCategoryValue>): ReactElement => {
  const [label, setLabel] = useState(props.value?.label ?? '');
  const [code, setCode] = useState(props.value?.code ?? '');
  const [remarks, setRemarks] = useState(props.value?.remarks ?? '');

  return <div className={styles.row}>
    <NxInput type="text"
             label="Category Name"
             onChange={(event: ChangeEvent<HTMLInputElement>): void => setLabel(event.target.value)}
             error={!label ? 'Value is required' : undefined}
             value={label}/>
    <NxInput type="text"
             label="Category Code"
             onChange={(event: ChangeEvent<HTMLInputElement>): void => setCode(event.target.value)}
             value={code}/>
    <NxInput type="text"
             label="Remarks"
             onChange={(event: ChangeEvent<HTMLInputElement>): void => setRemarks(event.target.value)}
             value={remarks}/>
    <TreeNodeConfirmation<CustomFieldCategoryValue>
      treeNode={props.treeNode}
      value={label ? {
        id: undefined,
        label,
        code,
        remarks
      } : undefined}
      mapping={props.mapping}
    />
  </div>;
};

export default CustomFieldCategoryTreeEdit;