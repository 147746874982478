import nxModule from 'nxModule';
import $ from "jquery";

const templateUrl = require('./report-modal.template.html');

nxModule.component('reportModalComponent', {
  templateUrl,
  bindings: {
    reportContent: '<',
    reportCode: '<',
    params: '<',
    onClose: '&',
    hideXls: '<'
  },
  controller: function (http, reportService) {
    const that = this;

    that.close = () => {
      $('#reportModal').modal('hide');
      if (that.onClose) that.onClose();
    };

    that.print = () => {
      window.print();
    };

    that.downloadXls = () => {
      reportService.downloadXls({reportCode: that.reportCode, params: that.params});
    };
  }
});


nxModule.factory('reportModal', function ($rootScope, $compile, $timeout, http) {

  const fetchReportAndDisplayModal = ({reportCode, params, onClose, hideXls}) => {
    http.http({
      url: `/reports/${reportCode}/json`,
      method: 'POST',
      responseType: 'json',
      data: $.param(params),
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).success(reportContent => {
      showModal(reportContent, reportCode, params, onClose, hideXls);
    });

  };

  function showModal(reportContent, reportCode, params, onClose, hideXls) {
    let scope = $rootScope.$new();
    scope.reportContent = reportContent;
    scope.params = params;
    scope.reportCode = reportCode;
    scope.hideXls = hideXls;
    scope.closeModal = () => {
      $('#reportModalComponent').remove();
      if (onClose) onClose();
    };
    $('#modal-container').append($compile('<report-modal-component id="reportModalComponent" report-code="reportCode" hide-xls="hideXls" report-content="reportContent" params="params" on-close="closeModal()"/>')(scope));
    $timeout(() => {
      $('#reportModal').modal({
        keyboard: false,
        backdrop: 'static'
      });
    });
  }

  return {
    display: fetchReportAndDisplayModal
  }

});