import AddIcon from '@material-ui/icons/Add';
import {NxIconButton} from '@nextbank/ui-components';
import {TreeNode} from 'inspire-tree';
import React, {ReactElement} from 'react';
import TreeNodeListWrapper from 'tree/TreeNodeListWrapper';

import EditableSubtreeView, {TreeNodeEditComponentFactory, TreeNodeViewComponentFactory} from './EditableSubtreeView';
import iconStyles from './iconStyles.scss';

const EditableSubtreeList = function <NT>(props: {
  treeNodes: TreeNode[],
  mapping: Map<string, NT>,
  disabled: boolean,
  TreeNodeEdit: TreeNodeEditComponentFactory<NT>,
  TreeNodeView: TreeNodeViewComponentFactory<NT>,
  onRootNodeAdded?: () => unknown
}): ReactElement {
  const nodes = props.treeNodes.map((treeNode, i) =>
    <EditableSubtreeView<NT> key={i}
                             treeNode={treeNode}
                             disabled={props.disabled}
                             mapping={props.mapping}
                             TreeNodeEdit={props.TreeNodeEdit}
                             TreeNodeView={props.TreeNodeView}
    />);

  if(props.onRootNodeAdded && !props.disabled) {
    nodes.push(<NxIconButton
      key="icon"
      className={iconStyles.icon}
      ariaLabel="Add a child node"
      icon={<AddIcon />}
      onClick={props.onRootNodeAdded} />
    );
  }

  return <TreeNodeListWrapper>
    {nodes}
  </TreeNodeListWrapper>;
};

export default EditableSubtreeList;
