import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './loan-origination-assessment.template.html';
import './loan-origination-assessment.style.less';

import {LosApplication, LosApplicationStatus} from '../application/types/los-application.types';
import {IController, ILocationService} from 'angular';
import {CustomerProfile} from '../../customer/profile/customer-profile.types';
import {LoanType} from '../../service/loan-type.types';
import {CreateLoanInput} from '../../service/create-loan-input.types';
import {UserCache, UserDetails} from '../../service/users/user.cache';
import {LOSApproval} from './los-assessment.types';
import {Branch} from "management/BranchTypes";
import RolesCache from "components/service/roles.cache";
import {CustomerService} from "components/service/customer.service";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import Authentication from "shared/utils/authentication";
import {Confirmation} from "shared/common/confirmation.types";
import {BranchService} from "components/service/branch.service";
import {ModalApi} from "components/technical/modal/modal.component";
import {Role} from "user/UserTypes";

type ApprovalConfigWithEntities = LOSApproval['approvalConfig'] & {authorizedEntities?: string};

class LoanOriginationAssessment implements IController {
  // binding injected from route
  private applicationId!: number;

  private modalAPI?: ModalApi;
  actionRemarks: string | null = null;

  approvalLevels: (LOSApproval & {approvalConfig: ApprovalConfigWithEntities})[] = [];
  application: LosApplication | null = null;

  customer: CustomerProfile | null = null;
  loanType: LoanType | null = null;
  loan: CreateLoanInput | null = null;

  constructor(private $location: ILocationService,
              private $route: NxRouteService,
              private $filter: NxIFilterService,
              private http: HttpService,
              private command: CommandService,
              private rolesCache: RolesCache,
              private userCache: UserCache,
              private authentication: Authentication,
              private customerService: CustomerService,
              private loanProductsCache: LoanProductsCache,
              private confirmation: Confirmation,
              private branchService: BranchService) {

  }

  async $onInit() {
    const [approvalLevels, application, roles, users] = await Promise.all([
      this.http.get<LOSApproval[]>(`/los/applications/${ this.applicationId }/approval-levels`).toPromise(),
      this.http.get<LosApplication>(`/los/applications/${ this.applicationId }`).toPromise(),
      this.rolesCache.toPromise(),
      this.userCache.withParam().toPromise()
    ]);

    this.initApprovalLevels(approvalLevels, roles, users);

    await this.initApplication(application, users);
  }

  private getUserName(id: number, users: UserDetails[]): string {
    const userFound: UserDetails | undefined = users.find(user => Number(user.id) === Number(id));
    return userFound ? userFound.effectiveName : '-';
  }

  private async initApplication(application: LosApplication, users: UserDetails[]) {
    const branches: Branch[] = await this.branchService.toPromise();

    this.application = {
      ...application,
      branchName: branches.find(branch => Number(branch.id) === Number(application.branchId))!.name,
      createdByName: this.getUserName(application.createdBy, users),
      lastUpdatedByName: this.getUserName(application.lastUpdatedBy, users)
    };
    this.customer = this.customerService.extractPhones(application.customer);
    this.loan = application.loanInputObject;
    if (!_.isEmpty(this.loan.collateralFileIds)) {
      this.loan.collateralFiles = this.loan.collateralFileIds.map(fileId => ({ id: fileId }));
    }
    const loanTypes = await this.loanProductsCache.toPromise();
    this.loanType = _.find(loanTypes, loanType => loanType.id === application.loanInputObject.loanTypeId)!;
  }

  private initApprovalLevels(approvalLevels: (LOSApproval & {approvalConfig: ApprovalConfigWithEntities})[], roles: Role[], users: UserDetails[]) {
    this.approvalLevels = approvalLevels.map(approval => {
      const { approvalConfig } = approval;
      const { authorizedRoleIds: authorizedRoleIds, authorizedUserIds: authorizedUserIds } = approvalConfig;

      const roleNames = roles.filter(role => (authorizedRoleIds || []).includes(role.id))
        .map(role => role.name);
      const userNames = users.filter(user => (authorizedUserIds || []).includes(user.id))
        .map(user => user.fullName);

      approvalConfig.authorizedEntities = roleNames.concat(userNames).join(' or ');
      return approval;
    }).sort((approvalA, approvalB) => approvalA.approvalConfig.level - approvalB.approvalConfig.level);
  }

  isApprovalConfigured(): boolean {
    return !_.isEmpty(this.approvalLevels);
  }

  editApplication() {
    this.$location.path(`/los/assessment/${ this.applicationId }/edit`);
  }

  onModalApiReady = ({ api }: {api: ModalApi}) => {
    this.modalAPI = api;
  };

  canShowActions(level: LOSApproval) {
    return level.status === 'AWAITING_APPROVAL'
      && (_.some(level.approvalConfig.authorizedRoleIds, roleId => this.authentication.context.roleIds.includes(roleId))
        || _.includes(level.approvalConfig.authorizedUserIds, this.authentication.context.id));
  }

  async changeStatus(status: LosApplicationStatus, approvalId: number) {
    if(!this.modalAPI) {
      throw new Error('Modal not initialized');
    }

    if (['REJECTED', 'DEFERRED'].includes(status)) {
      const { accepted } = await this.modalAPI.show();
      if (!accepted) {
        this.resetRemarks();
        return;
      }
    }

    const request = {
      approvalId,
      status,
      remarks: this.actionRemarks
    };

    await this.command.execute('LOSChangeApprovalStatus', request).toPromise();
    this.resetRemarks();
    this.$route.reload();
  }

  private resetRemarks() {
    this.actionRemarks = null;
  }

  async finalizeApplication() {
    if(!this.customer) {
      throw new Error('Missing customer');
    }

    if(!this.loanType) {
      throw new Error('Missing loan product');
    }

    if(!this.loan) {
      throw new Error('Missing loan');
    }

    const message = `Confirm opening of a new loan for the customer:<br/>
<br/>
Loan: <strong>${ this.loanType.productDefinition.productName }</strong><br/>
Principal: <strong>${ this.$filter('nxCurrency')(this.loan.principalAmount) }</strong><br/>
Interest: <strong>${ this.loan.interestRate } %</strong><br/>
<br/>
Customer name: <strong>${ this.customer.effectiveName }</strong><br/>
Customer type: <strong>${ this.customer.customerType }</strong><br/>
${ this.customer.status === 'PROSPECT' ? 'Customer is PROSPECT and is going to be activated.' : '' }`;

    const confirmed = await this.confirmation(message, true);
    if (confirmed) {
      const { output } = await this.command.execute<unknown, {id: number}>('LOSFinalizeApplication', { applicationId: this.applicationId }).toPromise();
      if (output) {
        this.$location.path(`/customer/${ this.customer.id }/loans/${ output.id }`);
      }
    }

  }
}

nxModule.component('loanOriginationAssessment', {
  templateUrl,
  bindings: {
    'applicationId': '<'
  },
  controller: LoanOriginationAssessment
});