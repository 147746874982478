import { StateMachineConfig, StateMachineTransition } from '../types/los-state-machine.types';

export class LOSStateMachineConfig {
  public static getDefaultStateMachineConfig(): StateMachineConfig {
    const customerSelectionTransitions: StateMachineTransition[] = [
      { name: 'next', from: 'CUSTOMER_SELECTION', to: 'CUSTOMER_PROFILE' }
    ];

    const customerProfileTransitions: StateMachineTransition[] = [
      {
        name: 'next', from: 'CUSTOMER_PROFILE', to: (loanInitialized?: boolean) => {
          return loanInitialized ? 'LOAN_CONFIGURATION' : 'LOAN_SELECTION';
        }
      },
      { name: 'changeCustomerType', from: 'CUSTOMER_PROFILE', to: 'CUSTOMER_SELECTION' },
    ];

    const loanSelectionTransitions: StateMachineTransition[] = [
      { name: 'next', from: 'LOAN_SELECTION', to: 'LOAN_CONFIGURATION' },
      { name: 'previous', from: 'LOAN_SELECTION', to: 'CUSTOMER_PROFILE' }
    ];

    const loanConfigTransitions: StateMachineTransition[] = [
      { name: 'next', from: 'LOAN_CONFIGURATION', to: 'SUMMARY' },
      { name: 'previous', from: 'LOAN_CONFIGURATION', to: 'CUSTOMER_PROFILE' },
      { name: 'changeLoanType', from: 'LOAN_CONFIGURATION', to: 'LOAN_SELECTION' }
    ];

    const summaryTransitions: StateMachineTransition[] = [
      { name: 'previous', from: 'SUMMARY', to: 'LOAN_CONFIGURATION' },
      { name: 'changeCustomer', from: 'SUMMARY', to: 'CUSTOMER_PROFILE' },
      { name: 'changeLoan', from: 'SUMMARY', to: 'LOAN_CONFIGURATION' }
    ];

    return {
      init: 'CUSTOMER_SELECTION',
      transitions: [
        ...customerSelectionTransitions,
        ...customerProfileTransitions,
        ...loanSelectionTransitions,
        ...loanConfigTransitions,
        ...summaryTransitions
      ]
    };
  }

  /**
   * Application form without CUSTOMER_SELECTION & LOAN_SELECTION steps
   * (user can't change customer type & loan type)
   */
  public static getSimplifiedStateMachineConfig(): StateMachineConfig {
    const customerProfileTransitions: StateMachineTransition[] = [
      { name: 'next', from: 'CUSTOMER_PROFILE', to: 'LOAN_CONFIGURATION' }
    ];

    const loanConfigTransitions: StateMachineTransition[] = [
      { name: 'next', from: 'LOAN_CONFIGURATION', to: 'SUMMARY' },
      { name: 'previous', from: 'LOAN_CONFIGURATION', to: 'CUSTOMER_PROFILE' }
    ];

    const summaryTransitions: StateMachineTransition[] = [
      { name: 'previous', from: 'SUMMARY', to: 'LOAN_CONFIGURATION' },
      { name: 'changeCustomer', from: 'SUMMARY', to: 'CUSTOMER_PROFILE' },
      { name: 'changeLoan', from: 'SUMMARY', to: 'LOAN_CONFIGURATION' }
    ];

    return {
      init: 'CUSTOMER_SELECTION',
      transitions: [
        ...customerProfileTransitions,
        ...loanConfigTransitions,
        ...summaryTransitions
      ]
    };
  }
}