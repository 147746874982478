import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import _ from 'lodash';
import nxModule from 'nxModule';
import {BigNumber} from 'bignumber.js';

import './group-loans-create-members.style.less';
import templateUrl from './group-loans-create-members.template.html';

class GroupLoansCreateMembers {
  constructor(groupLoansCreateService, confirmation, $location, $route) {
    this.groupLoansCreateService = groupLoansCreateService;
    this.confirmation = confirmation;
    this.$location = $location;
    this.customerId = $route.current.params['customerId'];
  }

  countActiveLoans(member) {
    return member.loans
      .filter(l =>
        [
          'ACTIVE',
          'PAST_DUE_PERFORMING',
          'PAST_DUE_NON_PERFORMING',
          'PAST_DUE_LITIGATION',
          'PAST_DUE_WRITE_OFF'
        ].includes(l.status))
      .length;
  }

  calculateOutstandingBalance(member) {
    return member.loans.reduce((s, loan) => s.plus(new BigNumber(loan.principalBalance)), new BigNumber(0)).toNumber();
  }

  calculateNewOutstandingBalance(member) {
    const outstandingFromNewLoans = new BigNumber(_.get(member, 'newLoanCalculation.amortizationSchedule.totalAmount.total', 0));
    const constLastOutstandingBalance = new BigNumber(this.calculateOutstandingBalance(member));
    return constLastOutstandingBalance.plus(outstandingFromNewLoans).toNumber();
  }

  noApplyingMembers() {
    return !this.members.some(member => member.applying);
  }

  noProductsAdded() {
    return !this.members.some(member => !!member.newProduct);
  }

  editLoan(member) {
    this.editLoanAction(member);
  }

  clearLoan(member) {
    member.newLoan = null;
    member.newLoanCalculation = null;
    member.newProduct = null;
  }

  cancel() {
    this.$location.path(`/customer/${this.customerId}/group-loans`);
  }

  saveApplication() {
    this.confirmation('Do you want to open the loans?', () => {
      const newLoans = this.members
        .filter(member => member.newLoan)
        .map(member => ({
          loan: member.newLoan,
          customerId: member.id
        }));

      this.groupLoansCreateService.submitLoans(this.groupProfile.id, newLoans)
        .success((loanResponse) => {
          this.saveApplicationAction({loan: loanResponse.output});
        });
    });
  }
}

nxModule.component('groupLoansCreateMembers', {
  templateUrl,
  bindings: {
    'groupProfile': '<',
    'members': '<',
    'prepareLoanAction': '<',
    'saveApplicationAction': '<',
    'editLoanAction': '<',
    'editGroupLoanAction': '<'
  },
  controller: GroupLoansCreateMembers
});
