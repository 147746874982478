export type AutoCloseInterval = 'NONE' | 'DAILY' | 'MONTH_END';

export const autoCloseIntervals = Object.freeze([{
  label: 'Never',
  value: 'NONE'
}, {
  label: 'Every day',
  value: 'DAILY'
}, {
  label: 'Every month end',
  value: 'MONTH_END'
}]);