import {
  NxButton,
  NxButtonLink,
  NxButtonVariant,
  NxFormik,
  NxFormikSubmitButton,
  NxLoader,
  NxPopup,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxStack,
  NxTable,
  NxTableActionProps,
  NxTableColumn
} from '@nextbank/ui-components';
import {NxTableRef} from '@nextbank/ui-components/dist/nxTable/NxTableTypes';
import CommandAccess from 'command/CommandAccess';
import commandAccessService from 'command/commandAccessService';
import {useCommand} from 'command/CommandService';
import {CustomFieldDefinition, CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {useDefinitions} from 'custom-field/CustomFieldService';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import HttpErrorHandler from 'misc-transaction/encash-cashiers-check/HttpErrorHandler';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import EnumFormatter from 'tools/EnumFormatter';

const columns: NxTableColumn<CustomFieldDefinition>[] = [{
  title: 'Name',
  field: 'name'
}, {
  title: 'Type',
  field: 'type'
}, {
  title: 'Required',
  field: 'required'
}];

const editCustomFieldCommand = 'EditCustomFieldDefinition';
const formatter = new EnumFormatter();

interface DisableCustomFieldDefinitionsInput {
  customFieldDefinitionIds: number[];
}

const CustomFieldDefinitionList = ({
                                     group,
                                     pathPrefix
                                   }: {group: CustomFieldGroup, pathPrefix: string}): ReactElement => {
  const execute = useCommand();
  const nxTableApi = useRef<NxTableRef | null>(null);
  const [definitions, setDefinitions] = useState<CustomFieldDefinition[]>([]);
  const [definitionToDisable, setDefinitionToDisable] = useState<CustomFieldDefinition | null>(null);
  const headerText = `${formatter.format(group)} custom fields`;
  const header = <NxHeader>
    {headerText}
  </NxHeader>;

  const fieldPrefix = `${pathPrefix}/custom-fields/groups/${group}/fields`;

  const EditDefinition = ({data}: NxTableActionProps<CustomFieldDefinition>): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`${fieldPrefix}/${data.id}`}>Open</NxButtonLink>;

  const DisableDefinition = ({data}: NxTableActionProps<CustomFieldDefinition>): ReactElement =>
    <NxButton variant={NxButtonVariant.DELETE} onClick={(): void => {
      setDefinitionToDisable(data);
    }}>Disable
    </NxButton>;

  const [{data: customFieldDefinitions, loading, error}] = useDefinitions({group, enabled: true});
  useEffect(() => setDefinitions(customFieldDefinitions || []), [customFieldDefinitions]);

  if (!definitions || loading) {
    return <NxPage>{header}<NxLoader/></NxPage>;
  }

  if (error) {
    return <HttpErrorHandler error={error} pageHeader={headerText}/>;
  }

  const rowActions = commandAccessService.canExecute(editCustomFieldCommand) ? [EditDefinition] : [];
  if (commandAccessService.canExecute('DisableCustomFieldDefinitions')) {
    rowActions.push(DisableDefinition);
  }
  return <NxPage>
    {header}
    <NxStack>
      <NxRow position={NxRowPosition.END}>
        <CommandAccess commandName={editCustomFieldCommand}>
          <NxButtonLink variant={NxButtonVariant.ADD} to={`${fieldPrefix}/new`}>Create</NxButtonLink>
        </CommandAccess>
      </NxRow>
      <NxTable
        ref={nxTableApi}
        columns={columns}
        rowActions={rowActions}
        data={async (query: NxQuery): Promise<NxQueryResult<CustomFieldDefinition>> => {
          const startingIndex = query.page * query.pageSize;
          const result = definitions.slice(startingIndex, startingIndex + query.pageSize);

          return {
            pageNo: query.page,
            result,
            totalCount: definitions.length
          };
        }}
      />
    </NxStack>
    {definitionToDisable ?
      <DisableConfirmationPopup definition={definitionToDisable} onClose={async (confirmed): Promise<void> => {
        try {
          if (confirmed) {
            const {approvalRequired} = await execute<DisableCustomFieldDefinitionsInput, void>({
              name: 'DisableCustomFieldDefinitions',
              input: {
                customFieldDefinitionIds: [definitionToDisable.id]
              }
            });

            if (!approvalRequired) {
              setDefinitions(definitions.filter(d => d.id !== definitionToDisable?.id));
              nxTableApi.current?.onQueryChange();
            }
          }
        } finally {
          setDefinitionToDisable(null);
        }
      }}/> : null}
  </NxPage>;
};

interface DisableConfirmationProps {
  definition: CustomFieldDefinition;
  onClose: (confirmed: boolean) => Promise<void>;
}

const DisableConfirmationPopup = (props: DisableConfirmationProps): ReactElement => {
  const definition = props.definition;
  return <NxPopup header="Confirm"
                  open={definition.id > 0}
                  description={`Do you want to disable '${definition.name}' custom field?`}>
    <NxFormik initialValues={{}} onSubmit={async (): Promise<void> => {
      await props.onClose(true);
    }}>
      {({
          isSubmitting,
          submitForm
        }): ReactElement => {
        return (
          <NxRow position={NxRowPosition.END}>
            <NxButton variant={NxButtonVariant.CLOSE}
                      disabled={isSubmitting}
                      onClick={async (): Promise<void> => {
                        await props.onClose(false);
                      }}>
              No
            </NxButton>
            <NxFormikSubmitButton
              variant={NxButtonVariant.SAVE}
              disabled={isSubmitting}
              onClick={(): void => {
                submitForm();
              }}>
              Yes
            </NxFormikSubmitButton>
          </NxRow>
        );
      }}
    </NxFormik>
  </NxPopup>;
};

export default CustomFieldDefinitionList;
